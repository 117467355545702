import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

import { DobleAvatarPlusNameCard } from './DobleAvatarPlusNameCard'
import { RenamePopUp } from './RenamePopUp'

interface ITeamSettingsCard {
  title?: string
  text?: string
  nameOne?: string
  nameTwo?: string
  isBottomLine?: boolean
  isTeamRename?: boolean
  workSpaceId?: string
  teamId?: string
}

export const TeamSettingsCard = ({
  title,
  nameOne,
  nameTwo,
  isBottomLine,
  isTeamRename,
  workSpaceId,
  teamId,
}: ITeamSettingsCard) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={`bg-baseColor flex flex-col p-4 gap-4 pb-8 ${
        isBottomLine && 'border-b-[1px] border-neutralColor30'
      }`}
    >
      <div className="flex flex-row justify-between">
        <p className="text-neutralColor text-xl">{title}</p>
        <Button variant="outline" onClick={() => setIsOpen(true)}>
          {t('dashboard.company.workspaceCard.rename')}
        </Button>
      </div>
      <div className="flex flex-row items-center">
        <DobleAvatarPlusNameCard
          nameShowInOthers={nameOne}
          nameForInitials={nameTwo}
        />
      </div>

      <RenamePopUp
        isRenameTeam={isTeamRename}
        oldName={title}
        workspaceId={workSpaceId}
        teamId={teamId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  )
}
