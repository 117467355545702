import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'

import emptyProfilePhoto from '@/assets/emptyState/emptyAvatar.svg'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import useScreenSize from '@/hooks/useScreenSize'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import { useLazySocialMediaPostQuery } from '@/store/api/Person/Person.query'
import type {
  FilterByType,
  SocialMediaPost,
} from '@/store/api/Person/Person.types'

import { Header } from '../Components/Header'

import {
  FlaggedPopover,
  type FlaggedPopoverFilter,
} from './components/FlaggedPopover'
import type { PlatformPopoverFilter } from './components/PlatformPopover'
import { PlatformPopover } from './components/PlatformPopover'
import { PostCard } from './components/PostCard'

export const SocialMediaPostList = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const plataform: PlatformPopoverFilter = state.plataform
  const filterBy: FlaggedPopoverFilter = state.filterBy
  const socialMediaId: string = state.socialMediaId
  const id: string = state.id

  const isSmallDesktop = useSmallDesktopQuery()

  const { height: screenHeigth } = useScreenSize()
  const [currentPage, setCurrentPage] = useState(1)
  const [platformFilter, setPlatformFilter] =
    useState<PlatformPopoverFilter>(plataform)
  const [flaggFilter, setFlaggFilter] = useState<FlaggedPopoverFilter>(filterBy)

  const [
    makeRequest,
    {
      data: postData,
      isLoading: isLoadingPostFirstTime,
      isError: isErrorPost,
      isFetching: isFechingPost,
    },
  ] = useLazySocialMediaPostQuery()

  const makeBeRequest = useCallback(
    (
      page: number,
      currentFilterBy?: FilterByType,
      platformFilter?: PlatformPopoverFilter,
    ) => {
      makeRequest({
        id,
        page: page,
        pageSize: 10,
        plataform: platformFilter,
        filterBy: currentFilterBy,
        socialMediaId,
      })
    },
    [id, plataform, makeRequest],
  )

  useEffect(() => {
    makeBeRequest(currentPage, flaggFilter, platformFilter)
  }, [currentPage, platformFilter, flaggFilter, makeBeRequest])

  const hasMoreData = useMemo(
    () => (postData?.posts.offset && postData?.posts.offset > 0) as boolean,
    [postData],
  )

  const postItemHeigth = useMemo(
    () => Math.floor(screenHeigth / 2),
    [screenHeigth],
  )

  const fetchMoreOnPagination = () => {
    const newPage = currentPage + 1
    setCurrentPage(newPage)
    makeBeRequest(newPage, flaggFilter, platformFilter)
  }

  const isLoading =
    (isFechingPost && currentPage === 1) || isLoadingPostFirstTime

  const handleNavigateProfile = async (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  return (
    <WeScreen
      isError={isErrorPost}
      isLoading={isLoadingPostFirstTime}
      useNextButton={false}
      childrenCustomGap={32}
      childrenCustomPadding={9}
    >
      <Header
        fullName={postData?.userName}
        platform={plataform}
        isPrivate={postData?.isPrivate}
        profileImage={postData?.profileImgUrl ?? emptyProfilePhoto}
        followers={postData?.followers}
        following={postData?.following}
        posts={postData?.postsCount}
        hiddenFollowSection={plataform === undefined}
        children={
          plataform && plataform !== 'WebSearch' ? (
            <Button
              disabled={!postData?.profileLink}
              className="h-8 px-3 py-2 rounded-lg"
              onClick={async () =>
                handleNavigateProfile(postData?.profileLink || '')
              }
            >
              {t('dashboard.postList.visitProfile')}
            </Button>
          ) : undefined
        }
      />

      <div className="bg-baseColorHighlight p-4 rounded-2xl">
        <div className={`flex justify-between mb-8`}>
          <p className="text-textNormal text-xl font-medium tracking-wide">
            {`${
              plataform
                ? flaggFilter || t('dashboard.postList.all')
                : platformFilter || t('dashboard.postList.all')
            } ${t('dashboard.postList.posts')}`}
          </p>
          {plataform && (
            <FlaggedPopover
              sortedBy={flaggFilter}
              onChangeSort={(sortBy) => {
                setFlaggFilter(sortBy)
                setCurrentPage(1)
              }}
            />
          )}
          {filterBy && (
            <PlatformPopover
              sortedBy={platformFilter}
              onChangeSort={(sortBy) => {
                setPlatformFilter(sortBy)
                setCurrentPage(1)
              }}
            />
          )}
        </div>
        {!isLoading && postData && postData.posts.list.length > 0 && (
          <div className="w-full">
            <InfiniteScroll
              className="lg:grid lg:grid-cols-2 flex flex-col gap-4"
              dataLength={postData.posts.list.length} //This is important field to render the next data
              next={fetchMoreOnPagination}
              hasMore={hasMoreData}
              loader={<p>Loading...</p>}
              height={isSmallDesktop ? 430 : 650}
            >
              {postData.posts.list.map((post: SocialMediaPost, id: number) => (
                <div key={id} className="w-auto">
                  <PostCard post={post} cardHeigth={postItemHeigth} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        )}
        {isLoading && (
          <div className="flex justify-center ">
            <LottieSpinner />
          </div>
        )}
        {postData?.posts.list.length === 0 && !isLoading && (
          <div className="flex justify-center">
            <EmptyState text={t('dashboard.postList.emptyState')} />
          </div>
        )}
      </div>
    </WeScreen>
  )
}
