import { Trash } from 'iconsax-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Ellipse from '@/assets/Ellipse.svg?react'
import { ESocialCheckStatus } from '@/store/api/Enterprise/Enterprise.types'
import { EReportFilter } from '@/store/api/workspace/workspace.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import type { ExtendedReportFilter } from '../ReportsScreen'

import type { ReportStatusType } from './ReportStatusSelector'

interface IFilterChipProps {
  text: ExtendedReportFilter | ReportStatusType
  isSelected?: boolean
  onClick: () => void
  type?: 'status' | 'reportType'
}

export const FilterChip = ({
  text,
  isSelected = false,
  onClick,
  type,
}: IFilterChipProps) => {
  const { t } = useTranslation()

  const getFilterName = (filter: ExtendedReportFilter | ReportStatusType) => {
    if (filter === 'all' && type) {
      return type === 'status'
        ? t('dashboard.reportsScreen.allStatus')
        : t('dashboard.reportsScreen.allReportType')
    }

    switch (filter) {
      // Report Types
      case EReportFilter.AdverseMedia:
        return t('dashboard.reportsScreen.adverseMedia')
      case EReportFilter.Ai:
        return t('dashboard.reportsScreen.aIReport')
      case EReportFilter.AiAnalyst:
        return t('dashboard.reportsScreen.aIAnalystReport')
      case EReportFilter.Assited:
        return t('dashboard.reportsScreen.weCheckAssisted')
      case EReportFilter.FacialMatching:
        return t('dashboard.reportsScreen.facialMatchingCredit')
      case 'your_reports':
        return t('dashboard.reportsScreen.yourReports')
      // Status Types
      case ESocialCheckStatus.InProgress:
        return t('dashboard.reportsScreen.inProgress')
      case ESocialCheckStatus.NotReleased:
        return t('dashboard.reportsScreen.pendingConsent')
      case ESocialCheckStatus.Cancelled:
        return t('dashboard.reportsScreen.cancelled')
      case ESocialCheckStatus.Complete:
        return t('dashboard.reportsScreen.new')
    }
  }

  return (
    <div
      className="h-[35px] px-4 py-2 rounded-[200px] items-center justify-center cursor-pointer flex gap-2 bg-baseColor text-neutralColor border border-neutralColor30"
      onClick={onClick}
    >
      {isSelected && <Ellipse />}
      <p className="text-sm font-medium tracking-tight">
        {getFilterName(text)}
      </p>
      {!isSelected && (
        <Trash size={16} color={weCheckPalette.dynamicRed} variant="Bold" />
      )}
    </div>
  )
}
