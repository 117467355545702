import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { z } from 'zod'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { setGeneratingReportGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import { useListPaginatedOfTopicsQuery } from '@/store/api/NewSearch/NewSearch.query'
import { selectNewSearchFirstStep } from '@/store/api/NewSearch/NewSearch.selector'
import { setFirstStep } from '@/store/api/NewSearch/NewSearch.slice'
import { switchSectionSchema } from '@/utils/schemas'

import { SwitchSection } from './Components/SwitchsSection'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const AIReportFocusedTopics = () => {
  useHasCurrentStepToken('AIReportFocusedTopics')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectedTopics = useSelector(selectNewSearchFirstStep)

  const [activeSwitchesCount, setActiveSwitchesCount] = useState(0)
  const { data, isLoading, isError } = useListPaginatedOfTopicsQuery({})

  const defaultValues: any = data?.info.list.reduce(
    (acc, topic) => {
      acc[topic.name] = selectedTopics.includes(topic.name)
      return acc
    },
    {} as { [key: string]: boolean },
  )

  const form = useForm<z.infer<typeof switchSectionSchema>>({
    resolver: zodResolver(switchSectionSchema),
    defaultValues: defaultValues,
  })

  const onSubmit = form.handleSubmit((formData) => {
    const selectedTopics =
      data?.info.list
        .filter((topic) => formData[topic.name])
        .map((topic) => ({
          id: topic._id,
          name: topic.name,
        })) || []

    dispatch(
      setFirstStep({
        topics: selectedTopics.map((topic) => topic.name),
        topicsIds: selectedTopics.map((topic) => topic.id),
      }),
    )
    localStorage.removeItem('subjectId')
    localStorage.removeItem('reportId')
    dispatch(
      setGeneratingReportGuardToken({ generatingReportGuardToken: true }),
    )

    navigate(AppRoutes.generatingReport)
  })

  return (
    <FormProvider {...form}>
      <WeScreen
        isLoading={isLoading}
        isError={isError}
        onClickNextButton={onSubmit}
      >
        <div className="flex flex-col justify-center h-full">
          <SwitchSection
            data={data}
            activeSwitchesCount={activeSwitchesCount}
            setActiveSwitchesCount={setActiveSwitchesCount}
            selectedTopics={selectedTopics}
          />
        </div>
      </WeScreen>
    </FormProvider>
  )
}
