import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

export const WorkSpaceCreationSecondStepInput = ({
  control,
  name,
  onChange,
  value,
  onRemove,
  showDeleteIcon,
}: {
  control: any
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  onRemove?: () => void
  showDeleteIcon?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <div className="">
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem className="">
            <div className="flex flex-col">
              <FormControl>
                <Input
                  {...field}
                  {...{ onChange, value }}
                  className="bg-baseColorHighlight"
                  placeholder={t('dashboard.company.createTeam.example')}
                  showDeleteIcon={showDeleteIcon}
                  onRemoveInput={onRemove}
                />
              </FormControl>
            </div>

            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
