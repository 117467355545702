import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'ramda'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'sonner'

import AddSocialMediaIcon from '@/assets/addSocialMediaIcon.svg?react'
import EditWithBorder from '@/assets/editWithBorder.svg?react'
import Facebook from '@/assets/socialMedia/Facebook.svg?react'
import Instagram from '@/assets/socialMedia/Instagram.svg?react'
import Linkedln from '@/assets/socialMedia/LinkedIn.svg?react'
import Pinterest from '@/assets/socialMedia/Pinterest.svg?react'
import Reddit from '@/assets/socialMedia/Reddit.svg?react'
import TikTok from '@/assets/socialMedia/TikTok.svg?react'
import X from '@/assets/socialMedia/X.svg?react'
import { Divider } from '@/components/Divider/Divider.component'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Sheet, SheetTrigger, SheetContent } from '@/components/ui/sheet'
import { useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import { selectIsDarkMode } from '@/store/api/ui/ui.selector'
import { sanitizeSocialMediaUrl } from '@/utils/sanitizeSocialMediaUrl'
import type { LoadSocialMediaUrlSchemaType } from '@/utils/schemas'
import { loadSocialMediaUrlSchema } from '@/utils/schemas'

import type { SocialMediaLink } from './NewBackgroundCheckForm.types'
import { SocialMediaItem } from './SocialMediaItem'

const socialMediaPatterns = {
  Facebook:
    /^(https?:\/\/)?(www\.)?(facebook\.com\/(profile\.php\?id=\d+|[A-Za-z0-9._-]+|p\/[A-Za-z0-9._-]+))$/,
  Twitter: /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9._-]+$/,
  Instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+$/,
  LinkedIn: /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9._-]+$/,
  TikTok: /^(https?:\/\/)?(www\.)?tiktok\.com\/@[A-Za-z0-9._-]+$/,
  Pinterest: /^(https?:\/\/)?(www\.)?pinterest\.com\/[A-Za-z0-9._-]+$/,
  Reddit: /^(https?:\/\/)?(www\.)?reddit\.com\/user\/[A-Za-z0-9._-]+\/?$/,
}

interface LoadSocialMediaSheetTypes {
  socialMediaArray: SocialMediaLink[]
  setSocialMediaArray: (newArray: SocialMediaLink[]) => void
}

export const LoadSocialMediaSheet = ({
  socialMediaArray,
  setSocialMediaArray,
}: LoadSocialMediaSheetTypes) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const socialMediaData = useSelector(selectNewSearch).socialMedia
  const isPrefillForm = useSelector(selectNewSearch).isPrefillForm

  const disabledLinkedinField = isPrefillForm && socialMediaData.linkedIn !== ''
  const form = useForm<LoadSocialMediaUrlSchemaType>({
    resolver: zodResolver(loadSocialMediaUrlSchema),
    defaultValues: {
      socialMediaUrl: '',
    },
    mode: 'onChange',
  })
  const isDarkMode = useAppSelector(selectIsDarkMode)
  const isMobile = useMobileMediaQuery()
  const {
    control,
    resetField,
    formState: { isDirty },
    setValue,
  } = form

  type SocialPlatform =
    | 'facebook'
    | 'instagram'
    | 'x'
    | 'tiktok'
    | 'linkedin'
    | 'twitter'
    | 'reddit'

  const addSocialMediaUrl = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    let socialMediaUrl = form.getValues().socialMediaUrl
    socialMediaUrl = sanitizeSocialMediaUrl(socialMediaUrl)

    setValue('socialMediaUrl', socialMediaUrl)

    let socialMediaType: SocialPlatform | null = null
    for (const [platform, pattern] of Object.entries(socialMediaPatterns)) {
      if (pattern.test(socialMediaUrl)) {
        socialMediaType = platform.toLowerCase() as SocialPlatform
        break
      }
    }

    if (socialMediaType) {
      const filteredArray = socialMediaArray.filter(
        (socialMedia) => socialMedia.type !== socialMediaType,
      )

      setSocialMediaArray([
        ...filteredArray,
        { type: socialMediaType, url: socialMediaUrl },
      ])
    } else {
      toast.error(t('feedback.toast.invalidURLError'))
    }

    resetField('socialMediaUrl')
  }

  const socialMediaTypes = useMemo(
    () => [
      { logo: Facebook, type: 'facebook' },
      { logo: Instagram, type: 'instagram' },
      { logo: Linkedln, type: 'linkedin' },
      { logo: TikTok, type: 'tiktok' },
      { logo: X, type: 'twitter' },
      { logo: Pinterest, type: 'pinterest' },
      { logo: Reddit, type: 'reddit' },
    ],
    [socialMediaArray],
  )

  const getSocialMediaUrl = (
    socialMedia:
      | 'x'
      | 'tiktok'
      | 'linkedin'
      | 'facebook'
      | 'instagram'
      | 'twitter'
      | 'pinterest'
      | 'reddit'
      | string,
  ) => socialMediaArray.find((item) => item.type === socialMedia)?.url

  const deleteUrl = (url: string) => {
    const newArray = socialMediaArray.filter(
      (socialMedia) => socialMedia.url !== url,
    )
    if (url) {
      setSocialMediaArray(newArray)
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger>
        <div role="button">
          {isEmpty(socialMediaArray) && (
            <AddSocialMediaIcon color={isDarkMode ? '#ffffff' : '#1c2226'} />
          )}
          {!isEmpty(socialMediaArray) && (
            <div className="ml-3">
              <EditWithBorder color={isDarkMode ? '#ffffff' : '#1c2226'} />
            </div>
          )}
        </div>
      </SheetTrigger>
      <SheetContent
        className="bg-baseColorHighlight lg:w-1/3 flex flex-col gap-6 overflow-y-scroll"
        side={isMobile ? 'bottom' : 'right'}
      >
        <div className="flex flex-col gap-6">
          <h1 className="text-neutralColor text-[24px] mt-6">
            {t('dashboard.newSearch.common.socialMediaProfiles')}
          </h1>
          <p className="text-neutralColor80">
            {t('dashboard.newSearch.common.enterUrl')}
          </p>
        </div>
        <FormField
          control={control}
          name="socialMediaUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-neutralColor">URL</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage data-name="socialMediaUrl" />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Button
            onClick={addSocialMediaUrl}
            className={isMobile ? 'w-full' : 'w-15'}
            disabled={!isDirty}
          >
            {t('dashboard.newSearch.common.add')}
          </Button>
        </div>

        {socialMediaTypes.map((socialMediaType, index) => (
          <div
            className="flex flex-col gap-4"
            key={`${index}-${socialMediaType.type}`}
          >
            <SocialMediaItem
              disabled={
                socialMediaType.type === 'linkedin' && disabledLinkedinField
              }
              key={`SocialMediaItem ${index}`}
              url={getSocialMediaUrl(socialMediaType.type)}
              Icon={socialMediaType.logo}
              deleteUrl={deleteUrl}
            />
            <Divider key={`Divider ${index}`} />
          </div>
        ))}
      </SheetContent>
    </Sheet>
  )
}
