import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  ETraductionKeysByUserRole,
  EUserPermission,
  EUserRole,
  EUserRoleSelector,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import type { Workspace } from '@/store/api/workspace/workspace.types'

import { OfficeCard } from './OfficeCard'

export const EditMemberCard = ({
  role,
  firstname,
  lastname,
  email,
  color,
  onRoleChange,
  workspaces = null,
  handleSelectedValueChange = () => {},
  handleUnselectedValueChange = () => {},
}: {
  role: EUserRole
  firstname: string
  lastname: string
  email: string
  color: string
  onRoleChange?: (role: EUserRoleSelector) => void
  workspaces?: Workspace[] | null
  handleSelectedValueChange?: (workspace: Workspace) => void
  handleUnselectedValueChange?: (workspace: Workspace) => void
}) => {
  const { t } = useTranslation()
  const { hasPermission } = usePermissions()

  const [selectedRole, setSelectedRole] = useState(role)

  const availableRoles = useMemo(() => {
    return Object.values(EUserRoleSelector).filter((role) =>
      hasPermission(EUserPermission.HANDLE_USERS, role as unknown as EUserRole),
    )
  }, [hasPermission])

  const hasDeletePermission = hasPermission(EUserPermission.HANDLE_USERS, role)
  const hasEditPermission = hasPermission(EUserPermission.HANDLE_USERS, role)
  const canNotRemove = role === EUserRole.OWNER || role === EUserRole.CO_OWNER

  const initials = useMemo(() => {
    const firstInitial = firstname[0]?.toUpperCase() ?? ''
    const secondInitial = lastname[0]?.toUpperCase() ?? ''
    return `${firstInitial} ${secondInitial}`
  }, [])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <Avatar>
            {firstname && (
              <AvatarFallback style={{ backgroundColor: color }}>
                {initials}
              </AvatarFallback>
            )}
          </Avatar>

          <div className="flex flex-col">
            <p>{`${firstname} ${lastname}`}</p>
            <p className="text-sm text-neutralColor60">{email}</p>
          </div>
        </div>

        {hasEditPermission && role !== EUserRole.OWNER ? (
          <div>
            <Select
              onValueChange={(value) => {
                setSelectedRole(value as EUserRole)
                if (onRoleChange) onRoleChange(value as EUserRoleSelector)
              }}
            >
              <SelectTrigger className="flex flex-row gap-3">
                <SelectValue
                  placeholder={t(ETraductionKeysByUserRole[selectedRole])}
                ></SelectValue>
              </SelectTrigger>

              <SelectContent>
                {Object.values(availableRoles).map((role) => (
                  <SelectItem key={role} value={role}>
                    <p>{t(ETraductionKeysByUserRole[role])}</p>
                  </SelectItem>
                ))}

                {hasDeletePermission && !canNotRemove && (
                  <SelectItem
                    className="flex flex-row n border-t-[0.5px] border-neutralColor60 rounded-none gap-2"
                    value="remove"
                  >
                    <p className="flex flex-row gap-4 items-center text-dynamicRed">
                      {t('dashboard.company.manageModal.remove')}
                    </p>
                  </SelectItem>
                )}
              </SelectContent>
            </Select>
          </div>
        ) : (
          <div className="flex flex-row gap-2 items-center pr-4">
            <p>{t(ETraductionKeysByUserRole[role])}</p>
          </div>
        )}
      </div>

      {hasEditPermission && role !== EUserRole.OWNER && workspaces ? (
        <>
          <div className="flex flex-row border-t-[0.5px] border-neutralColor60" />
          <p className="text-base">
            {t('dashboard.company.manageModal.teamAssignment')}
          </p>

          <div className="flex flex-col gap-5 max-h-[270px] overflow-y-auto pl-4 scrollbar-thin scrollbar-thumb-neutralColor60 scrollbar-track-transparent">
            {workspaces?.map((workspace) => (
              <OfficeCard
                key={workspace._id}
                workspace={workspace}
                onSelectedValueChange={handleSelectedValueChange}
                onUnselectedValueChange={handleUnselectedValueChange}
                selectedUser={{
                  userEmail: email,
                }}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  )
}
