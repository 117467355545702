import { isEmpty } from 'ramda'
import { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import DragAndDropEmpty from '@/assets/faceSearch/faceSearchDragAndDropIcon.svg?react'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Progress } from '@/components/ui/progress'
import { useAppSelector } from '@/hooks/redux'
import {
  useSearchResultsMutation,
  useUploadPicMutation,
} from '@/store/api/SearchFace/SearchFace.query'
import type { SearchPhotoResponse } from '@/store/api/SearchFace/SearchFace.types'
import { useLazyUserInfoQuery } from '@/store/api/Users/Users.query'
import {
  selectSelectedTeam,
  selectSelectedWorkStation,
} from '@/store/api/Users/Users.selector'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { sleep } from '@/utils/sleep'

import { FaceSearchResult } from './components/FaceSearchResults/FaceSearchResults'

export const FaceSearch = () => {
  const selectedWorkspace = useAppSelector(selectSelectedWorkStation)
  const selectedTeam = useAppSelector(selectSelectedTeam)

  const [searchResponse, setSearchResponse] = useState<
    SearchPhotoResponse | undefined
  >()

  const { t } = useTranslation()

  const [fileName, setFileName] = useState<string | undefined>()

  const [isLoadingFaceSearch, setIsLoadingFaceSearch] = useState(false)

  const [
    uploadPicMutation,
    {
      data: uploadPicData,

      reset: resetUpload,
      isLoading: isLoadingFaceUpload,
    },
  ] = useUploadPicMutation()

  const [refreshUserData] = useLazyUserInfoQuery()

  const [searchPhoto, { reset: resetSearch }] = useSearchResultsMutation()

  const onDropRejected = (e: any) => {
    if (
      !isEmpty(e) &&
      e[0].errors &&
      e[0].errors[0].code === 'file-too-large'
    ) {
      toast.error(t('dashboard.facialMatching.fileError1'))
    } else {
      toast.error(t('dashboard.facialMatching.fileError2'))
    }
  }

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (!acceptedFiles || isEmpty(acceptedFiles)) return

    const formData = new FormData()
    formData.append('images', acceptedFiles[0])
    await uploadPicMutation(formData).unwrap()
    setFileName(acceptedFiles[0].name)
  }, [])

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected: onDropRejected,
    disabled: false,
    noClick: true,
    accept: {
      'image/png': ['.png', '.jpg', '.webp', '.jpeg'],
    },
    maxFiles: 1,
    maxSize: 6000000,
  })

  const onNewSearch = () => {
    setSearchResponse(undefined)
    resetUpload()
    resetSearch()
    setFileName(undefined)
  }

  const userImage = useMemo(() => {
    if (uploadPicData && uploadPicData.code === 'IMAGE_ERROR') {
      toast.error(uploadPicData.error)
      onNewSearch()
      return
    }
    if (uploadPicData && !isEmpty(uploadPicData.input)) {
      return uploadPicData.input[0].base64
    }
  }, [uploadPicData])

  const makeSearchPhoto = async () => {
    if (!uploadPicData?.id_search) return
    setIsLoadingFaceSearch(true)
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const response = await searchPhoto({
        searchId: uploadPicData.id_search,
        id: selectedWorkspace?._id || '',
        teamId: selectedTeam?._id || '',
        reportType: 'facial_matching',
        workspace: selectedWorkspace?.name || '',
        team: selectedTeam?.name || '',
      }).unwrap()
      setSearchResponse(response)
      if (response.output) {
        refreshUserData()
        setIsLoadingFaceSearch(false)
        return
      }
      await sleep(500)
    }
  }

  const onPressLoadFile = () => {
    // `current` points to the mounted file input element
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div>
      <div>
        {searchResponse && searchResponse.output ? (
          <FaceSearchResult
            data={searchResponse.output.items}
            searchedImage={searchResponse.input[0].base64}
            onNewSearch={onNewSearch}
          />
        ) : (
          <>
            <div className="flex flex-col gap-6 w-full justify-center items-center max-xl:flex-col mt-4">
              <Card
                className={`flex justify-between flex-col lg:p-5 w-full ${
                  searchResponse ? 'h-[70vh]' : 'h-[85vh]'
                }  `}
              >
                <div
                  {...getRootProps()}
                  className="flex flex-col m-2 bg-primaryColor5 lg:m-6 border-[3px] border-primaryColor30 border-dashed rounded-lg flex-1 justify-center items-center p-6"
                >
                  <div className=" flex flex-col justify-center items-center flex-1 ">
                    <input {...getInputProps()} />
                    <div className="mb-8">
                      {userImage ? (
                        <img
                          src={userImage}
                          alt="Uploaded"
                          className="max-w-40 max-h-40 "
                        />
                      ) : isLoadingFaceUpload ? (
                        <div className="flex justify-center items-center w-full h-full">
                          <LottieSpinner
                            width={50}
                            height={50}
                            text={t('dashboard.loading.loading')}
                          />
                        </div>
                      ) : (
                        <DragAndDropEmpty color={weCheckPalette.dynamicBlue} />
                      )}
                    </div>

                    <h1 className="text-2xl font-medium tracking-wide mb-2">
                      {t('dashboard.facialMatching.facialMatching')}
                    </h1>
                    {!searchResponse && (
                      <div className="flex flex-col gap-4 justify-center items-center">
                        <div className="flex flex-col lg:flex-row items-center gap-2">
                          <p className="text-neutralColor60 text-base font-normal tracking-wider">
                            {t('dashboard.facialMatching.addImage')}
                          </p>
                          <div className="flex flex-row gap-2">
                            <div className="rounded-full py-1 px-2 bg-[#C6F5FF] text-xs text-[#037797]">
                              JPG
                            </div>
                            <div className="rounded-full py-1 px-2 bg-[#C8FFD4] text-[#1E924D] text-xs">
                              PNG
                            </div>
                            <div className="rounded-full py-1 px-2 bg-[#FFFDCB] text-[#676427] text-xs">
                              WEBP
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-3">
                          <Button
                            onClick={onPressLoadFile}
                            isLoading={isLoadingFaceUpload}
                            variant={'secondary'}
                            className="py-5"
                          >
                            {t('dashboard.facialMatching.chooseFile')}
                          </Button>
                          <Button
                            className="z-50 py-5"
                            onClick={makeSearchPhoto}
                            isLoading={isLoadingFaceSearch}
                            disabled={!uploadPicData}
                          >
                            {t('dashboard.facialMatching.runSearch')}
                          </Button>
                        </div>
                        <p className="text-neutralColor60 text-base">
                          {t('dashboard.facialMatching.orDropFiles')}
                        </p>
                        <p className="text-neutralColor60 text-base">
                          Max. 6MB
                        </p>
                      </div>
                    )}
                    {searchResponse && (
                      <p className="text-textAccent tracking-wider">
                        {fileName}
                      </p>
                    )}
                  </div>
                  {!searchResponse && (
                    <div className=" text-center lg:flex lg:flex-row lg:justify-center lg:w-full">
                      <p className="text-neutralColor text-xs lg:ml-1">
                        <span
                          className="text-linkColor "
                          role="button"
                          onClick={() =>
                            window.open(
                              t('dashboard.common.termsAndConditionsLink'),
                              '_blank',
                            )
                          }
                          tabIndex={0}
                        >
                          {t('dashboard.facialMatching.termsAndConditions')}
                        </span>
                        &nbsp;
                        {t('dashboard.facialMatching.apply1Credit')}
                      </p>
                    </div>
                  )}
                </div>
              </Card>
              {searchResponse && (
                <Card className="flex justify-between flex-col  px-4 py-6 basis-1/2 gap-6 w-full">
                  <div className="flex flex-col gap-1">
                    <p className="text-xl font-semibold tracking-wide flex flex-row">
                      {searchResponse.progress}%{' '}
                      {t('dashboard.facialMatching.loading')}
                      <p className="animate-pulse">...</p>
                    </p>
                    <p className="tracking-wider text-textAccent">
                      {searchResponse.message}
                    </p>

                    <Progress
                      value={searchResponse.progress ?? 0}
                      className="h-4 mt-1"
                    />
                  </div>
                </Card>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
