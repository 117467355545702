import { zodResolver } from '@hookform/resolvers/zod'
import { SearchNormal1 } from 'iconsax-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'
import { InsufficientCreditsAlert } from '@/pages/Search/Components/InsufficientCreditsAlert'
import { setSearchResultsGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  setSearchResultsInfo,
  setUseCases,
} from '@/store/api/NewSearch/NewSearch.slice'
import type { listOfUseCasesResponse } from '@/store/api/NewSearch/NewSearch.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { sanitizeSocialMediaUrl } from '@/utils/sanitizeSocialMediaUrl'
import type { searchInputSchemaTypes } from '@/utils/schemas'
import { linkedInUrlRegex, searchInputSchema } from '@/utils/schemas'
import { parseFullName } from '@/utils/strings'

import { Button } from '../ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from '../ui/form'
import { Input } from '../ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select'

export const InputsSection = ({
  hasSufficientCredits,
  data,
}: {
  hasSufficientCredits: boolean
  data?: listOfUseCasesResponse
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openInsufficientCreditsAlert, setOpenInsufficientCreditsAlert] =
    useState(false)

  const {
    t,
    i18n: { language },
  } = useTranslation()
  const form = useForm<searchInputSchemaTypes>({
    resolver: zodResolver(searchInputSchema),
    defaultValues: {
      searchInput: '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, control, formState } = form

  const filteredUseCases = data?.info.filter(
    (useCase) => !useCase.isForCompanySearch && !useCase.requiredComplianceForm,
  )

  const onSubmit = (data: any) => {
    let searchInput = data.searchInput

    if (!hasSufficientCredits) {
      setOpenInsufficientCreditsAlert(true)
      return
    }

    if (linkedInUrlRegex.test(searchInput)) {
      searchInput = sanitizeSocialMediaUrl(searchInput)

      dispatch(
        setSearchResultsInfo({
          linkedInUrl: searchInput,
        }),
      )
    } else {
      const { firstName, lastName } = parseFullName(searchInput)
      dispatch(
        setSearchResultsInfo({
          firstName,
          lastName,
        }),
      )
    }

    dispatch(
      setUseCases({
        selectedUseCaseId,
      }),
    )
    dispatch(setSearchResultsGuardToken({ searchResultsGuardToken: true }))

    navigate(AppRoutes.searchResults)
  }

  const [selectedUseCaseId, setSelectedUseCaseId] = useState<string>('')
  const [selectedUseCaseName, setSelectedUseCaseName] = useState<
    string | undefined
  >('')

  const handleUseCaseChange = (useCase: string) => {
    const selected = data?.info.find((item) => item._id === useCase)
    if (selected) {
      const selectedName = selected?.translations.find(
        (t) => t.language === language,
      )
      setSelectedUseCaseName(selectedName?.name || '')
      setSelectedUseCaseId(selected._id)
    }
  }

  return (
    <div className="flex flex-col gap-6">
      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex gap-1.5 w-full flex-col"
        >
          <FormField
            control={control}
            name="searchInput"
            render={({ field }) => (
              <FormItem className="">
                <FormControl>
                  <Input
                    {...field}
                    placeholder={t('dashboard.home.inputPlaceHolder')}
                    className="h-[46px] rounded-lg"
                    icon={
                      <SearchNormal1
                        className="absolute mt-3.5 mr-4"
                        size="18"
                        color={weCheckPalette.neutralColor30}
                      />
                    }
                  />
                </FormControl>
                <div className="flex justify-between">
                  <FormDescription>
                    {t('dashboard.home.inputLabel')}
                  </FormDescription>
                  <FormMessage data-name="searchInput" />
                </div>
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-2">
            <Select onValueChange={handleUseCaseChange}>
              <SelectTrigger className="h-[46px] rounded-lg w-full">
                <SelectValue
                  placeholder={
                    selectedUseCaseName !== ''
                      ? selectedUseCaseName
                      : t('dashboard.newSearch.useCases.selectUseCase')
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t('Select Use Case')}</SelectLabel>
                  {filteredUseCases?.map((useCase, index) => {
                    const translation = useCase.translations.find(
                      (t) => t.language === language,
                    )
                    return (
                      <SelectItem
                        key={index}
                        value={useCase._id || ''}
                        className="flex items-center hover:bg-primaryColor5 gap-2"
                      >
                        <p>{translation?.name}</p>
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
            <FormDescription>{t('dashboard.home.useCase')}</FormDescription>
          </div>

          <div className="flex justify-end">
            <Button
              className="h-[45px] w-1/3"
              disabled={!formState.isValid || !selectedUseCaseName}
            >
              <p className="text-base font-bold">
                {t('dashboard.home.search')}
              </p>
            </Button>
          </div>

          <InsufficientCreditsAlert
            open={openInsufficientCreditsAlert}
            setOpen={setOpenInsufficientCreditsAlert}
          />
        </form>
      </Form>
    </div>
  )
}
