import * as ProgressPrimitive from '@radix-ui/react-progress'
import * as React from 'react'

import { cn } from '@/lib/utils'

interface ProgressProps
  extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  color?: string
  animationDuration?: number
  isLoadingBar?: boolean
}

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(
  (
    {
      className,
      value,
      color,
      animationDuration = 300,
      isLoadingBar = false,
      ...props
    },
    ref,
  ) => {
    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          'relative h-2 w-full overflow-hidden rounded-full bg-primary/20',
          className,
        )}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className={`h-full w-full flex-1 transition-all ${
            isLoadingBar && 'animate-pulse'
          }`}
          style={{
            transform: `translateX(-${100 - (value || 0)}%)`,
            backgroundColor: color ? color : '#323232',
            transitionDuration: `${animationDuration}ms`,
          }}
        />
      </ProgressPrimitive.Root>
    )
  },
)

Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
