import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

import type { CreateWorkSpaceSliceState } from './CreateWorkSpace.slice'

export const selectWorkSpace = (state: RootState) => state.createWorkSpace

export const selectCreateWorkSpaceFirstStep = createSelector(
  selectWorkSpace,
  (createWorkSpaceState: CreateWorkSpaceSliceState) =>
    createWorkSpaceState.CreateWorkSpaceFirstStep,
)

export const selectCreateWorkSpaceSecondStep = createSelector(
  selectWorkSpace,
  (createWorkSpaceState: CreateWorkSpaceSliceState) =>
    createWorkSpaceState.CreateWorkSpaceSecondStep,
)

export const selectCreateWorkSpaceThirdStep = createSelector(
  selectWorkSpace,
  (createWorkSpaceState: CreateWorkSpaceSliceState) =>
    createWorkSpaceState.CreateWorkSpaceThirdStep,
)
