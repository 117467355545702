import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

import type { CreateTeamSliceState } from './CreateTeam.slice'

export const selectTeam = (state: RootState) => state.createTeam

export const selectCreateTeamFirstStep = createSelector(
  selectTeam,
  (createTeamState: CreateTeamSliceState) =>
    createTeamState.CreateTeamFirstStep,
)

export const selectCreateTeamSecondStep = createSelector(
  selectTeam,
  (createTeamState: CreateTeamSliceState) =>
    createTeamState.CreateTeamSecondStep,
)
