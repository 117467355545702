import { CloseSquare, SearchNormal1 } from 'iconsax-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import AvatarPending from '@/assets/Avatar-pending.svg?react'
import { AlertDialog, AlertDialogContent } from '@/components/ui/alert-dialog'
import { Avatar } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import type {
  EUserRole,
  EUserRoleSelector,
} from '@/hooks/usePermissions/permissions.type'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import { useUpdateEnterpriseUserRoleMutation } from '@/store/api/Enterprise/Enterprise.query'
import {
  useInviteMemberToATeamMutation,
  useRemoveMemberFromTeamMutation,
} from '@/store/api/workspace/workspace.query'
import type { UserData, Workspace } from '@/store/api/workspace/workspace.types'
import { getColorByName } from '@/utils/colorByName'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { DobleAvatarPlusNameCard } from './DobleAvatarPlusNameCard'
import { EditMemberCard } from './EditMemberCard'
import { MemberItem } from './MemberItem'
import { PopUpTopHeader } from './PopUpTopHeader'

export const ManageOffice = ({
  isOpen,
  setIsOpen,
  workspace,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  workspace: Workspace
}) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()

  const [showMore, setShowMore] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const [selectedUser, setSelectedUser] = useState<UserData | null>(null)

  const [selectedRole, setSelectedRole] = useState<
    EUserRoleSelector | 'remove' | null
  >(null)

  const [selectedValuesArray, setSelectedValuesArray] = useState<{
    [key: string]: any[]
  }>({})

  const [unselectedValuesArray, setUnselectedValuesArray] = useState<{
    [key: string]: any[]
  }>({})

  const [inviteMemberToATeam, { isLoading: isInvitingMember }] =
    useInviteMemberToATeamMutation()

  const [removeMemberFromTeam, { isLoading: isRemovingMember }] =
    useRemoveMemberFromTeamMutation()

  const handleRoleChange = (role: EUserRoleSelector) => {
    setSelectedRole(role)
  }

  const [updateMemberRole, { isLoading: isUpdatingMemberRole }] =
    useUpdateEnterpriseUserRoleMutation()

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  const closeModal = () => {
    setIsOpen(false)
    setShowMore(false)
    setSearchTerm('')
    setSelectedUser(null)
    setSelectedRole(null)
  }

  // const handleInviteMember = async (data: inviteMemberValidationType) => {
  //   const promises = []

  //   for (const team of workspace?.teams ?? []) {
  //     promises.push(
  //       inviteMemberToATeam({
  //         id: workspace?._id ?? '',
  //         email: data.email,
  //         role: data.role,
  //         teamId: team._id,
  //       }).unwrap(),
  //     )
  //   }

  //   try {
  //     await Promise.all(promises)
  //     toast.success(t('dashboard.company.manageModal.inviteSuccess'))
  //     closeModal()
  //   } catch (e) {
  //     if (e.data?.code === 'EMAIL_DUP') {
  //       toast.error(t('dashboard.company.manageModal.userIsAlreadyInvited'))
  //     } else if (e.data?.code === 'USER_NOT_AVAILABLE') {
  //       toast.error(t('dashboard.company.manageModal.userNotAvailable'))
  //     } else {
  //       toast.error(t('dashboard.company.manageModal.errorSendingInvitation'))
  //     }
  //   }
  // }

  const handleRemoveMember = async (userId: string) => {
    const promises = []

    for (const workspaceId in selectedValuesArray) {
      const teams = selectedValuesArray[workspaceId]

      for (const team of teams) {
        promises.push(
          removeMemberFromTeam({
            id: workspace?._id ?? '',
            teamId: team._id,
            userId,
          }).unwrap(),
        )
      }
    }

    try {
      await Promise.all(promises)
      toast.success(t('dashboard.company.manageModal.memberRemoved'))
    } catch (error) {
      toast.error(t('dashboard.company.manageModal.errorRemovingMember'))
    }
  }

  const handleTeamAssignment = async () => {
    if (!selectedUser?.userEmail) {
      toast.error(t('dashboard.company.manageModal.emailRequired'))
      return
    }

    const promises = []

    for (const workspaceId in selectedValuesArray) {
      const teams = selectedValuesArray[workspaceId]

      for (const team of teams) {
        const addMember = {
          id: workspaceId,
          email: selectedUser.userEmail,
          role: selectedUser.user?.role ?? '',
          teamId: team.id,
        }
        promises.push(inviteMemberToATeam(addMember).unwrap())
      }
    }

    for (const workspaceId in unselectedValuesArray) {
      const teams = unselectedValuesArray[workspaceId]

      for (const team of teams) {
        promises.push(
          removeMemberFromTeam({
            id: workspaceId,
            teamId: team.id,
            userId: selectedUser.user?._id ?? '',
          }).unwrap(),
        )
      }
    }

    try {
      await Promise.all(promises)
      toast.success(t('dashboard.company.manageModal.memberTeamUpdated'))
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.company.manageModal.memberTeamUpdatedError'))
    }
  }

  const handleUpdateMemberRole = async () => {
    if (!selectedUser?.user?._id) return

    try {
      await updateMemberRole({
        id: selectedUser?.user?._id ?? '',
        role: selectedRole ?? '',
      }).unwrap()
      toast.success(t('dashboard.company.manageModal.memberRoleUpdated'))
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.company.manageModal.memberRoleUpdatedError'))
    }
  }

  const handleSaveEditMember = async () => {
    // Handle role change
    if (selectedRole && selectedRole !== selectedUser?.user?.role) {
      if (selectedRole === 'remove') {
        await handleRemoveMember(selectedUser?._id ?? '')
      } else {
        await handleUpdateMemberRole()
      }
    }

    // Handle team assignment
    if (
      Object.keys(selectedValuesArray).length > 0 ||
      Object.keys(unselectedValuesArray).length > 0
    ) {
      await handleTeamAssignment()
    }

    setSelectedUser(null)
    setSelectedRole(null)
    setSelectedValuesArray({})
    setUnselectedValuesArray({})
  }

  const handleSelectedValueChange = (team: any) => {
    setSelectedValuesArray((prev) => {
      const { _id: workspaceId } = workspace as Workspace
      if (!workspaceId) return prev

      return {
        ...prev,
        [workspaceId]: prev[workspaceId]
          ? [...prev[workspaceId], { ...team, workspaceId }]
          : [{ ...team, workspaceId }],
      }
    })
  }

  const handleUnselectedValueChange = (team: any) => {
    setUnselectedValuesArray((prev) => {
      const { workspaceId } = team
      return {
        ...prev,
        [workspaceId]: prev[workspaceId]
          ? [...prev[workspaceId], { ...team, workspaceId }]
          : [{ ...team, workspaceId }],
      }
    })
  }

  const handleEditMember = (user: UserData) => {
    setSelectedUser(user)
    setShowMore(true)

    if (user?.user?.role) {
      setSelectedRole(user.user.role as EUserRoleSelector)
    }
  }

  const filterMembers = (user: UserData) => {
    const { user: userData, userEmail } = user
    const firstName = userData?.firstname ?? ''
    const lastName = userData?.lastname ?? ''

    const fullName = `${firstName} ${lastName}`

    return (
      fullName.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      userEmail.toLowerCase().includes(searchTerm?.toLowerCase())
    )
  }

  const isLoading = isRemovingMember || isInvitingMember || isUpdatingMemberRole

  const nameShowInOthers =
    workspace?.allMembers?.[2]?.user?.firstname ??
    workspace?.allMembers?.[2]?.userEmail
  const nameForInitials =
    workspace?.allMembers?.[3]?.user?.firstname ??
    workspace?.allMembers?.[3]?.userEmail

  const showMoreMembers =
    !selectedUser &&
    !showMore &&
    workspace?.allMembers?.length &&
    workspace?.allMembers?.length > 2
  const showPreviewMembers = workspace?.allMembers && showMore && !selectedUser

  return (
    <AlertDialog open={isOpen} onOpenChange={closeModal}>
      <AlertDialogContent
        className={`flex flex-col gap-3 ${
          isMobile ? 'w-[350px]' : isSmallMobile ? 'w-[250px]' : 'w-[500px]'
        }`}
      >
        <div className="flex flex-1 flex-col gap-3">
          {/* Modal Home */}
          {!showMore && (
            <>
              <div className="flex flex-row justify-between items-center">
                <p className="text-base font-bold">{workspace?.name}</p>

                <div className="flex flex-row justify-end items-center border-neutralColor60">
                  <Button
                    onClick={closeModal}
                    variant="link"
                    className="text-base"
                  >
                    <CloseSquare
                      width={18}
                      height={17}
                      variant="Bold"
                      color={weCheckPalette.neutralColor}
                    />
                  </Button>
                </div>
              </div>

              {/* Invite Member */}

              {/* TODO: Fix the backend to handle multiple invites
                Seem like the backend is not handling the multiple invites
                correctly for concurrency request to MongoDB.
                This is a temporary fix to avoid the issue.
              */}

              {/* <div className="flex flex-col justify-between gap-4">
                <div>
                  <p className="text-neutralColor60 text-sml">
                    {t('dashboard.company.manageModal.memberWillJoinAllTeams')}
                  </p>
                </div>
                <InviteMemberForm
                  onInvite={handleInviteMember}
                  isLoading={isInvitingMember}
                />
              </div> */}

              {/* Preview Members */}
              {workspace?.allMembers?.length ? (
                <div className="flex flex-col gap-4">
                  <div>
                    <p className="text-neutralColor60 text-sml">
                      {t('dashboard.company.manageModal.whoHasAccess')}
                    </p>
                  </div>

                  {workspace?.allMembers?.slice(0, 2).map((user, index) => (
                    <MemberItem
                      key={`${user._id}-${index}`}
                      firstname={user.user?.firstname ?? ''}
                      lastname={user.user?.lastname ?? ''}
                      email={user.userEmail}
                      invite={user.invite}
                      role={user.user?.role ?? ''}
                      color={getColorByName(
                        user.user?.firstname ?? user.userEmail,
                        user.user?.lastname ?? '',
                      )}
                      onClick={() => {
                        setSelectedUser(user)
                        setSelectedRole(user.user?.role as EUserRoleSelector)
                        setShowMore(true)
                      }}
                    />
                  ))}
                </div>
              ) : null}
            </>
          )}

          {/* Show more members */}
          {showMoreMembers ? (
            <div className="flex flex-1 flex-col">
              <button
                onClick={handleShowMore}
                className="flex flex-1 text-neutralColor"
              >
                {showMore ? (
                  ''
                ) : (
                  <div className="flex flex-1 my-4">
                    <DobleAvatarPlusNameCard
                      nameShowInOthers={nameShowInOthers}
                      nameForInitials={nameForInitials}
                      countElements={workspace?.allMembers?.length}
                      rightIcon
                    />
                  </div>
                )}
              </button>
            </div>
          ) : null}

          {/* View list of all members */}
          {showPreviewMembers && (
            <div className="flex flex-1 flex-col">
              <PopUpTopHeader
                text={`${workspace?.allMembers?.length} ${t(
                  'dashboard.company.manageModal.colaboratorsOffice',
                )}`}
                onClick={() => {
                  setShowMore(false)
                  setSearchTerm('')
                }}
                setIsOpen={closeModal}
              />

              <Input
                placeholder={t('dashboard.company.search')}
                className="h-[46px] rounded-lg w-full p-4 mb-2 mt-2"
                icon={
                  <SearchNormal1
                    className="absolute mt-6 mr-4"
                    size="18"
                    color={weCheckPalette.neutralColor30}
                  />
                }
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div className="flex flex-1 flex-col max-h-[330px] overflow-y-auto gap-3 mt-4 scrollbar-thin scrollbar-thumb-neutralColor60 scrollbar-track-transparent">
                {workspace?.allMembers
                  ?.filter(filterMembers)
                  .map((user, index) => (
                    <div key={`${user._id}-member-item-${index}`}>
                      <MemberItem
                        key={`${user.userEmail}`}
                        firstname={user.user?.firstname ?? user.userEmail}
                        lastname={user.user?.lastname ?? ''}
                        invite={user.invite}
                        email={user.userEmail}
                        role={user.user?.role ?? ''}
                        onClick={() => handleEditMember(user)}
                        color={getColorByName(
                          user.user?.firstname ?? user.userEmail,
                          user.user?.lastname ?? '',
                        )}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* Edit Selected User View*/}
          {selectedUser && (
            <div className="flex flex-1 flex-col gap-4">
              {!selectedUser?.invite ? (
                <>
                  <PopUpTopHeader
                    text={t('dashboard.company.manageModal.memberSettings')}
                    onClick={() => setSelectedUser(null)}
                    setIsOpen={closeModal}
                  />

                  <EditMemberCard
                    role={(selectedUser?.user?.role as EUserRole) ?? undefined}
                    firstname={selectedUser.user?.firstname ?? ''}
                    lastname={selectedUser.user?.lastname ?? ''}
                    email={selectedUser.userEmail}
                    color={getColorByName(
                      selectedUser.user?.firstname ?? selectedUser.userEmail,
                      selectedUser.user?.lastname ?? '',
                    )}
                    onRoleChange={handleRoleChange}
                    workspaces={[workspace]}
                    handleSelectedValueChange={handleSelectedValueChange}
                    handleUnselectedValueChange={handleUnselectedValueChange}
                  />

                  <div className="flex flex-1 flex-col justify-end">
                    <div className="flex flex-row gap-3 justify-end border-t-[0.5px] border-neutralColor60 pt-3">
                      <Button
                        variant="secondary"
                        onClick={() => setSelectedUser(null)}
                        disabled={isLoading}
                      >
                        {t('dashboard.company.manageModal.cancel')}
                      </Button>
                      <Button
                        variant="default"
                        onClick={handleSaveEditMember}
                        isLoading={isLoading}
                      >
                        {t('dashboard.company.manageModal.save')}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <PopUpTopHeader
                    text={t('dashboard.company.editInvite.inviteSettings')}
                    onClick={() => setSelectedUser(null)}
                    setIsOpen={closeModal}
                  />

                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <Avatar>
                        <AvatarPending />
                      </Avatar>

                      <div className="flex flex-col">
                        <p className="text-sm text-neutralColor60">
                          {selectedUser?.userEmail}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
