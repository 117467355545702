const COLORS = [
  '#FF6B6B', // Red
  '#4ECDC4', // Turquoise
  '#45B7D1', // Light Blue
  '#96CEB4', // Sage Green
  '#FFEEAD', // Light Yellow
  '#D4A5A5', // Dusty Rose
  '#9B59B6', // Purple
  '#3498DB', // Blue
  '#E67E22', // Orange
  '#1ABC9C', // Teal
  '#2ECC71', // Green
  '#F1C40F', // Yellow
  '#E74C3C', // Dark Red
  '#8E44AD', // Dark Purple
  '#16A085', // Dark Teal
  '#D35400', // Dark Orange
  '#27AE60', // Dark Green
  '#2980B9', // Dark Blue
  '#F39C12', // Golden
  '#C0392B', // Burgundy
  '#D98880', // Salmon
  '#A569BD', // Light Purple
  '#5DADE2', // Sky Blue
  '#48C9B0', // Mint
  '#52BE80', // Forest Green
  '#EB984E', // Light Orange
]

export const getColorByName = (name: string, surname?: string): string => {
  const fullName = `${name}${surname || ''}`.toLowerCase()

  // Use multiple hash functions to increase randomness
  let hash1 = 0
  let hash2 = 0
  let hash3 = 0

  for (let i = 0; i < fullName.length; i++) {
    const char = fullName.charCodeAt(i)
    hash1 = char + ((hash1 << 5) - hash1)
    hash2 = char + ((hash2 << 7) - hash2)
    hash3 = char + ((hash3 << 11) - hash3)
  }

  // Combine hashes and use prime numbers to increase distribution
  const combinedHash = Math.abs((hash1 * 31) ^ (hash2 * 37) ^ (hash3 * 41))

  const index = combinedHash % COLORS.length

  return COLORS[index]
}
