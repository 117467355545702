import React from 'react'
import { useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import type { EReportType } from '@/store/api/Enterprise/Enterprise.types'
import type { Workspace } from '@/store/api/workspace/workspace.types'
import type { creditsSchemaType } from '@/utils/schemas'

interface IWorkSpaces {
  title?: string
  creditsAvalible?: number
  icon?: any
  workspace: Workspace
  type: EReportType
}

export const ManageCreditsSheetCard = ({
  title,
  creditsAvalible,
  icon,
  type,
}: IWorkSpaces) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<creditsSchemaType>()

  return (
    <div
      className={` bg-baseColor flex flex-col p-3 justify-between gap-2 rounded-lg w-full `}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between gap-3">
          <p className="text-neutralColor text-lg">{title}</p>
          {icon}
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center">
        <div className="w-full">
          <FormField
            control={control}
            name={type}
            render={({ field }) => (
              <FormItem className="">
                <div className="flex flex-col">
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      disabled={isSubmitting}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value)
                        field.onChange(value)
                      }}
                      className="bg-primaryColor5 text-center border-none"
                    />
                  </FormControl>
                </div>

                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-neutralColor60 text-sm">Avalible Credits</p>
        <p className="text-neutralColor60 text-sm">Max. {creditsAvalible}</p>
      </div>
    </div>
  )
}
