import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { selectCreateWorkSpaceFirstStep } from '@/store/api/CreateWorkSpace/CreateWorkSpace.selector'
import { setCreateWorkSpaceFirstStep } from '@/store/api/CreateWorkSpace/CreateWorkSpace.slice'
import type { workSpaceSchemaType } from '@/utils/schemas'
import { workSpaceSchema } from '@/utils/schemas'

import { Stepper } from '../Search/Components/Stepper'

export const WorkSpaceCreation = () => {
  const { t } = useTranslation()
  const steps = [
    t('dashboard.company.createTeam.nameYourWorkspace'),
    t('dashboard.company.createTeam.teamSetup'),
    t('dashboard.company.createTeam.inviteMembers'),
  ]
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const createCreateWorkSpaceFirstStep = useAppSelector(
    selectCreateWorkSpaceFirstStep,
  )
  const form = useForm<workSpaceSchemaType>({
    resolver: zodResolver(workSpaceSchema),
    defaultValues: {
      workSpaceName: createCreateWorkSpaceFirstStep.workSpaceName || '',
    },
    mode: 'onChange',
  })
  const { handleSubmit, control, formState } = form
  const { errors } = formState
  const activeStep = 0
  const isWorkSpaceNameValid =
    form.watch('workSpaceName')?.trim().length > 0 && !errors.workSpaceName
  const disabledContinueButton = !isWorkSpaceNameValid

  const onSubmit = (data: any) => {
    dispatch(
      setCreateWorkSpaceFirstStep({
        workSpaceName: data.workSpaceName,
      }),
    )
    navigate(AppRoutes.workSpaceCreationSecondStep)
  }
  return (
    <WeScreen
      title={''}
      isLoading={false}
      isError={false}
      isDisabledNextButton={disabledContinueButton}
      onClickNextButton={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col items-center gap-3 xl:gap-6 h-full">
        <div className="flex">
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="flex flex-col justify-center xl:mt-44">
          <Form {...form}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col max-md:gap-6 gap-6"
            >
              <div className="flex flex-col gap-3">
                <p className="text-center text-xl font-medium text-neutralColor">
                  {t('dashboard.company.createTeam.createAWorkspaceTitle')}
                </p>
                <p className="text-center font-normal text-base text-neutralColor80">
                  {t('dashboard.company.createTeam.createAWorkspaceText')}
                </p>
                <FormField
                  control={control}
                  name="workSpaceName"
                  render={({ field }) => (
                    <FormItem className="">
                      <div className="flex flex-col">
                        <FormControl>
                          <Input
                            {...field}
                            className="bg-baseColorHighlight"
                            placeholder={t(
                              'dashboard.company.createTeam.example',
                            )}
                          />
                        </FormControl>
                      </div>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>
        </div>
      </div>
    </WeScreen>
  )
}
