import { ArrowRight2 } from 'iconsax-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import AvatarPending from '@/assets/Avatar-pending.svg?react'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { getColorByName } from '@/utils/colorByName'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const DobleAvatarPlusNameCard = ({
  nameShowInOthers,
  nameForInitials,
  countElements = 0,
  rightIcon,
}: {
  nameShowInOthers: string | undefined
  nameForInitials: string | undefined
  countElements?: number
  rightIcon?: boolean
}) => {
  const { t } = useTranslation()

  const isEmail = (email: string) => {
    return email.includes('@')
  }

  return (
    <div className="flex flex-1 flex-row items-center justify-between">
      <div className="flex flex-row items-center">
        <div className="relative bottom-4">
          {nameShowInOthers &&
            (isEmail(nameShowInOthers) ? (
              <Avatar className="absolute top-4 left-3 w-6 h-6">
                <AvatarPending />
              </Avatar>
            ) : (
              <Avatar
                className={`absolute top-0 left-0 ${
                  !nameForInitials ? 'w-10 h-10' : 'w-6 h-6'
                }`}
              >
                <AvatarImage />
                <AvatarFallback
                  className="text-neutralColor"
                  style={{ backgroundColor: getColorByName(nameShowInOthers) }}
                >
                  {nameShowInOthers.slice(0, 1).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            ))}

          {nameForInitials &&
            (isEmail(nameForInitials) ? (
              <Avatar className="absolute top-4 left-3 w-6 h-6">
                <AvatarPending />
              </Avatar>
            ) : (
              <Avatar className="absolute top-4 left-3 w-6 h-6">
                <AvatarImage />
                <AvatarFallback
                  className="text-neutralColor"
                  style={{ backgroundColor: getColorByName(nameForInitials) }}
                >
                  {nameForInitials.slice(0, 1).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            ))}
        </div>

        <div>
          <p className="text-neutralColor60 text-base ml-12">
            {nameShowInOthers && `${nameShowInOthers} `}
            {countElements > 3 &&
              `${t('dashboard.company.dobleAvatarPlusNameCard.and')} ${
                countElements - 3
              } ${t('dashboard.company.dobleAvatarPlusNameCard.others')}`}
          </p>
        </div>
      </div>

      {rightIcon && (
        <ArrowRight2 size="16" color={weCheckPalette.neutralColor60} />
      )}
    </div>
  )
}
