import { ArrowRight2 } from 'iconsax-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import AvatarPending from '@/assets/Avatar-pending.svg?react'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import type { EUserRole } from '@/hooks/usePermissions/permissions.type'
import { ETraductionKeysByUserRole } from '@/hooks/usePermissions/permissions.type'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import { selectUserInfo } from '@/store/api/Users/Users.selector'
import type { InviteData } from '@/store/api/workspace/workspace.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const MemberItem = ({
  firstname,
  lastname,
  email,
  role,
  invite = null,
  color,
  onClick,
}: {
  firstname: string | ''
  lastname: string | ''
  email: string
  role: string | null
  invite?: InviteData | null
  color: string
  onClick?: () => void
}) => {
  const { t } = useTranslation()
  const userInfo = useSelector(selectUserInfo)
  const isInvite = invite !== null

  const initials = useMemo(() => {
    if (isInvite) return

    const firstInitial = firstname?.[0]?.toUpperCase() ?? ''
    const secondInitial = lastname?.[0]?.toUpperCase() ?? ''

    return `${firstInitial} ${secondInitial}`
  }, [firstname, lastname])

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row items-center gap-3">
        {!isInvite ? (
          <Avatar>
            <AvatarFallback
              style={{ backgroundColor: color }}
              className="text-neutralColor"
            >
              {initials}
            </AvatarFallback>
          </Avatar>
        ) : (
          <Avatar>
            <AvatarPending />
          </Avatar>
        )}

        {isInvite ? <p>{email}</p> : <p>{`${firstname} ${lastname}`}</p>}
        {email === userInfo?.email && (
          <Chip text={t('dashboard.company.memberItem.you')} green />
        )}
      </div>

      {!isInvite ? (
        // Member Settings
        <div className="flex flex-row items-center">
          <Button
            className="flex flex-row items-center gap-2 p-0 m-0"
            variant="link"
            onClick={onClick}
          >
            <p>{t(ETraductionKeysByUserRole[role as EUserRole])}</p>

            <ArrowRight2 size="16" color={weCheckPalette.neutralColor60} />
          </Button>
        </div>
      ) : (
        // Invite Settings
        <div className="flex flex-row items-center">
          <Button
            className="flex flex-row items-center gap-2 p-0 m-0"
            variant="link"
            onClick={onClick}
          >
            <p className="mr-2 text-neutralColor60">
              {t('dashboard.company.memberItem.invitationSent')}
            </p>

            <ArrowRight2 size="16" color={weCheckPalette.neutralColor60} />
          </Button>
        </div>
      )}
    </div>
  )
}
