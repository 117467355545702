import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Card } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'
import { type SocialMediaAccount } from '@/store/api/Person/Person.types'

import { InfoDisplayItem } from './InfoDisplayCard'

interface SocialMediaAccountsProps {
  accounts: SocialMediaAccount[]
  socialCheckId: string
}

const SocialMediaAccounts = ({
  accounts,
  socialCheckId,
}: SocialMediaAccountsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Card className="p-4 max-h-[366px] overflow-y-scroll custom-scrollbar flex flex-col gap-6 rounded-2xl">
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-xl font-medium text-neutralColor mb-4">
          {t('dashboard.reportGeneralInfo.socialMediaAccounts')}
        </h2>
        <p className="text-neutralColor60 text-xl font-medium">
          {accounts.length}
        </p>
      </div>

      <div className="flex flex-col gap-6">
        {accounts.map((account) => (
          <InfoDisplayItem
            topText={account.userName || 'Nameless'}
            bottomText={account.profileLink || 'No URL'}
            imgUrl={account.profileImgUrl || 'useEmptyState'}
            profileUrl={account.profileLink}
            imgAlt={account.userName}
            socialMedia={account.name}
            key={account.id}
            customOnClick={() => {
              navigate(AppRoutes.socialMediaPostList, {
                state: {
                  id: socialCheckId,
                  plataform: account.name,
                  socialMediaId: account.id,
                },
              })
            }}
          />
        ))}
      </div>
    </Card>
  )
}

export default SocialMediaAccounts
