import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { AppRoutes } from '@/constants/appRoutes'
import { setReportSetupToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'

interface SearchTypeCardTypes {
  title?: string
  subTitle?: string
  text?: string
  creditBalance?: number
  isSelected: boolean
  onSelect: () => void
  disabled?: boolean
  isAssistedReport?: boolean
}

export const SearchTypeCard = ({
  title,
  text,
  creditBalance = 0,
  isSelected,
  onSelect,
  disabled,
  isAssistedReport,
}: SearchTypeCardTypes) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleOnClick = () => {
    onSelect()
    dispatch(setReportSetupToken({ reportSetupGuardToken: true }))
  }

  return (
    <Card
      className={`h-36 max-sm:h-48 flex flex-col content-between lg:w-1/2 p-3 gap-3  ${
        disabled && !isSelected
          ? ''
          : ' hover:bg-primaryColor5 hover:border-primaryColor30 bg-baseColorHighlight cursor-pointer'
      }
      ${isSelected && !disabled && 'border-primaryColor30 bg-primaryColor5'}
      `}
      onClick={!disabled && creditBalance !== 0 ? handleOnClick : () => {}}
    >
      <div className="flex justify-between items-center">
        <p
          className={`${
            creditBalance && creditBalance > 0
              ? 'text-neutralColor'
              : 'text-neutralColor30'
          } text-base font-medium`}
        >
          {title}
        </p>
        {creditBalance && creditBalance > 0 ? (
          <div>
            <RadioGroup disabled={disabled}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  value="option-one"
                  id="option-one"
                  checked={isSelected}
                />
                <Label
                  className={isSelected ? 'text-dynamicBlue' : ''}
                  htmlFor="option-one"
                >
                  {isSelected
                    ? t('dashboard.newSearch.selectSearchType.selected')
                    : t('dashboard.newSearch.selectSearchType.select')}
                </Label>
              </div>
            </RadioGroup>
          </div>
        ) : (
          <Button
            variant="outline"
            onClick={() => {
              navigate(AppRoutes.contactUs)
            }}
            className="h-6 px-2 py-1 rounded"
          >
            <p className="text-xs font-medium px-2 ">
              {t('dashboard.newSearch.selectSearchType.contactSales')}
            </p>
          </Button>
        )}
      </div>
      <div className="sm:h-11 md:h-12">
        <p
          className={`${
            creditBalance && creditBalance > 0
              ? 'text-neutralColor60'
              : 'text-neutralColor30'
          } text-base font-normal`}
        >
          {text}
        </p>
      </div>

      <div className="flex items-center justify-between">
        <div
          className={`flex items-center gap-2 rounded-2xl border p-2 h-[24px] ${
            creditBalance === 0
              ? 'bg-transparent border-neutralColor30'
              : 'bg-primaryColor5 border-primaryColor30'
          }`}
        >
          <p
            className={`${
              creditBalance === 0 ? 'text-neutralColor30' : 'text-primaryColor'
            } text-xs font-medium `}
          >
            {t('dashboard.newSearch.selectSearchType.creditsBalance')}
          </p>
          <p
            className={`${
              creditBalance === 0 ? 'text-neutralColor30' : 'text-primaryColor'
            } text-xs font-medium `}
          >
            {creditBalance}
          </p>
        </div>
        <div className="flex items-end">
          <div className="flex items-center gap-2 bg-primaryColor5 border-primaryColor30 rounded-2xl border p-2 h-[24px]">
            <p className="text-xs font-medium text-primaryColor">
              {isAssistedReport
                ? t('dashboard.newSearch.selectSearchType.businessDays')
                : t('dashboard.newSearch.selectSearchType.3BusinessDays')}
            </p>
          </div>
        </div>
      </div>
    </Card>
  )
}
