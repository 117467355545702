import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  getWorkSpaceReportsRequest,
  getWorkSpaceReportsResponse,
  AssignCreditsToWorkspaceRequest,
  inviteMemberToATeamRequest,
  UpdateTeamData,
  WorkspaceDetailsRequest,
  WorkspaceDetailsResponse,
  WorkSpaceResponse,
  UpdateWorkspaceData,
  WorkspaceCreditsResponse,
  RemoveMemberFromTeamRequest,
} from './workspace.types'

export const workspaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    workspaceDetails: builder.query<
      WorkspaceDetailsResponse,
      WorkspaceDetailsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces/${request.workspaceId}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.info
      },
      providesTags: [RTKTags.workspaceDetails],
    }),

    getWorkSpaceReports: builder.query<
      getWorkSpaceReportsResponse,
      getWorkSpaceReportsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.id}/teams/${request.teamId}/subjects`,
        method: 'GET',
        params: {
          page: request.page,
          pageSize: request.pageSize,
          reportType: request.reportType,
          status: request.status,
          onlyUser: request.onlyUser,
        },
      }),
      providesTags: [RTKTags.workspaceDetails],
      keepUnusedDataFor: 0,
    }),

    getWorkSpaces: builder.query<WorkSpaceResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces`,
        method: 'GET',
      }),
      providesTags: [RTKTags.getWorkSpaces],
    }),

    assignCreditsToWorkspace: builder.mutation<
      void,
      AssignCreditsToWorkspaceRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.workspaceId}/credits`,
        method: 'PUT',
        body: request.values,
      }),
      invalidatesTags: [
        RTKTags.workspaceDetails,
        RTKTags.getWorkSpaces,
        RTKTags.userInfo,
        RTKTags.workspaceCredits,
      ],
    }),

    updateTeamData: builder.mutation<void, UpdateTeamData>({
      query: (request: UpdateTeamData) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.id}/teams/${request.teamId}`,
        method: 'PUT',
        body: {
          name: request.name,
        },
      }),
      invalidatesTags: [RTKTags.workspaceDetails],
    }),

    removeMemberFromTeam: builder.mutation<void, RemoveMemberFromTeamRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces/${request.id}/teams/${request.teamId}/members/${request.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.workspaceDetails],
    }),

    updateWorkSpaceData: builder.mutation<void, UpdateWorkspaceData>({
      query: (request: UpdateTeamData) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.id}`,
        method: 'PUT',
        body: {
          name: request.name,
        },
      }),
      invalidatesTags: [RTKTags.workspaceDetails],
    }),

    inviteMemberToATeam: builder.mutation<void, inviteMemberToATeamRequest>({
      query: (request) => {
        const { id, ...rest } = request
        return {
          url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.id}/members`,
          method: 'POST',
          body: {
            members: [rest],
          },
        }
      },
      invalidatesTags: [RTKTags.workspaceDetails],
    }),

    workspaceCredits: builder.query<
      WorkspaceCreditsResponse,
      WorkspaceDetailsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/workspaces/${request.workspaceId}/credits`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.info
      },
      providesTags: [RTKTags.workspaceCredits],
    }),
  }),
})

export const {
  useWorkspaceDetailsQuery,
  useGetWorkSpacesQuery,
  useAssignCreditsToWorkspaceMutation,
  useUpdateTeamDataMutation,
  useRemoveMemberFromTeamMutation,
  useInviteMemberToATeamMutation,
  useGetWorkSpaceReportsQuery,
  useUpdateWorkSpaceDataMutation,
  useWorkspaceCreditsQuery,
} = workspaceApi
