import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import emptyProfilePhoto from '@/assets/reportInfo/emptyProfilePhoto.png'
import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { useRudderStack } from '@/hooks/useRudderStack'
import useScrollRestoration from '@/hooks/useScrollRestoration'
import { ESocialMedia } from '@/store/api/NewSearch/NewSearch.types'
import { useGetSubjectBasicInformationQuery } from '@/store/api/Person/Person.query'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { Chip } from '../Home/components/Chip/Chip'

import { Actions } from './Components/Actions'
import { ContentSummarySection } from './Components/ContentSummarySection'
import { CoreFindingsSection } from './Components/CoreFindingsSection'
import { DigitalFootPrintCard } from './Components/DigitalFootPrintCard'
import { Header } from './Components/Header'
import { HorizontalBarChart } from './Components/HorizontalBarChart'
import { InfoDisplayItem } from './Components/InfoDisplayCard'
import { RecordCard } from './Components/RecordCard'
import SocialMediaAccounts from './Components/SocialMediaAccountsWidget'

export const ReportGeneralInfo = () => {
  const { state } = useLocation()
  const reportId = state
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetSubjectBasicInformationQuery({
    id: reportId,
  })
  const userInfo = useSelector(selectUserInfo)
  const { trackEvent } = useRudderStack()
  const navigate = useNavigate()

  useScrollRestoration()

  // const postWithTopicFound = useMemo(() => {
  //   if (!data) return '0'
  //   const searchedTopics = data.info.inputs.topics
  //   const result = data.info.risks.filter((risk) =>
  //     searchedTopics.includes(risk.name),
  //   ).length
  //
  //   return `${result}`
  // }, [data])

  useEffect(() => {
    trackEvent(Events.REPORTS_VIEW, userInfo.userId, {
      email: userInfo.email,
    })
  }, [])

  const totalRisks =
    data?.info?.risks?.reduce((total, risk) => total + risk.count, 0) || '0'

  return (
    <WeScreen
      isError={isError}
      isLoading={isLoading}
      useNextButton={false}
      childrenCustomGap={32}
      childrenCustomPadding={24}
      onClickBack={() => navigate(AppRoutes.reports)}
    >
      <Header
        fullName={data?.info.fullName ? data?.info.fullName : 'N/A'}
        followers={data?.info.followers}
        following={data?.info.following}
        posts={data?.info.posts}
        profileImage={
          data?.info.profileImgUrl
            ? data?.info.profileImgUrl
            : emptyProfilePhoto
        }
        children={
          <Actions
            id={reportId}
            pdf={data?.info.pdf}
            complianceSigned={data?.info.complianceSigned}
            summarySigned={data?.info.summarySigned}
            disclosureSigned={data?.info.disclosureSigned}
            dynamicSigned={data?.info.dynamicSigned}
            otherAuthorizationSigned={data?.info.otherAuthorizationSigned}
          />
        }
      />

      {/* -=-=-= Skills Widget -=-=-=*/}
      {data?.info.skills.length !== 0 && (
        <div className="flex gap-2 h-min">
          <p className="flex text-neutralColor text-xl font-medium">
            {t('dashboard.reportGeneralInfo.skills')}
          </p>
          <div className="flex gap-3 flex-wrap items-start mt-1">
            {data?.info.skills
              .slice(0, 16)
              .map((skill, index) => (
                <Chip text={skill.name} key={index} isTag transparent />
              ))}
          </div>
        </div>
      )}

      {/* -=-=-= Content Summary Widget -=-=-=*/}
      <ContentSummarySection
        positivePosts={data?.info.positivePosts || 0}
        negativePosts={data?.info.negativePosts || 0}
        flaggedPosts={data?.info.flaggedPosts || 0}
        webSearchPosts={
          data?.info.channelsValue.find(
            (element) => element.channel === ESocialMedia.WEBSEARCH,
          )?.posts || 0
        }
        id={reportId}
      />

      <div className="w-full flex gap-x-6 max-lg:flex-col max-lg:gap-y-6">
        {/* -=-=-= LEFT COLUMN -=-=-=*/}
        <div className="flex flex-col w-1/2 gap-y-6 max-lg:w-full">
          {/* -=-=-= Social Media Widget -=-=-=*/}
          {data?.info.socialMedia.length !== 0 && (
            <SocialMediaAccounts
              accounts={data?.info.socialMedia || []}
              socialCheckId={reportId}
            />
          )}

          {/* -=-=-= Digital Footprint Widget -=-=-=*/}
          {data?.info.digitalFootprints.length !== 0 && (
            <Card className="p-4 flex flex-col gap-6 max-h-[335px] overflow-y-scroll custom-scrollbar rounded-2xl">
              <div className="flex flex-row items-center justify-between">
                <p className="text-neutralColor text-xl font-medium">
                  {t('dashboard.reportGeneralInfo.digitalFoot')}
                </p>
                <p className="text-neutralColor60 text-xl font-medium">
                  {data?.info.digitalFootprints.length}
                </p>
              </div>
              <div className="flex flex-col gap-4">
                {data?.info.digitalFootprints.map((footprint) => (
                  <DigitalFootPrintCard
                    image={footprint.image}
                    url={footprint.url}
                    socialMedia={footprint.platform}
                    text={footprint.platform ?? 'No Platform'}
                    chipText={
                      footprint.status === 'auto-checked' ||
                      footprint.status === 'checked'
                        ? t('dashboard.reportGeneralInfo.verified')
                        : t('dashboard.reportGeneralInfo.unverified')
                    }
                  />
                ))}
              </div>
            </Card>
          )}

          {/* -=-=-= Core Findings Widget -=-=-=*/}
          <CoreFindingsSection Summary={data?.info.summary} />
        </div>

        {/* -=-=-= RIGHT COLUMN -=-=-=*/}
        <div className="flex flex-col w-1/2 gap-y-6 max-lg:w-full ">
          {/* -=-=-= AvailableRecords Widget -=-=-=*/}
          {(data?.info.workEmails.length !== 0 ||
            data?.info.personalEmails.length !== 0 ||
            data?.info.phones.length !== 0 ||
            data?.info.address.length !== 0) && (
            <Card className="flex flex-col gap-5 p-4 rounded-2xl">
              <p className="text-neutralColor text-xl font-medium">
                {t('dashboard.reportGeneralInfo.available')}
              </p>
              <div className="flex flex-col gap-3">
                {data?.info.workEmails.length !== 0 &&
                  data?.info.workEmails.map((workEmail) => (
                    <RecordCard
                      title={t('dashboard.reportGeneralInfo.work')}
                      content={workEmail}
                    />
                  ))}
                {data?.info.personalEmails.length !== 0 &&
                  data?.info.personalEmails.map((personalEmail) => (
                    <RecordCard
                      title={t('dashboard.reportGeneralInfo.personal')}
                      content={personalEmail}
                    />
                  ))}
                {data?.info.phones.length !== 0 && (
                  <Separator className="border-t-[0.5px] border-neutralColor30 my-1" />
                )}
                {data?.info.phones.length !== 0 &&
                  data?.info.phones.map((phone) => (
                    <RecordCard
                      title={t('dashboard.reportGeneralInfo.phone')}
                      content={phone}
                    />
                  ))}
                {data?.info.address.length !== 0 && (
                  <Separator className="border-t-[0.5px] border-neutralColor30 my-1" />
                )}

                {data?.info.address.length !== 0 &&
                  data?.info.address.map((adress) => (
                    <RecordCard
                      title={t('dashboard.reportGeneralInfo.address')}
                      content={adress}
                    />
                  ))}
              </div>
            </Card>
          )}

          {/* -=-=-= Career Widget -=-=-=*/}
          {data?.info.career.length !== 0 && (
            <Card className="p-4 flex flex-col gap-5 max-h-[335px] overflow-y-scroll custom-scrollbar rounded-2xl">
              <div className="flex flex-row items-center justify-between">
                <p className="text-neutralColor text-xl font-medium">
                  {t('dashboard.reportGeneralInfo.career')}
                </p>
                <p className="text-neutralColor60 text-xl font-medium">
                  {data?.info.career.length}
                </p>
              </div>
              {data?.info.career.map((item, index) => (
                <InfoDisplayItem
                  topText={item.title}
                  bottomText={`${item.company} - Full Time ${
                    item.startDate.month ? `- ${item.startDate.month}` : ''
                  }${item.startDate.year ? `/${item.startDate.year}` : ''} 
                  ${
                    item.endDate.month
                      ? ` - ${item.endDate.month}`
                      : item.startDate.month
                        ? `- Present`
                        : ''
                  }${item.endDate.year ? `/${item.endDate.year}` : ''}`}
                  imgUrl={item.companyLogoUrl || 'useEmptyState'}
                  imgAlt={item.company}
                  profileUrl={item.companyLinkedinURL}
                  key={index}
                />
              ))}
            </Card>
          )}

          {/* -=-=-= Education Widget -=-=-=*/}
          {data?.info.education.length !== 0 && (
            <Card className="p-4 flex flex-col gap-5 max-h-[335px] overflow-y-scroll custom-scrollbar rounded-2xl">
              <div className="flex flex-row items-center justify-between">
                <p className="text-neutralColor text-xl font-medium">
                  {t('dashboard.reportGeneralInfo.education')}
                </p>
                <p className="text-neutralColor60 text-xl font-medium">
                  {data?.info.education.length}
                </p>
              </div>
              {data?.info.education.map((item, index) => (
                <InfoDisplayItem
                  topText={
                    item.title || t('dashboard.reportGeneralInfo.noTitle')
                  }
                  bottomText={`${item.school}
                  ${item.endDate.year ? ` - ${item.endDate.year}` : ''}`}
                  imgAlt={item.school}
                  profileUrl={item.schoolUrl}
                  key={index}
                />
              ))}
            </Card>
          )}

          {/* -=-=-= Core Behavior Detection Widget -=-=-=*/}
          {totalRisks !== '0' && (
            <Card className="flex flex-col p-4 gap-5">
              <div className="flex justify-between items-center">
                <p className="text-neutralColor text-xl font-medium">
                  {t('dashboard.reportGeneralInfo.core')}
                </p>

                <p className="text-neutralColor60 text-xl font-medium">
                  {totalRisks}
                </p>
              </div>
              <div className="flex flex-wrap gap-3">
                {data?.info?.risks.map((risk, index) => (
                  <div
                    className="flex items-center gap-2 px-3 py-2 bg-primaryColor5 w-min rounded-[22px] border border-neutralColor30"
                    key={index}
                  >
                    <p className="text-neutralColor text-nowrap">{risk.name}</p>
                    <div className="rounded-[22px] bg-primaryColor30 h-4 px-1 py-0.5 flex items-center">
                      <p className="text-neutralColor text-xs font-medium ">
                        {risk.count}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          )}

          {/* -=-=-= FocusTopics Widget -=-=-=*/}
          {data?.info.inputs.topics.length !== 0 && (
            <Card className="p-4 flex flex-col gap-5 ">
              <p className="text-neutralColor text-xl font-medium">
                {t('dashboard.reportGeneralInfo.focused')}
              </p>
              <div className="flex gap-2">
                {data?.info.inputs.topics.map((topic) => (
                  <div className="w-min h-8 px-3 py-2 bg-primaryColor5 rounded-[22px] border border-primaryColor30">
                    <p className="text-neutralColor text-xs font-medium text-nowrap">
                      {topic}
                    </p>
                  </div>
                ))}
              </div>
            </Card>
          )}

          {/* -=-=-= Channel Volume Widget -=-=-=*/}
          <Card className="flex flex-col p-4 gap-6">
            <div className="flex flex-col gap-2">
              <p className="text-neutralColor text-xl font-medium">
                {t('dashboard.reportGeneralInfo.channelVolume')}
              </p>
              <p className="text-neutralColor80 text-sm font-medium tracking-tight">
                {t('dashboard.reportGeneralInfo.addDetails')}
              </p>
            </div>
            <HorizontalBarChart chartData={data?.info.channelsValue} />
          </Card>
        </div>
      </div>
    </WeScreen>
  )
}
