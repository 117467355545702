import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  EUserPermission,
  EUserRole,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import { useGetStatsQuery } from '@/store/api/NewSearch/NewSearch.query'
import { selectWorkspaceCreditsFromStore } from '@/store/api/Users/Users.selector'
import type { Credits } from '@/store/api/Users/Users.types'

import { DueDiligenceCard } from '../DueDiligenceCard/DueDiligenceCard'

export const StatsSection = ({ creditsData }: { creditsData?: Credits }) => {
  const { t } = useTranslation()

  const { data: statsData, isLoading: isLoadingStatsData } = useGetStatsQuery()

  const workspaceCredits = useSelector(selectWorkspaceCreditsFromStore)

  const { hasPermission } = usePermissions()

  return (
    <div className="flex flex-col gap-12 h-full mt-2">
      {hasPermission(
        EUserPermission.HANDLE_REPORTS,
        EUserRole.TEAM_MANAGER,
      ) && (
        <div className="flex flex-col gap-4">
          <p className="text-neutralColor text-xl font-medium">
            {t('dashboard.home.dueDiligenceOverview')}
          </p>

          <div className="flex flex-col lg:flex-row gap-6">
            <DueDiligenceCard
              title={t('dashboard.home.completedReportsCard')}
              number={statsData?.info.completedReports}
              isLoading={isLoadingStatsData}
              subTitle={t('dashboard.home.last30Days')}
            />
            <DueDiligenceCard
              title={t('dashboard.home.pendingReportsCard')}
              number={statsData?.info.pendingReports}
              isLoading={isLoadingStatsData}
              subTitle={t('dashboard.home.last30Days')}
            />
            <DueDiligenceCard
              title={t('dashboard.home.facialMatchingCard')}
              number={statsData?.info.faceSearchReports}
              isLoading={isLoadingStatsData}
              subTitle={t('dashboard.home.last30Days')}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div>
          <p className="text-neutralColor text-xl font-medium">
            {t('dashboard.home.balance')}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-5">
          <DueDiligenceCard
            title={t('dashboard.home.aIAnalystReport')}
            number={workspaceCredits?.aiAnalystReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.aIReport')}
            number={workspaceCredits?.aiReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.adverseMedia')}
            number={workspaceCredits?.adverseMediaReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.facialMatchingTitle')}
            number={workspaceCredits?.facialMatchingCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
          <DueDiligenceCard
            title={t('dashboard.home.weCheckAssisted')}
            number={workspaceCredits?.assistedReportCredits}
            subTitle={t('dashboard.home.creditsRemaining')}
          />
        </div>
      </div>
    </div>
  )
}
