import { More } from 'iconsax-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { ConsentFilesSheet } from '@/pages/Reports/Components/ConsentFilesSheet'
import { useMakeBackgroundCheckOpenedMutation } from '@/store/api/Person/Person.query'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface ActionsTypes {
  pdf?: string
  complianceSigned?: string
  summarySigned?: string
  disclosureSigned?: string
  dynamicSigned?: string
  otherAuthorizationSigned?: string
  id: string
}

export const Actions = ({
  pdf,
  complianceSigned,
  summarySigned,
  disclosureSigned,
  dynamicSigned,
  otherAuthorizationSigned,
  id,
}: ActionsTypes) => {
  const { t } = useTranslation()
  const [makeBackgroundCheckOpened] = useMakeBackgroundCheckOpenedMutation()
  const onPressDowload = async () => {
    if (pdf) {
      window.open(pdf, '_blank')
    }
    try {
      await makeBackgroundCheckOpened({ id: id }).unwrap()
    } catch (e) {
      catchWithSentry(e)
      console.error(e)
    }
  }
  return (
    <Select>
      {
        <SelectTrigger
          className={`w-10 h-10 border-none shadow-none p-1 bg-transparent  ${
            !complianceSigned && !pdf && 'hidden'
          }`}
          hiddenArrow
        >
          <More color={weCheckPalette.neutralColor} size={30} />
        </SelectTrigger>
      }

      <SelectContent className="w-[224px]">
        <SelectGroup>
          <SelectLabel>Actions</SelectLabel>
          <SelectSeparator />
          {pdf && (
            <Button
              variant={'outline'}
              className="border-none shadow-none p-0 w-full"
              onClick={onPressDowload}
            >
              <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
                {t('dashboard.reportGeneralInfo.download')}
              </p>
            </Button>
          )}
          {complianceSigned && (
            <ConsentFilesSheet
              complianceSigned={complianceSigned}
              summarySigned={summarySigned}
              disclosureSigned={disclosureSigned}
              dynamicSigned={dynamicSigned}
              otherAuthorizationSigned={otherAuthorizationSigned}
              variant={'ghost'}
              isActionSelector
            />
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
