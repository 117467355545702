import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppSelector } from '@/hooks/redux'
import { useCreateWorkSpaceMutation } from '@/store/api/CreateWorkSpace/CreateWorkSpace.query'
import {
  selectCreateWorkSpaceFirstStep,
  selectCreateWorkSpaceSecondStep,
} from '@/store/api/CreateWorkSpace/CreateWorkSpace.selector'
import type { workSpaceThirdStepSchemaType } from '@/utils/schemas'
import { workSpaceThirdStepSchema } from '@/utils/schemas'

import { Stepper } from '../Search/Components/Stepper'

import { WorkSpaceCreationThirdStepInputs } from './components/WorkSpaceCreationThirdStepInputs'

export const WorkSpaceCreationThirdStep = () => {
  const { t } = useTranslation()
  const steps = [
    t('dashboard.company.createTeam.nameYourWorkspace'),
    t('dashboard.company.createTeam.teamSetup'),
    t('dashboard.company.createTeam.inviteMembers'),
  ]
  const navigate = useNavigate()

  const form = useForm<workSpaceThirdStepSchemaType>({
    resolver: zodResolver(workSpaceThirdStepSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  })
  const { handleSubmit, control, formState } = form
  const { errors } = formState
  const activeStep = 2
  const [createWorkSpace, { isLoading }] = useCreateWorkSpaceMutation()
  const createCreateWorkSpaceFirstStep = useAppSelector(
    selectCreateWorkSpaceFirstStep,
  )
  const createWorkSpaceSecondStepData = useAppSelector(
    selectCreateWorkSpaceSecondStep,
  )

  const [inputs, setInputs] = useState<
    { email: string; role: string; teams: string[] }[]
  >([{ email: '', role: '', teams: [] }])

  const maxInputs = 5
  const isTeamNameValid =
    form.watch('email')?.trim().length > 0 && !errors.email
  const isAnyInputValid = inputs.some((input) => input.email.trim().length > 0)
  const disabledContinueButton = !isTeamNameValid && !isAnyInputValid

  const handleAddInput = () => {
    if (inputs.length < maxInputs) {
      setInputs([...inputs, { email: '', role: '', teams: [] }])
    }
  }
  const handleInputChange = (index: number, value: string) => {
    const newInputs = [...inputs]
    newInputs[index].email = value // Cambiar solo el email
    setInputs(newInputs)
  }

  const handleRemoveInput = (index: number) => {
    const newInputs = inputs.filter((_, i) => i !== index)

    setInputs(newInputs)
  }

  const handleRoleChange = (index: number, role: string) => {
    const newInputs = [...inputs]
    newInputs[index].role = role
    setInputs(newInputs)
  }

  const handleTeamChange = (index: number, teams: string[]) => {
    const newInputs = [...inputs]
    newInputs[index].teams = teams
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const allEmailsFilled = inputs.every(
      (input) => input.email.trim().length > 0,
    )
    const allRolesSelected = inputs.every(
      (input) => input.role.trim().length > 0,
    )
    const allTeamsSelected = inputs.every((input) => input.teams.length > 0)

    if (!allEmailsFilled) {
      toast.error('Enter a valid email address to invite this member..')
      return
    }
    if (!allRolesSelected) {
      toast.error('Please assign a role to this member to proceed..')
      return
    }
    if (!allTeamsSelected) {
      toast.error('Select at least one team to include this member..')
      return
    }

    const teams = createWorkSpaceSecondStepData.createTeamArray.map((team) => {
      const members = inputs
        .filter((input) => input.teams.includes(team))
        .map((input) => ({
          email: input.email,
          role: input.role,
        }))

      return {
        name: team,
        members: members,
      }
    })

    try {
      await createWorkSpace({
        name: createCreateWorkSpaceFirstStep.workSpaceName,
        teams: teams,
      }).unwrap()

      toast.success(t('dashboard.company.createTeam.workspaceSucces'))
      navigate(AppRoutes.enterprise)
    } catch (error: any) {
      if (error.data?.code === 'USER_NOT_AVAILABLE') {
        const emailMatch = error.data.message.match(/email ([^\s]+)/)
        const email = emailMatch ? emailMatch[1] : ''
        toast.error(
          t('dashboard.company.workSpaceCreation.userNotAvailable', { email }),
        )
      } else {
        toast.error(t('dashboard.company.createTeam.workspaceError'))
      }
      console.error('Error creating workspace:', error)
    }
  }

  const onSkip = async () => {
    const teams = createWorkSpaceSecondStepData.createTeamArray.map((team) => {
      const members = inputs
        .filter((input) => input.teams.includes(team))
        .map((input) => ({
          email: input.email,
          role: input.role,
        }))

      return {
        name: team,
        members: members,
      }
    })

    try {
      await createWorkSpace({
        name: createCreateWorkSpaceFirstStep.workSpaceName,
        teams: teams,
      }).unwrap()

      // Navegar después de que la request sea correcta
      toast.success(t('dashboard.company.createTeam.workspaceSucces'))
      navigate(AppRoutes.enterprise)
    } catch (error) {
      toast.error(t('dashboard.company.createTeam.workspaceError'))
      console.error('Error creating workspace:', error)
    }
  }

  return (
    <WeScreen
      isLoading={isLoading}
      isDisabledNextButton={disabledContinueButton}
      useSkipButton
      onClickNextButton={onSubmit}
      onClickSkipButton={onSkip}
    >
      <div className="flex flex-col items-center gap-3 xl:gap-6 h-full">
        <div className="flex">
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="w-full flex flex-col justify-center xl:mt-44 p-4">
          <Form {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
              <div className="flex flex-col gap-3">
                <p className="text-center text-xl font-medium text-neutralColor">
                  {t('dashboard.company.createTeam.inviteMembersTitle')}
                </p>
                <p className="text-center font-normal text-base text-neutralColor80">
                  {t('dashboard.company.createTeam.inviteMembersText')}
                </p>
                {inputs.map((input, index) => (
                  <WorkSpaceCreationThirdStepInputs
                    key={index}
                    control={control}
                    name={`email_${index}`}
                    value={input.email}
                    showDeleteIcon={index !== 0}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onRemove={() => handleRemoveInput(index)}
                    onRoleChange={(role) => handleRoleChange(index, role)} // Pasar el índice
                    onTeamsChange={(teams) => handleTeamChange(index, teams)} // Pasar el índice
                  />
                ))}
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault()
                    handleAddInput()
                  }}
                  className="w-1/2 lg:w-1/6"
                  disabled={inputs.length >= maxInputs}
                >
                  {t('dashboard.company.createTeam.addAnother')}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </WeScreen>
  )
}
