import { Sort } from 'iconsax-react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import type { FilterByType } from '@/store/api/Person/Person.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export type FlaggedPopoverFilter = FilterByType | undefined

export const FlaggedPopover = ({
  sortedBy,
  onChangeSort,
}: {
  sortedBy: FlaggedPopoverFilter
  onChangeSort: (sortBy: FlaggedPopoverFilter) => void
}) => {
  const { t } = useTranslation()

  const handleChangeSort = (sort: FlaggedPopoverFilter) => {
    if (sort !== sortedBy) {
      onChangeSort(sort)
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          size={'icon'}
          asChild
          className="w-10 h-10 bg-transparent"
        >
          <div>
            <Sort color={weCheckPalette.neutralColor} />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent style={{ width: 150 }}>
        <div className="tracking-widest border-b border-backgroundHighlight pb-2 px-1">
          {t('dashboard.postList.sortBy')}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleChangeSort(undefined)}
          className={`py-2 px-1 text-sm tracking-wider ${
            !sortedBy && 'bg-backgroundHighlight font-semibold'
          }`}
        >
          {t('dashboard.postList.all')}
        </div>
        {(['Flagged', 'Negative', 'Positive', 'Neutral'] as FilterByType[]).map(
          (filter) => (
            <div
              key={filter}
              role="button"
              tabIndex={0}
              onClick={() => handleChangeSort(filter)}
              className={`py-2 px-1 text-sm tracking-wider ${
                sortedBy === filter && 'bg-backgroundHighlight font-semibold'
              }`}
            >
              {t(`${filter}`)}
            </div>
          ),
        )}
      </PopoverContent>
    </Popover>
  )
}
