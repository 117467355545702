import { selectPermissions, selectRole } from '@/store/api/Users/Users.selector'

import { useAppSelector } from '../redux'

import type { EUserPermission, IAuthorizedRoleParams } from './permissions.type'
import { EUserRole } from './permissions.type'

export const usePermissions = () => {
  const userPermissions = useAppSelector(selectPermissions)
  const userRole = useAppSelector(selectRole)

  const hasPermission = (
    permissionRequired: EUserPermission,
    roleRequired: EUserRole,
  ): boolean => {
    return (
      userPermissions.includes(permissionRequired) &&
      isRoleAuthorized({
        currentRole: userRole as EUserRole,
        requiredRole: roleRequired,
      })
    )
  }

  const hasPermissions = (
    permissionsRequired: EUserPermission[],
    roleRequired: EUserRole,
  ): boolean => {
    return permissionsRequired.every(
      (permission) =>
        userPermissions.includes(permission) &&
        isRoleAuthorized({
          currentRole: userRole as EUserRole,
          requiredRole: roleRequired,
        }),
    )
  }

  const _buildHierarchy = (roles: EUserRole[]): Map<string, number> => {
    const hierarchy: Map<string, number> = new Map()

    roles.forEach((role, index) => {
      hierarchy.set(role, roles.length - index)
    })

    return hierarchy
  }

  const enterpriseRoleHierarchies: Map<string, number> = _buildHierarchy([
    EUserRole.OWNER,
    EUserRole.CO_OWNER,
    EUserRole.SUPERVISOR,
    EUserRole.WORKSPACE_ADMIN,
    EUserRole.MANAGER,
    EUserRole.TEAM_MANAGER,
    EUserRole.TEAM_ANALYST,
    EUserRole.OPERATIONAL_USER,
    EUserRole.READER,
    EUserRole.VIEW_USER,
  ])

  const isRoleAuthorized = ({
    currentRole,
    requiredRole,
  }: IAuthorizedRoleParams): boolean => {
    const hierarchy = enterpriseRoleHierarchies

    if (!requiredRole || !currentRole) {
      return false
    }

    const priority = hierarchy.get(currentRole)
    const requiredPriority = hierarchy.get(requiredRole)

    if (!priority || !requiredPriority || priority < requiredPriority) {
      return false
    }

    return true
  }

  return {
    hasPermission,
    hasPermissions,
    permissions: userPermissions,
  }
}
