import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'

export const InvitationExpiredScreen = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="w-9/12 bg-baseColor p-4 flex flex-col gap-3 rounded-xl">
      <p className="text-[32px] text-neutralColor">
        {t('dashboard.team.invitationExpired')}
      </p>
      <p className="text-base text-neutralColor60">
        {t('dashboard.team.thisInvitationIsNoLongerActive')}
      </p>
      <div className="mt-4 flex items-end justify-end">
        <Button
          variant="default"
          className="py-5"
          onClick={() => {
            navigate(AppRoutes.registerStart)
          }}
        >
          Go Back
        </Button>
      </div>
    </div>
  )
}
