import { createSlice } from '@reduxjs/toolkit'

export interface NewSearchSliceState {
  useCases: {
    selectedUseCaseId: string
    selectedUseCaseName: string
    consentSwitchValue: boolean
    isSwitchDisabled: boolean
    isForCompanySearch: boolean
  }
  consent: {
    consentEmail: string
    file: string
    fileName: string
    subjectLocationValue: string
    subjectLocationName: string
    businessLocationValue: string
    businessLocationName: string
  }
  selectSearchType: {
    searchType: string
  }
  reportSetup: {
    pdfCoreFindings: boolean
    pdfFlaggedBehaviors: boolean
    pdfLanguage: string
    pdfNegativePosts: boolean
    pdfNeutralPosts: boolean
    pdfPositivePosts: boolean
    pdfSentimentAnalysis: boolean
    pdfSocialMediaAccounts: boolean
    pdfWebSearchPosts: boolean
    useDefaultValue: boolean
  }
  firstStep: {
    topics: string[]
    topicsIds: string[]
  }
  subjectInfo: {
    firstName: string
    lastName: string
    email: string
    middleName: string
    alias: string
    phoneNumber: string
    collage: string
    companyName: string
    dob: string
    countryLongName: string
    countryShortName: string
    stateLongName: string
    stateShortName: string
    cityLongName: string
    cityShortName: string
    image: File | string | null
  }
  socialMedia: {
    facebook: string
    instagram: string
    x: string
    linkedIn: string
    tikTok: string
    pinterest: string
    reddit: string
  }
  companyInfo: {
    name: string
    website: string
    industry: string
    countryLongName: string
    countryShortName: string
    stateLongName: string
    stateShortName: string
    cityLongName: string
    cityShortName: string
  }
  searchResultsInfo: {
    firstName: string
    lastName: string
    school: string
    company: string
    linkedInUrl: string
    searchInput: string
  }
  isPrefillForm: boolean
}

export const newSearchInitialState: NewSearchSliceState = {
  useCases: {
    selectedUseCaseId: '',
    selectedUseCaseName: '',
    consentSwitchValue: false,
    isSwitchDisabled: false,
    isForCompanySearch: false,
  },
  consent: {
    consentEmail: '',
    file: '',
    fileName: '',
    subjectLocationValue: '',
    subjectLocationName: '',
    businessLocationValue: '',
    businessLocationName: '',
  },
  selectSearchType: {
    searchType: '',
  },
  firstStep: {
    topics: [],
    topicsIds: [],
  },
  reportSetup: {
    pdfCoreFindings: false,
    pdfFlaggedBehaviors: false,
    pdfLanguage: 'en',
    pdfNegativePosts: false,
    pdfNeutralPosts: false,
    pdfPositivePosts: false,
    pdfSentimentAnalysis: false,
    pdfSocialMediaAccounts: false,
    pdfWebSearchPosts: false,
    useDefaultValue: true,
  },
  subjectInfo: {
    firstName: '',
    lastName: '',
    email: '',
    middleName: '',
    alias: '',
    phoneNumber: '',
    collage: '',
    dob: '',
    countryLongName: '',
    countryShortName: '',
    stateLongName: '',
    stateShortName: '',
    cityLongName: '',
    cityShortName: '',
    companyName: '',
    image: '',
  },
  socialMedia: {
    facebook: '',
    instagram: '',
    x: '',
    linkedIn: '',
    tikTok: '',
    reddit: '',
    pinterest: '',
  },
  companyInfo: {
    name: '',
    website: '',
    industry: '',
    countryLongName: '',
    countryShortName: '',
    stateLongName: '',
    stateShortName: '',
    cityLongName: '',
    cityShortName: '',
  },
  searchResultsInfo: {
    firstName: '',
    lastName: '',
    school: '',
    company: '',
    linkedInUrl: '',
    searchInput: '',
  },
  isPrefillForm: false,
}

const newSearchSlice = createSlice({
  name: 'newSearch',
  initialState: newSearchInitialState,
  reducers: {
    setSearchType: (state, action) => {
      const searchType = action.payload
      state.selectSearchType = searchType
    },
    setUseCases: (state, action) => {
      const useCases = action.payload
      state.useCases = { ...useCases }
    },
    setConsent: (state, action) => {
      const consent = action.payload
      state.consent = { ...consent }
    },
    resetConsent: (state) => {
      state.consent = newSearchInitialState.consent
    },
    setReportSetup: (state, action) => {
      const reportSetup = action.payload
      state.reportSetup = { ...reportSetup }
    },
    changeReportLang: (state, action) => {
      const reportLang = action.payload
      state.reportSetup.pdfLanguage = reportLang
    },
    setFirstStep: (state, action) => {
      const { topics, topicsIds } = action.payload
      state.firstStep.topics = topics
      state.firstStep.topicsIds = topicsIds
    },
    setSubjectInfo: (state, action) => {
      const newSubjectInfo = action.payload
      state.subjectInfo = { ...newSubjectInfo }
    },
    clearSubjectInfo: (state) => {
      state.subjectInfo = { ...newSearchInitialState.subjectInfo }
    },
    setSocialMedia: (state, action) => {
      const newSearchFourStep = action.payload
      state.socialMedia = { ...newSearchFourStep }
    },
    setCompanyInfo: (state, action) => {
      const newCompanyInfo = action.payload
      state.companyInfo = { ...newCompanyInfo }
    },
    clearNewSearch: (state) => {
      state = { ...newSearchInitialState }
      return state
    },
    setSearchResultsInfo: (state, action) => {
      const searchResultsInfo = action.payload
      state.searchResultsInfo = { ...searchResultsInfo }
    },
    setIsPrefillForm: (state, action) => {
      const isPrefillForm = action.payload
      state.isPrefillForm = isPrefillForm
    },
  },
})

export const {
  setUseCases,
  setConsent,
  setSearchType,
  setReportSetup,
  setFirstStep,
  clearNewSearch,
  setSocialMedia,
  resetConsent,
  setSubjectInfo,
  clearSubjectInfo,
  changeReportLang,
  setCompanyInfo,
  setSearchResultsInfo,
  setIsPrefillForm,
} = newSearchSlice.actions

export default newSearchSlice.reducer
