import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { CCPANotice, PrivacyPolicy } from '@/constants/TermsAndConditions'
import { useCheckTokenGuard } from '@/hooks/useCheckTokenGuard'
import { invitePaswordFlowSchema } from '@/utils/schemas'
import type { invitePaswordFlowType } from '@/utils/schemas'

export const InviteScreenFormFirstStep = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false)
  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  useCheckTokenGuard(token)
  const form = useForm<invitePaswordFlowType>({
    resolver: zodResolver(invitePaswordFlowSchema),
    defaultValues: {
      createPassword: '',
      confirmPassword: '',
    },
  })

  const {
    handleSubmit,
    control,

    formState: { errors = {}, dirtyFields },
    watch,
  } = form

  const passNotMatch =
    Object.keys(dirtyFields).includes('confirmPassword') &&
    watch().confirmPassword !== watch().createPassword

  const onSubmit = (data: any) => {
    if (passNotMatch) {
      return
    } else {
      navigate(AppRoutes.inviteSecondStep, {
        state: { token: token as string, password: data.createPassword },
      })
    }
  }

  const handleCheckboxChange = (newChecked: boolean) => {
    setIsChecked(newChecked)
  }

  return (
    <div className="flex flex-col gap-8 w-full max-w-[436px]">
      <div className="flex flex-col gap-5">
        <p className="text-[40px] font-bold tracking-wide">
          {t('dashboard.landingSingUpInvite.createPassword')}
        </p>
        <p className="text-1xl text-textAccent tracking-tight">
          {t('dashboard.landingSingUpInvite.createYourPassword')}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <FormField
            control={control}
            name="createPassword"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md  ">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUpInvite.createPassword',
                    )}
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                <FormMessage data-name="password" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md  ">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t(
                      'dashboard.landingSingUpInvite.confirmPassword',
                    )}
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                {passNotMatch && !errors.confirmPassword && (
                  <p className="text-[0.8rem] font-medium text-destructive m-">
                    {t('dashboard.landingSingUpInvite.passwordsNotMatch')}
                  </p>
                )}
                <FormMessage data-name="password" />
              </FormItem>
            )}
          />

          <div className="flex flex-col gap-5">
            <p className="text-1xl text-textAccent tracking-tight">
              {t('dashboard.landingSingUpInvite.passwordsNotMatchText')}
            </p>
            <div className="flex flex-row gap-3">
              <Checkbox
                className="w-5 h-5 rounded-md"
                onCheckedChange={handleCheckboxChange}
              />
              <p className="text-textAccent text-base font-light ">
                {t('dashboard.landingSingUpInvite.iAgree')}
              </p>
            </div>
          </div>

          <Button
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            disabled={!isChecked}
          >
            {t('dashboard.landingSingUpInvite.continue')}
          </Button>
        </form>
      </Form>
      <div className="border-t pt-6 text-center max-w-[436px]">
        <p className="text-sm font-medium ">
          {t('dashboard.landingSingUpInvite.clickingContinue')}
          &nbsp;
          <span
            className="text-linkColor "
            role="button"
            onClick={() =>
              window.open(
                t('dashboard.common.termsAndConditionsLink'),
                '_blank',
              )
            }
            tabIndex={0}
          >
            {t('dashboard.landingSingUpInvite.termsAndConditions')}
          </span>
          &nbsp;
          <span
            className="text-linkColor"
            role="button"
            onClick={() => window.open(PrivacyPolicy.PRIVACY_POLICY, '_blank')}
            tabIndex={0}
          >
            {t('dashboard.landingSingUpInvite.privacyPolicy')}&nbsp;
          </span>
          {t('dashboard.landingSingUpInvite.and')}&nbsp;
          <span
            className="text-linkColor"
            role="button"
            onClick={() => window.open(CCPANotice.CCPA_NOTICE, '_blank')}
            tabIndex={0}
          >
            {t('dashboard.landingSingUpInvite.cCPANotice')}
          </span>
        </p>
      </div>
    </div>
  )
}
