import { useTranslation } from 'react-i18next'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { EReportFilter } from '@/store/api/workspace/workspace.types'

import type { ExtendedReportFilter } from '../ReportsScreen'

const FILTER_OPTIONS: ExtendedReportFilter[] = [
  undefined,
  'your_reports',
  ...Object.values(EReportFilter),
]

interface ReportTypeSelectorProps {
  selectedFilter: ExtendedReportFilter | 'all'
  onFilterChange: (value: ExtendedReportFilter | 'all') => void
}

export const ReportTypeSelector = ({
  selectedFilter,
  onFilterChange,
}: ReportTypeSelectorProps) => {
  const { t } = useTranslation()

  return (
    <Select
      value={selectedFilter}
      onValueChange={(value) => onFilterChange(value as ExtendedReportFilter)}
    >
      <SelectTrigger className="h-[35px] px-4 py-2 rounded-[200px] w-min flex gap-2">
        <p>{t('dashboard.reportsScreen.selectFilter')}</p>
      </SelectTrigger>
      <SelectContent>
        {FILTER_OPTIONS.map((filter, index) => (
          <SelectItem key={index} value={filter ?? 'all'}>
            {filter
              ? t(`dashboard.reportsScreen.${filter}`)
              : t('dashboard.reportsScreen.all')}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
