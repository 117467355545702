import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import DarkReportInProgress from '@/assets/DarkReportInProgress.svg'
import LightReportInProgress from '@/assets/LightReportInProgress.svg'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppSelector } from '@/hooks/redux'
import { selectIsDarkMode } from '@/store/api/ui/ui.selector'

import { WeScreen } from '../WeScreen/WeScreen'

export const ReportInProgress = () => {
  const { t } = useTranslation()
  const isDarkMode = useAppSelector(selectIsDarkMode)

  const location = useLocation()
  const consentSentEmail = location.state?.consentSentEmail

  const navigate = useNavigate()

  return (
    <WeScreen useHeader={false}>
      <div className="flex flex-col gap-8 pt-6">
        <Card className="bg-baseColorHighlight flex gap-6 flex-col items-center p-6 h-[785px] justify-center">
          <div>
            <img
              src={isDarkMode ? DarkReportInProgress : LightReportInProgress}
              alt="ReportInProgress"
            />
          </div>

          {!consentSentEmail ? (
            <>
              <p className="text-neutralColor text-center text-textNormal text-[32px] font-medium tracking-wide">
                {t('dashboard.reportInProgress.reportInProgress')}
              </p>

              <p className="text-neutralColor80 text-center text-textAccent text-base font-bold tracking-tight">
                {t('dashboard.reportInProgress.reportInProgressText')}
              </p>
            </>
          ) : (
            <>
              <p className="text-neutralColor text-center text-textNormal text-[32px] font-medium tracking-wide">
                {t('dashboard.reportInProgress.reportInProgressConsentTitle')}
              </p>

              <p className="text-neutralColor80 text-center text-textAccent text-base font-bold tracking-tight">
                {t('dashboard.reportInProgress.reportInProgressConsentText')}
              </p>
            </>
          )}

          <Button
            onClick={() => navigate(AppRoutes.reports)}
            className=" h-[46px] text-base font-normal tracking-widest"
          >
            {t('dashboard.reportInProgress.goToReports')}
          </Button>
        </Card>
      </div>
    </WeScreen>
  )
}
