import React, { useState } from 'react'

import { ESocialMedias } from '@/constants/sanitizeSocialMediaConstants'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import { identifySocialMedia } from '@/utils/identifySocialMedia'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import Clip from '../../../assets/socialMedia/Clip.svg?react'
import Facebook from '../../../assets/socialMedia/Facebook.svg?react'
import Instagram from '../../../assets/socialMedia/Instagram.svg?react'
import Linkedin from '../../../assets/socialMedia/LinkedIn.svg?react'
import Pinterest from '../../../assets/socialMedia/Pinterest.svg?react'
import Reddit from '../../../assets/socialMedia/Reddit.svg?react'
import Tiktok from '../../../assets/socialMedia/TikTok.svg?react'
import X from '../../../assets/socialMedia/X.svg?react'

interface DigitalFootPrintCardTypes {
  socialMedia: string
  text: string
  chipText: string
  url: string
  image: string
}

export const DigitalFootPrintCard = ({
  socialMedia,
  text,
  chipText,
  url,
  image,
}: DigitalFootPrintCardTypes) => {
  const SOCIAL_MEDIA_ICONS = {
    [ESocialMedias.LinkedIn]: Linkedin,
    [ESocialMedias.Instagram]: Instagram,
    [ESocialMedias.Twitter]: X,
    [ESocialMedias.Facebook]: Facebook,
    [ESocialMedias.Reddit]: Reddit,
    [ESocialMedias.Pinterest]: Pinterest,
    [ESocialMedias.TikTok]: Tiktok,
  }
  const SocialMediaIcon =
    SOCIAL_MEDIA_ICONS[identifySocialMedia(socialMedia) as ESocialMedias] ||
    Clip

  const onPressCard = async () => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const [imageSrc, setImageSrc] = useState(image || null)

  return (
    <div
      className="flex items-center justify-between h-[72px] p-3 bg-baseColor hover:cursor-pointer hover:bg-primaryColor5 rounded-xl"
      onClick={onPressCard}
    >
      <div className="flex items-center gap-3">
        {imageSrc !== null ? (
          <img
            src={imageSrc}
            alt={text}
            className="w-6 h-6 rounded-md"
            onError={() => setImageSrc(null)}
          />
        ) : (
          <SocialMediaIcon
            className="w-5 h-5"
            color={weCheckPalette.neutralColor}
          />
        )}

        <p className="text-neutralColor text-base font-medium">{text}</p>
      </div>
      <div className="w-min">
        <Chip
          text={chipText}
          yellow={chipText === 'Unverified'}
          green={chipText === 'Verified'}
          isTag
        />
      </div>
    </div>
  )
}
