import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Checkbox } from '@/components/ui/checkbox'
import { Form, FormControl, FormItem } from '@/components/ui/form'
import type {
  WorkSpaceInfo,
  Workspace,
} from '@/store/api/workspace/workspace.types'
import type { inviteWorkspaceSchemaType } from '@/utils/schemas'
import { inviteWorkspaceSchema } from '@/utils/schemas'

export const OfficeCard = ({
  workspace,
  onSelectedValueChange,
  onUnselectedValueChange,
  selectedUser,
}: {
  workspace: WorkSpaceInfo | Workspace
  onSelectedValueChange: (value: any) => void
  onUnselectedValueChange: (value: any) => void
  selectedUser?: { userEmail: string }
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(() => {
    return workspace.teams
      .filter(
        (team) =>
          team.members?.some(
            (member) => member.userEmail === selectedUser?.userEmail,
          ),
      )
      .map((team) => team._id)
  })

  const form = useForm<inviteWorkspaceSchemaType>({
    resolver: zodResolver(inviteWorkspaceSchema),
    defaultValues: {
      email: '',
    },
  })
  const { handleSubmit } = form

  const handleCheckboxChange = (
    team: any,
    checked: boolean | 'indeterminate',
  ) => {
    const teamWithWorkspaceId = { workspaceId: workspace._id, id: team._id }

    if (checked !== 'indeterminate' && checked) {
      // If we check the checkbox, we add the team id to the selected values
      setSelectedValues((prev) => [...prev, team._id])

      onSelectedValueChange(teamWithWorkspaceId)
    } else {
      // If we uncheck the checkbox, we remove the team id from the selected values
      setSelectedValues((prev) => prev.filter((id) => id !== team._id))

      // We also add the team id to the unselected values to remove it from the member list
      onUnselectedValueChange(teamWithWorkspaceId)
    }
  }

  const handleSelectAllChange = () => {
    if (selectedValues.length === workspace.teams.length) {
      setSelectedValues([])
    } else {
      const allTeamsWithWorkspaceId = workspace.teams.map((team) => ({
        workspaceId: workspace._id,
        id: team._id,
      }))
      setSelectedValues(workspace.teams.map((team) => team._id))
      allTeamsWithWorkspaceId.forEach((team) => onSelectedValueChange(team))
    }
  }

  const onSubmit = (data: any) => {
    console.log(data)
  }

  return (
    <div className="flex flex-col gap-2">
      <p className="text-neutralColor  text-sm">{workspace.name}</p>

      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem className="flex flex-row items-center gap-2 text-sm">
            <FormControl>
              <Checkbox
                className="mt-2.5"
                checked={selectedValues.length === workspace.teams.length}
                onCheckedChange={handleSelectAllChange}
              />
            </FormControl>
            <p>Select All</p>{' '}
          </FormItem>
          {workspace.teams.map((team) => (
            <FormItem
              key={team._id}
              className="flex flex-row items-center gap-2 text-sm"
            >
              <FormControl>
                <Checkbox
                  className="mt-2.5"
                  checked={selectedValues.includes(team._id)}
                  onCheckedChange={(checked) =>
                    handleCheckboxChange(team, checked)
                  }
                />
              </FormControl>
              <p>{team.name}</p>
            </FormItem>
          ))}
        </form>
      </Form>
    </div>
  )
}
