import { useTranslation } from 'react-i18next'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { ESocialCheckStatus } from '@/store/api/Enterprise/Enterprise.types'

export type ReportStatusType = ESocialCheckStatus | 'new' | 'all'

interface ReportStatusSelectorProps {
  selectedStatus: ReportStatusType
  onStatusChange: (value: ESocialCheckStatus | undefined) => void
}

export const ReportStatusSelector = ({
  selectedStatus,
  onStatusChange,
}: ReportStatusSelectorProps) => {
  const { t } = useTranslation()

  const handleStatusChange = (value: string) => {
    if (value === 'new') {
      onStatusChange(ESocialCheckStatus.Complete)
      return
    }
    onStatusChange(value as ESocialCheckStatus)
  }

  return (
    <Select value={selectedStatus} onValueChange={handleStatusChange}>
      <SelectTrigger className="h-[35px] px-4 py-2 rounded-[200px] w-min flex gap-2">
        <p className="text-sm font-medium tracking-tight text-neutralColor">
          {t('dashboard.reportsScreen.selectStatus')}
        </p>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">{t('dashboard.reportsScreen.all')}</SelectItem>
        <SelectItem value={ESocialCheckStatus.InProgress}>
          {t('dashboard.reportsScreen.inProgress')}
        </SelectItem>
        <SelectItem value="new">{t('dashboard.reportsScreen.new')}</SelectItem>
        <SelectItem value={ESocialCheckStatus.NotReleased}>
          {t('dashboard.reportsScreen.concentPending')}
        </SelectItem>
        <SelectItem value={ESocialCheckStatus.Cancelled}>
          {t('dashboard.reportsScreen.cancelled')}
        </SelectItem>
      </SelectContent>
    </Select>
  )
}
