import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'

export const InsufficientCreditsAlert = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="flex flex-col gap-5 w-6/12 max-md:w-11/12">
        <AlertDialogHeader className="flex flex-col gap-3">
          <AlertDialogTitle className="text-neutralColor text-2xl font-medium max-md:text-left max-md:text-[30px]">
            {t('dashboard.home.insufficientCredits')}
          </AlertDialogTitle>
          <AlertDialogDescription
            className="text-neutralColor80 text-base font-normal max-md:text-left"
            dangerouslySetInnerHTML={{
              __html: t('dashboard.home.insufficientCreditsText'),
            }}
          />
        </AlertDialogHeader>

        <AlertDialogFooter>
          <Button
            type="submit"
            variant="secondary"
            onClick={() => setOpen(false)}
            className="h-[46px] px-5 py-3"
          >
            <p className="text-neutralColor text-base font-medium">
              {t('dashboard.home.cancel')}
            </p>
          </Button>
          <Button
            type="submit"
            variant="default"
            className="h-[46px] px-5 py-3"
            onClick={() => navigate(AppRoutes.contactUs)}
          >
            <p className="text-neutralColor text-base font-medium">
              {t('dashboard.home.contact')}
            </p>
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
