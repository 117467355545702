export enum EUserRole {
  OWNER = 'owner',
  CO_OWNER = 'co_owner',
  SUPERVISOR = 'supervisor',
  WORKSPACE_ADMIN = 'workspace_admin',
  MANAGER = 'manager',
  TEAM_MANAGER = 'team_manager',
  TEAM_ANALYST = 'team_analyst',
  OPERATIONAL_USER = 'operational_user',
  VIEW_USER = 'view_user',
  READER = 'reader',
}

// Enum with only non deprecated roles
export enum EUserRoleSelector {
  CO_OWNER = 'co_owner',
  SUPERVISOR = 'supervisor',
  WORKSPACE_ADMIN = 'workspace_admin',
  TEAM_MANAGER = 'team_manager',
  TEAM_ANALYST = 'team_analyst',
  READER = 'reader',
}

export const UserRoles = [...Object.values(EUserRole)]

export enum EUserPermission {
  HANDLE_BILLING = 'handle_billing',
  HANDLE_CREDITS = 'handle_credits',
  HANDLE_ENTERPRISES = 'handle_enterprises',
  HANDLE_USERS = 'handle_users',
  VIEW_REPORTS = 'view_reports',
  HANDLE_REPORTS = 'handle_reports',
  HANDLE_TOPICS = 'handle_topics',
  HANDLE_USE_CASES = 'handle_use_cases',
  HANDLE_WORKSPACES = 'handle_workspaces',
  HANDLE_TEAMS = 'handle_teams',
  HANDLE_MEMBERS = 'handle_members',
}

export interface IAuthorizedRoleParams {
  currentRole: EUserRole
  requiredRole: EUserRole
}

export const ETraductionKeysByUserRole = {
  [EUserRole.OWNER]: 'dashboard.roles.owner',
  [EUserRole.CO_OWNER]: 'dashboard.roles.coOwner',
  [EUserRole.SUPERVISOR]: 'dashboard.roles.supervisor',
  [EUserRole.WORKSPACE_ADMIN]: 'dashboard.roles.workspaceAdmin',
  [EUserRole.TEAM_MANAGER]: 'dashboard.roles.teamManager',
  [EUserRole.READER]: 'dashboard.roles.reader',
  [EUserRole.TEAM_ANALYST]: 'dashboard.roles.teamAnalyst',
  // Deprecated Roles
  [EUserRole.MANAGER]: 'dashboard.roles.manager',
  [EUserRole.OPERATIONAL_USER]: 'dashboard.roles.operationalUser',
  [EUserRole.VIEW_USER]: 'dashboard.roles.viewUser',
}
