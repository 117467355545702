import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface CreateWorkSpaceSliceState {
  CreateWorkSpaceFirstStep: {
    workSpaceName: string
  }
  CreateWorkSpaceSecondStep: {
    createTeamArray: string[]
  }
  CreateWorkSpaceThirdStep: {
    members: {
      email: string
      teamWhichBelong: string[]
      role: string
    }[]
  }
}

export const createWorkSpaceInitialState: CreateWorkSpaceSliceState = {
  CreateWorkSpaceFirstStep: {
    workSpaceName: '',
  },
  CreateWorkSpaceSecondStep: {
    createTeamArray: [],
  },
  CreateWorkSpaceThirdStep: {
    members: [],
  },
}

const createWorkSpaceSlice = createSlice({
  name: 'createWorkSpace',
  initialState: createWorkSpaceInitialState,
  reducers: {
    setCreateWorkSpaceFirstStep: (state, action) => {
      const workSpaceName = action.payload.workSpaceName
      state.CreateWorkSpaceFirstStep = { workSpaceName }
    },
    setCreateWorkSpaceSecondStep: (state, action) => {
      const workSpaceSecond = action.payload
      state.CreateWorkSpaceSecondStep = { ...workSpaceSecond }
    },
    setCreateWorkSpaceThirdStep: (
      state,
      action: PayloadAction<{
        email: string
        teamWhichBelong: string[]
        role: string
      }>,
    ) => {
      const newMember = {
        email: action.payload.email,
        teamWhichBelong: action.payload.teamWhichBelong,
        role: action.payload.role,
      }
      state.CreateWorkSpaceThirdStep.members.push(newMember)
    },

    clearSignUpState: (state) => {
      state.CreateWorkSpaceFirstStep =
        createWorkSpaceInitialState.CreateWorkSpaceFirstStep
      state.CreateWorkSpaceSecondStep =
        createWorkSpaceInitialState.CreateWorkSpaceSecondStep
      state.CreateWorkSpaceThirdStep =
        createWorkSpaceInitialState.CreateWorkSpaceThirdStep
    },
  },
})
export const {
  setCreateWorkSpaceFirstStep,
  setCreateWorkSpaceSecondStep,
  setCreateWorkSpaceThirdStep,
  clearSignUpState,
} = createWorkSpaceSlice.actions

export default createWorkSpaceSlice.reducer
