import { ArrowRight } from 'iconsax-react'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { setAdvancedSearchGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  setIsPrefillForm,
  setSocialMedia,
  setSubjectInfo,
} from '@/store/api/NewSearch/NewSearch.slice'
import type { findSubjectItem } from '@/store/api/NewSearch/NewSearch.types'
import { textEllipsis } from '@/utils/strings'

export const ProfileItem = ({ data }: { data: findSubjectItem }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMobileMediaQuery()
  const handleNavigate = () => {
    dispatch(setIsPrefillForm(true))
    dispatch(
      setSubjectInfo({
        firstName: data.firstName,
        lastName: data.lastName,
        alias: data.alias,
        collage: data.collage,
        companyName: data.company,
        countryLongName: data.location.country,
        cityLongName: data.location.city,
        image: data.profilePicture,
      }),
    )
    dispatch(
      setSocialMedia({
        linkedIn: data.profileURL,
      }),
    )
    dispatch(setAdvancedSearchGuardToken({ advancedSearchGuardToken: true }))
    navigate(AppRoutes.advancedSearch)
  }
  const fullname = `${data.firstName} ${data.lastName}`

  const initials = useMemo(() => {
    if (!data.firstName) {
      return ''
    }
    const initials = fullname.split(' ')
    const firstInitial = initials[0] ? initials[0][0].toUpperCase() : ''
    const secondInitial = initials[2] ? initials[2][0].toUpperCase() : ''
    return `${firstInitial} ${secondInitial}`
  }, [data.firstName, data.lastName])

  return (
    <Button
      className="flex justify-between items-center h-auto pl-3 pr-4 py-2 bg-baseColor rounded-2xl"
      onClick={handleNavigate}
    >
      <div className="flex gap-3 items-center">
        <Avatar className="w-[32px] h-[32px]">
          {data.profilePicture && (
            <AvatarImage
              src={data.profilePicture}
              alt={`${data.firstName} ${data.lastName}`}
            />
          )}
          <AvatarFallback className="bg-baseColor">{initials}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <p className="text-neutralColor text-base font-medium text-left">{`${
            isMobile ? textEllipsis(fullname, 17) : fullname
          }`}</p>
          <p className="text-neutralColor60 text-sm font-medium tracking-wide break-words">
            {`${isMobile ? textEllipsis(data.headline, 7) : data.headline} - ${
              isMobile
                ? textEllipsis(data.location.full, 10)
                : data.location.full
            } 
            `}
          </p>
        </div>
      </div>
      <ArrowRight className="w-6 h-6 flex justify-end" />
    </Button>
  )
}
