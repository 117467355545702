import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { useRudderStack } from '@/hooks/useRudderStack'
import {
  FilterByTypeEnum,
  PlatformTypes,
} from '@/store/api/Person/Person.types'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { TotalPostsCard } from './TotalPostsCard'

interface ContentSummarySectionTypes {
  positivePosts: number
  negativePosts: number
  flaggedPosts: number
  webSearchPosts: number
  id: string
}

export const ContentSummarySection = ({
  negativePosts,
  positivePosts,
  flaggedPosts,
  webSearchPosts,
  id,
}: ContentSummarySectionTypes) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userInfo = useSelector(selectUserInfo)
  const { trackEvent } = useRudderStack()

  const handleTrackEvent = (postType: string) => {
    trackEvent(Events.REPORTS_VIEW_POST_CATEGORY, userInfo.userId, {
      email: userInfo.email,
      post_category: postType,
    })
    navigate(AppRoutes.postList, {
      state: {
        id,
        filterBy:
          postType === 'Negative'
            ? FilterByTypeEnum.NEGATIVE
            : postType === 'Positive'
              ? FilterByTypeEnum.POSITIVE
              : FilterByTypeEnum.FLAGGED,
      },
    })
  }

  const handleTrackEventWebNews = (postType: string) => {
    trackEvent(Events.REPORTS_VIEW_POST_CATEGORY, userInfo.userId, {
      email: userInfo.email,
      post_category: postType,
    })
    navigate(AppRoutes.postList, {
      state: {
        id,
        plataform: PlatformTypes.WEBSEARCH,
      },
    })
  }

  return (
    <div className="flex h-[91px] gap-6 max-lg:flex-wrap max-lg:h-auto">
      <TotalPostsCard
        sentiment={t('dashboard.postsInsights.flaggedPosts')}
        onClick={() => handleTrackEvent('Flagged')}
        totalPosts={flaggedPosts}
      />
      <TotalPostsCard
        sentiment={t('dashboard.postsInsights.negativePosts')}
        onClick={() => handleTrackEvent('Negative')}
        totalPosts={negativePosts}
      />
      <TotalPostsCard
        sentiment={t('dashboard.postsInsights.positivePosts')}
        onClick={() => handleTrackEvent('Positive')}
        totalPosts={positivePosts}
      />
      <TotalPostsCard
        sentiment={t('Web/News Posts')}
        onClick={() => handleTrackEventWebNews('Web/News')}
        totalPosts={webSearchPosts}
      />
    </div>
  )
}
