import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'
import { setInitialReportSetupToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'

import { GetStartedCard } from '../GetStartedCard/GetStartedCard'

export const SearchSection = ({
  aiReportCredits,
  aiAnalystReportCredits,
  adverseMediaReportCredits,
  assistedReportCredits,
  facialMatchingCredits,
}: {
  aiReportCredits: number
  aiAnalystReportCredits: number
  adverseMediaReportCredits: number
  assistedReportCredits: number
  facialMatchingCredits: number
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSearchNavigate = () => {
    dispatch(setInitialReportSetupToken({ setUseCasesToken: true }))
    navigate(AppRoutes.newSearchInitialReportSetup, {
      state: { clearForm: true },
    })
  }

  return (
    <div className="flex flex-row gap-8 max-lg:gap-3 max-lg:flex-col">
      <GetStartedCard
        title={t('dashboard.home.aIGeneratedReport')}
        text={t('dashboard.home.aIGeneratedReportText')}
        aiReportCredits={aiReportCredits ?? 0}
        onClick={
          aiReportCredits && aiReportCredits > 0
            ? () => navigate(AppRoutes.aiGeneratedReport)
            : () => navigate(AppRoutes.contactUs)
        }
      />
      <GetStartedCard
        title={t('dashboard.home.socialAnalysis')}
        text={t('dashboard.home.socialAnalysisText')}
        aiReportCredits={aiReportCredits ?? 0}
        aiAnalystReportCredits={aiAnalystReportCredits ?? 0}
        adverseMediaReportCredits={adverseMediaReportCredits ?? 0}
        assistedReportCredits={assistedReportCredits ?? 0}
        onClick={
          aiReportCredits > 0 ||
          aiAnalystReportCredits > 0 ||
          adverseMediaReportCredits > 0 ||
          assistedReportCredits > 0
            ? handleSearchNavigate
            : () => navigate(AppRoutes.contactUs)
        }
        useChip
      />
      <GetStartedCard
        title={t('dashboard.home.facialMatching')}
        text={t('dashboard.home.facialMatchingText')}
        facialMatchingCredits={facialMatchingCredits}
        onClick={
          facialMatchingCredits && facialMatchingCredits > 0
            ? () => navigate(AppRoutes.faceSearch)
            : () => navigate(AppRoutes.contactUs)
        }
      />
    </div>
  )
}
