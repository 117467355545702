import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import AvatarPending from '@/assets/Avatar-pending.svg?react'
import { Avatar } from '@/components/ui/avatar'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import type {
  InviteData,
  UserData,
} from '@/store/api/workspace/workspace.types'

export enum EInviteAction {
  RESEND = 'resend',
  REVOKE = 'revoke',
}

export const EditInviteCard = ({
  user,
  inviteAction = EInviteAction.RESEND,
  onInviteChange,
  invite,
}: {
  user: UserData
  inviteAction: EInviteAction
  onInviteChange: (invite: EInviteAction) => void
  invite: InviteData
}) => {
  const { t } = useTranslation()
  const email = user.userEmail

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row items-center gap-3">
        <Avatar>
          <AvatarPending />
        </Avatar>

        <div className="flex flex-col">
          <p className="text-sm text-neutralColor60">{email}</p>
          <p className="text-sm text-neutralColor60">
            {t('dashboard.company.editInvite.invitationSent')} -{' '}
            {format(new Date(invite.lastInviteUpdate), 'MM/dd/yyyy')}
          </p>
        </div>
      </div>

      <div>
        <Select value={inviteAction} onValueChange={onInviteChange}>
          <SelectTrigger className="flex flex-row gap-3">
            <SelectValue
              placeholder={t('dashboard.company.editInvite.invitationSent')}
            ></SelectValue>
          </SelectTrigger>

          <SelectContent>
            <SelectItem className="flex flex-row " value={EInviteAction.RESEND}>
              <p className="flex flex-row gap-4 items-center text-neutralColor60">
                {t('dashboard.company.editInvite.resendInvite')}
              </p>
            </SelectItem>

            <SelectItem className="flex flex-row " value={EInviteAction.REVOKE}>
              <p className="flex flex-row gap-4 items-center text-dynamicRed">
                {t('dashboard.company.editInvite.revoke')}
              </p>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
