import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'
import { useAppSelector } from '@/hooks/redux'
import { selectNewSearchGuardsTokens } from '@/store/api/NewSearch/NewSearch.selector'

export const useHasCurrentStepToken = (
  step:
    | 'initialReportSetup'
    | 'consent'
    | 'reportSetup'
    | 'firstStep'
    | 'subjectInfoStep'
    | 'companyInfoStep'
    | 'finalStep'
    | 'searchResults'
    | 'advancedSearch'
    | 'generatingReport'
    | 'AIReportFocusedTopics'
    | 'null',
) => {
  const navigate = useNavigate()
  const guardsTokens = useAppSelector(selectNewSearchGuardsTokens)
  const tokenSelector = {
    initialReportSetup: guardsTokens.initialReportSetup.initialReportSetupToken,
    consent: guardsTokens.consent.consentToken,
    reportSetup: guardsTokens.reportSetup.reportSetupGuardToken,
    firstStep: guardsTokens.firstStep.firstStepGuardToken,
    subjectInfoStep: guardsTokens.subjectInfoStep.subjectInfoStepGuardToken,
    companyInfoStep: guardsTokens.companyInfoStep.companyInfoStepGuardToken,
    finalStep: guardsTokens.finalStep.FinalStepGuardToken,
    searchResults: guardsTokens.searchResults.searchResultsGuardToken,
    advancedSearch: guardsTokens.advancedSearch.advancedSearchGuardToken,
    generatingReport: guardsTokens.generatingReport.generatingReportGuardToken,
    AIReportFocusedTopics:
      guardsTokens.AIReportFocusedTopics.AIReportFocusedTopicsGuardToken,
    null: true,
  }[step]

  useEffect(() => {
    if (!tokenSelector) {
      navigate(AppRoutes.dashboard)
    }
  }, [])
}
