import { Progress } from '@/components/ui/progress'
import type { ChannelsValue } from '@/store/api/Person/Person.types'

interface HorizontalBarChartProps {
  chartData?: ChannelsValue[]
}

export const HorizontalBarChart = ({ chartData }: HorizontalBarChartProps) => {
  const backgroundColor = [
    '#357af6',
    '#f09436',
    '#5cc8be',
    '#ea3354',
    '#5856d6',
    '#f7cb45',
    '#af52de',
  ]
  const formatNumber = (num: number): string => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M'
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K'
    }
    return num.toString()
  }

  const totalPosts =
    chartData?.reduce((total, socialMedia) => total + socialMedia.posts, 0) || 0

  return (
    <div className="flex flex-col gap-4">
      {chartData?.map((socialMedia, index) => (
        <div className="flex gap-3 items-center max-md:flex-col" key={index}>
          <div className="w-[100px] flex gap-2 max-md:w-min">
            <p className="text-xs font-bold text-neutralColor ">
              {formatNumber(socialMedia.posts)}
            </p>
            <p className="text-xs font-medium text-neutralColor60 text-nowrap">
              {socialMedia.channel === 'Twitter'
                ? 'X (Twitter)'
                : socialMedia.channel}
            </p>
          </div>
          <Progress
            color={backgroundColor[index]}
            value={totalPosts > 0 ? (socialMedia.posts / totalPosts) * 100 : 0}
            className="h-4"
            animationDuration={5000}
          />
        </div>
      ))}
    </div>
  )
}
