import React from 'react'

import type { WorkspaceTeam } from '@/store/api/workspace/workspace.types'

import { TeamSettingsCard } from './TeamSettingsCard'

interface ITeamCard {
  teams?: WorkspaceTeam[]
  workspaceId?: string
}

export const TeamCard = ({ teams, workspaceId }: ITeamCard) => {
  return (
    <div className="bg-baseColor rounded-lg p-4">
      <p className="text-xl text-neutralColor">Teams</p>
      <div>
        {teams?.map((team, index) => {
          const nameOne =
            team.members[0]?.user?.firstname ?? team.members[0]?.userEmail
          const nameTwo =
            team.members[1]?.user?.firstname ?? team.members[1]?.userEmail

          return (
            <TeamSettingsCard
              workSpaceId={workspaceId}
              title={team.name}
              nameOne={nameOne}
              nameTwo={nameTwo}
              teamId={team._id}
              isBottomLine={index !== teams.length - 1}
              isTeamRename
            />
          )
        })}
      </div>
    </div>
  )
}
