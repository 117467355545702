import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { setFinalStepGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  selectNewSearch,
  selectHasSocialMediaData,
  selectSubjectInfo,
  selectConsent,
} from '@/store/api/NewSearch/NewSearch.selector'
import { setSubjectInfo } from '@/store/api/NewSearch/NewSearch.slice'
import type { searchFormSchemaTypes } from '@/utils/schemas'
import { searchFormSchema } from '@/utils/schemas'

import { ProgressBar } from './Components/ProgressBar'
import { SearchForm } from './Components/SearchForm'
import { Stepper } from './Components/Stepper'
import { SubjectLocation } from './Const/SubjectLocationData'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const NewSearchSubjectInfo = () => {
  useHasCurrentStepToken('subjectInfoStep')
  const { t } = useTranslation()
  const subjectInfo = useAppSelector(selectSubjectInfo)
  const consent = useAppSelector(selectConsent)

  const form = useForm<searchFormSchemaTypes>({
    resolver: zodResolver(searchFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: consent.consentEmail ?? '',
      phoneNumber: '',
      countryLongName:
        consent.subjectLocationValue &&
        consent.subjectLocationValue !== SubjectLocation.RestOfTheWorld
          ? 'United States'
          : '',
      countryShortName: '',
      stateLongName:
        consent.subjectLocationValue &&
        consent.subjectLocationValue !== SubjectLocation.RestOfTheWorld
          ? consent.subjectLocationName
          : '',
      stateShortName: '',
      cityLongName: '',
      cityShortName: '',
      middleName: '',
      alias: '',
      dob: '',
      companyName: '',
      collage: '',
      image: null,
    },
    mode: 'onChange',
  })

  const hasSocialMediaData = useSelector(selectHasSocialMediaData)

  const {
    handleSubmit,
    formState: { dirtyFields },
    resetField,
    watch,
    setValue,
  } = form

  useEffect(() => {
    Object.keys(subjectInfo).forEach((key) => {
      const subjectInfoCast = subjectInfo as any
      if (subjectInfoCast[key] !== '') {
        setValue(key as keyof searchFormSchemaTypes, subjectInfoCast[key], {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      }
    })
  }, [subjectInfo, setValue])

  const searchType = useSelector(selectNewSearch).selectSearchType.searchType

  const steps = [
    searchType === 'ai_report' || searchType === 'adverse_media_report'
      ? undefined
      : t('dashboard.newSearch.common.focusedTopics'),
    t('dashboard.newSearch.common.subjectInformation'),
    t('dashboard.newSearch.common.submit'),
  ]

  const activeStep =
    searchType === 'ai_report' || searchType === 'adverse_media_report' ? 0 : 1

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const isMobile = useMobileMediaQuery()

  const onSubmit = (data: any) => {
    dispatch(
      setSubjectInfo({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        middleName: data.middleName,
        alias: data.alias,
        phoneNumber: data.phoneNumber,
        dob: data.dob,
        collage: data.collage,
        companyName: data.companyName,
        countryLongName: data.countryLongName,
        countryShortName: data.countryShortName,
        stateLongName: data.stateLongName,
        stateShortName: data.stateShortName,
        cityLongName: data.cityLongName,
        cityShortName: data.cityShortName,
        image: data.image,
      }),
    )
    dispatch(setFinalStepGuardToken({ finalStepGuardToken: true }))
    navigate(AppRoutes.newSearchLegalDisclaimer)
  }

  const requiredFields: (keyof searchFormSchemaTypes)[] = [
    'firstName',
    'lastName',
    'email',
    'countryLongName',
  ]
  const formWatch = watch()

  useEffect(() => {
    const phoneNumber = form.getValues().phoneNumber
    const dirtyFieldsKeys = Object.keys(dirtyFields)

    if (dirtyFieldsKeys.includes('phoneNumber') && !phoneNumber) {
      resetField('phoneNumber')
    }
  }, [dirtyFields, formWatch])

  return (
    <WeScreen
      onClickNextButton={handleSubmit(onSubmit)}
      isDisabledNextButton={!form.formState.isValid}
      childrenCustomPadding={24}
    >
      <div className="flex flex-col h-full justify-between max-lg:gap-4">
        <div className="flex flex-col items-center">
          <div>
            <Stepper steps={steps} activeStep={activeStep} />
          </div>
        </div>
        <div className="flex flex-col lg:h-[80%]">
          <FormProvider {...form}>
            <SearchForm />
          </FormProvider>
        </div>
        {!isMobile && (
          <ProgressBar
            form={form}
            requiredFields={requiredFields}
            hasSocialMediaData={hasSocialMediaData}
          />
        )}
      </div>
    </WeScreen>
  )
}
