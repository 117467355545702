import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type { CreateTeamRequest, CreateTeamResponse } from './CreateTeam.types'

export const createTeamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTeam: builder.mutation<CreateTeamResponse, CreateTeamRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces/${request.workspaceId}/teams`,
        method: 'POST',
        body: {
          teams: request.teams,
        },
      }),
      invalidatesTags: [
        RTKTags.userInfo,
        RTKTags.getWorkSpaces,
        RTKTags.workspaceDetails,
      ],
    }),
  }),
})

export const { useCreateTeamMutation } = createTeamApi
