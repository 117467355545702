import { ArrowDown2 } from 'iconsax-react'
import { useSelector } from 'react-redux'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useAppDispatch } from '@/hooks/redux'
import {
  selectSelectedTeam,
  selectSelectedWorkStation,
} from '@/store/api/Users/Users.selector'
import { setSelectedTeam } from '@/store/api/Users/Users.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

const TeamSelector = () => {
  const dispatch = useAppDispatch()
  const selectedWorkStation = useSelector(selectSelectedWorkStation)
  const selectedTeam = useSelector(selectSelectedTeam)

  const handleTeamSelect = (teamId: string) => {
    const team = selectedWorkStation?.teams.find((t) => t._id === teamId)
    if (team) {
      dispatch(setSelectedTeam(team))
    }
  }

  return (
    <div className="flex w-full justify-center">
      <Select value={selectedTeam?._id} onValueChange={handleTeamSelect}>
        <SelectTrigger
          className="w-min gap-3 items-center justify-center border-none bg-transparent focus:ring-0 focus:ring-offset-0 text-xl font-medium"
          hiddenArrow
        >
          <SelectValue placeholder={selectedTeam?.name} />
          <div className="rounded-full bg-baseColor justify-center items-center p-[2px]">
            <ArrowDown2 size="17" color={weCheckPalette.neutralColor30} />
          </div>
        </SelectTrigger>
        <SelectContent>
          {selectedWorkStation?.teams.map((team) => (
            <SelectItem key={team._id} value={team._id}>
              {team.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default TeamSelector
