import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Facebook from '@/assets/socialMediaLogos/fb.svg?react'
import Instagram from '@/assets/socialMediaLogos/ig.svg?react'
import Linkedln from '@/assets/socialMediaLogos/linkedIn.svg?react'
import Pinterest from '@/assets/socialMediaLogos/pinterest.svg?react'
import Reddit from '@/assets/socialMediaLogos/reddit.svg?react'
import TikTok from '@/assets/socialMediaLogos/tiktok.svg?react'
import X from '@/assets/socialMediaLogos/x.svg?react'
import { LoadSocialMediaSheet } from '@/components/NewBackgroundCheckForm/LoadSocialMediaSheet'
import type { SocialMediaLink } from '@/components/NewBackgroundCheckForm/NewBackgroundCheckForm.types'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import { useAppDispatch } from '@/hooks/redux'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import { setSocialMedia } from '@/store/api/NewSearch/NewSearch.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const SocialMediaSelector = () => {
  const dispatch = useAppDispatch()
  const socialMediaData = useSelector(selectNewSearch).socialMedia

  const [socialMediaTypes, setSocialMediaTypes] = useState<SocialMediaLink[]>(
    [],
  )

  useEffect(() => {
    const initialSocialMediaTypes: any[] = [
      { type: 'facebook', url: socialMediaData.facebook || '' },
      { type: 'instagram', url: socialMediaData.instagram || '' },
      { type: 'linkedin', url: socialMediaData.linkedIn || '' },
      { type: 'twitter', url: socialMediaData.x || '' },
      { type: 'tiktok', url: socialMediaData.tikTok || '' },
      { type: 'pinterest', url: socialMediaData.pinterest || '' },
      { type: 'reddit', url: socialMediaData.reddit || '' },
    ].filter((item) => item.url.trim() !== '')

    setSocialMediaTypes(initialSocialMediaTypes)
  }, [socialMediaData])

  const onSelectSocialMedia = (socialMedia: SocialMediaLink[]) => {
    const facebook =
      socialMedia.find((item) => item.type === 'facebook')?.url ?? ''
    const instagram =
      socialMedia.find((item) => item.type === 'instagram')?.url ?? ''
    const linkedIn =
      socialMedia.find((item) => item.type === 'linkedin')?.url ?? ''
    const x =
      socialMedia.find((item) => item.type === 'x')?.url ??
      socialMedia.find((item) => item.type === 'twitter')?.url ??
      ''
    const tiktok = socialMedia.find((item) => item.type === 'tiktok')?.url ?? ''
    const pinterest =
      socialMedia.find((item) => item.type === 'pinterest')?.url ?? ''
    const reddit = socialMedia.find((item) => item.type === 'reddit')?.url ?? ''

    dispatch(
      setSocialMedia({
        facebook: facebook,
        instagram: instagram,
        x: x,
        linkedIn: linkedIn,
        tikTok: tiktok,
        pinterest: pinterest,
        reddit: reddit,
      }),
    )
  }

  const socialMediaIcons = useMemo(() => {
    const components = []
    const Wrapper = ({ children }: { children: React.ReactNode }) => (
      <div className={`p-4 rounded-full bg-buttonPrincipalBackground`}>
        {children}
      </div>
    )
    const hasSocialMedia = (
      socialMedia:
        | 'x'
        | 'tiktok'
        | 'linkedin'
        | 'facebook'
        | 'instagram'
        | 'twitter'
        | 'pinterest'
        | 'reddit',
    ) => socialMediaTypes.some((item) => item.type === socialMedia)
    if (hasSocialMedia('facebook')) {
      components.push(
        <div className="flex flex-row gap-3 items-center justify-center">
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center">
                <Wrapper>
                  <div className="flex justify-center items-center gap-2">
                    <Facebook color={weCheckPalette.icon} />
                    <span>Facebook</span>
                  </div>
                </Wrapper>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="md:w-96">
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 justify-center items-center">
                  <Facebook color={weCheckPalette.icon} />
                  <p>Facebook:</p>
                </div>
                <p>
                  {
                    socialMediaTypes.find((item) => item.type === 'facebook')
                      ?.url
                  }
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>,
      )
    }
    if (hasSocialMedia('linkedin')) {
      components.push(
        <div className="flex flex-row gap-3 items-center justify-center">
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center">
                <Wrapper>
                  <div className="flex justify-center items-center gap-2">
                    <Linkedln color={weCheckPalette.icon} />
                    <span>Linkedin</span>
                  </div>
                </Wrapper>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="md:w-96">
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 justify-center items-center">
                  <Linkedln color={weCheckPalette.icon} />
                  <p>Linkedin:</p>
                </div>
                <p>
                  {
                    socialMediaTypes.find((item) => item.type === 'linkedin')
                      ?.url
                  }
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>,
      )
    }
    if (hasSocialMedia('x') || hasSocialMedia('twitter')) {
      components.push(
        <div className="flex flex-row gap-3 items-center justify-center">
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center">
                <Wrapper>
                  <div className="flex justify-center items-center gap-2">
                    <X color={weCheckPalette.icon} />
                    <span>X (Twitter)</span>
                  </div>
                </Wrapper>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="md:w-96">
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 justify-center items-center">
                  <X color={weCheckPalette.icon} />
                  <p>X (Twitter):</p>
                </div>
                <p>
                  {
                    socialMediaTypes.find(
                      (item) => item.type === 'x' || item.type === 'twitter',
                    )?.url
                  }
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>,
      )
    }
    if (hasSocialMedia('instagram')) {
      components.push(
        <div className="flex flex-row gap-3 items-center justify-center">
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center">
                <Wrapper>
                  <div className="flex justify-center items-center gap-2">
                    <Instagram color={weCheckPalette.icon} />
                    <span>Instagram</span>
                  </div>
                </Wrapper>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="md:w-96">
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 justify-center items-center">
                  <Instagram color={weCheckPalette.icon} />
                  <p>Instagram:</p>
                </div>
                <p>
                  {
                    socialMediaTypes.find((item) => item.type === 'instagram')
                      ?.url
                  }
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>,
      )
    }
    if (hasSocialMedia('tiktok')) {
      components.push(
        <div className="flex flex-row gap-3 items-center justify-center">
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center">
                <Wrapper>
                  <div className="flex justify-center items-center gap-2">
                    <TikTok color={weCheckPalette.icon} />
                    <span>Tik Tok</span>
                  </div>
                </Wrapper>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="md:w-96">
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 justify-center items-center">
                  <TikTok color={weCheckPalette.icon} />
                  <p>Tik Tok:</p>
                </div>
                <p>
                  {socialMediaTypes.find((item) => item.type === 'tiktok')?.url}
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>,
      )
    }
    if (hasSocialMedia('pinterest')) {
      components.push(
        <div className="flex flex-row gap-3 items-center justify-center">
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center">
                <Wrapper>
                  <div className="flex justify-center items-center gap-2">
                    <Pinterest color={weCheckPalette.icon} />
                    <span>Pinterest</span>
                  </div>
                </Wrapper>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="md:w-96">
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 justify-center items-center">
                  <Pinterest color={weCheckPalette.icon} />
                  <p>Pinterest:</p>
                </div>
                <p>
                  {
                    socialMediaTypes.find((item) => item.type === 'pinterest')
                      ?.url
                  }
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>,
      )
    }
    if (hasSocialMedia('reddit')) {
      components.push(
        <div className="flex flex-row gap-3 items-center justify-center">
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center">
                <Wrapper>
                  <div className="flex justify-center items-center gap-2">
                    <Reddit color={weCheckPalette.icon} />
                    <span>Reddit</span>
                  </div>
                </Wrapper>
              </div>
            </HoverCardTrigger>
            <HoverCardContent className="md:w-96">
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 justify-center items-center">
                  <Reddit color={weCheckPalette.icon} />
                  <p>Reddit:</p>
                </div>
                <p>
                  {socialMediaTypes.find((item) => item.type === 'reddit')?.url}
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>,
      )
    }
    return components
  }, [socialMediaTypes])

  return (
    <div className="flex flex-row items-center">
      <div className="flex gap-3">{socialMediaIcons}</div>
      <div role="button" className="mt-2">
        <LoadSocialMediaSheet
          socialMediaArray={socialMediaTypes}
          setSocialMediaArray={onSelectSocialMedia}
        />
      </div>
    </div>
  )
}
