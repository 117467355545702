import { useMemo } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Progress } from '@/components/ui/progress'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface ProgressBarTypes {
  form: UseFormReturn<any>
  requiredFields: string[]
  hasSocialMediaData?: boolean
}

export const ProgressBar = ({
  form,
  requiredFields,
  hasSocialMediaData = false,
}: ProgressBarTypes) => {
  const { t } = useTranslation()
  const formValues = form.watch()
  const { errors } = form.formState

  const completePercentage = useMemo(() => {
    const values = form.getValues()
    const { dirtyFields } = form.formState
    const totalFields = Object.keys(values).length

    const dirtyFieldsKeys = Object.keys(dirtyFields).filter(
      (item) => item !== 'socialMediaUrl',
    )

    const completedFields = dirtyFieldsKeys.filter((field: string) => {
      return values[field] !== '' && !errors[field]
    })

    const numberOfCompletedFields = hasSocialMediaData
      ? completedFields.length + 1
      : completedFields.length

    return (numberOfCompletedFields / totalFields) * 100
  }, [form, hasSocialMediaData, formValues, errors])

  const areRequiredFieldsValid = useMemo(() => {
    const formValues = form.getValues()
    return requiredFields.every((field) => {
      const value = formValues[field]
      return value !== undefined && value !== null && value !== ''
    })
  }, [form, requiredFields])

  const inputStrength = useMemo(() => {
    if (!areRequiredFieldsValid) {
      return {
        text: t('dashboard.newSearch.common.insufficient'),
        color: weCheckPalette.neutralColor60,
      }
    }
    if (completePercentage < 40) {
      return {
        text: t('dashboard.newSearch.common.essential'),
        color: weCheckPalette.dynamicBlue,
      }
    } else if (completePercentage < 70) {
      return {
        text: t('dashboard.newSearch.common.advanced'),
        color: weCheckPalette.dynamicBlue,
      }
    } else {
      return {
        text: t('dashboard.newSearch.common.precision'),
        color: weCheckPalette.dynamicGreen,
      }
    }
  }, [completePercentage, areRequiredFieldsValid])

  return (
    <div className="flex flex-col gap-2 mt-3">
      <div className="flex justify-between items-center">
        <div className="flex items-center justify-center">
          <p>{t('dashboard.newSearch.common.inputStrength')}</p>
          <p
            className="text-textAccent ml-2 font-medium"
            style={{ color: inputStrength.color }}
          >
            {inputStrength.text}
          </p>
        </div>
        <span className="text-neutralColor60">
          {t('dashboard.newSearch.common.estimateAccuracy')}
          <span className="text-neutralColor font-bold ml-1">
            {completePercentage.toFixed(0)} {'%'}
          </span>
        </span>
      </div>

      <Progress
        value={completePercentage}
        className="h-4"
        color={inputStrength.color}
      />
    </div>
  )
}
