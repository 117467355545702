import { DropdownMenuGroup } from '@radix-ui/react-dropdown-menu'
import { Add, ArrowDown2 } from 'iconsax-react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch } from '@/hooks/redux'
import {
  EUserPermission,
  EUserRole,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import {
  selectSelectedTeam,
  selectSelectedWorkStation,
  selectWorkStations,
} from '@/store/api/Users/Users.selector'
import {
  setSelectedTeam,
  setSelectedWorkStation,
} from '@/store/api/Users/Users.slice'
import type { Team, WorkStation } from '@/store/api/Users/Users.types'

const WorkStationSelector = () => {
  const workStations = useSelector(selectWorkStations)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const selectedWorkStation = useSelector(selectSelectedWorkStation)
  const selectedTeam = useSelector(selectSelectedTeam)
  const { hasPermission } = usePermissions()

  const handleTeamSelect = (team: Team, workStation: WorkStation) => {
    dispatch(setSelectedTeam(team))
    dispatch(setSelectedWorkStation(workStation))
  }

  const handleCreateNewTeam = (workspaceID: string) => {
    navigate(AppRoutes.createTeamFirstStep, { state: workspaceID })
  }

  const handleCreateNewWorkspace = () => {
    navigate(AppRoutes.workSpaceCreation)
  }

  return (
    <div className="flex w-full justify-center py-4">
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex items-center text-sm font-medium  justify-between w-full gap-2 text-neutralColor px-2 py-1 rounded-md border-none ">
            {selectedWorkStation?.name}, <br /> {selectedTeam?.name}
            <ArrowDown2 size={16} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-2 bg-baseColorHighlight">
          <DropdownMenuGroup>
            {workStations.map((ws) => (
              <div key={`${ws._id}-workstation-item`}>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>{ws.name}</DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className="p-2 bg-baseColorHighlight">
                      {ws.teams.map((team) => (
                        <div key={`${team._id}-team-item`}>
                          <DropdownMenuItem
                            key={team._id}
                            onFocus={(e) => {
                              // Avoid error console.
                              if (
                                e.currentTarget.closest('[aria-hidden="true"]')
                              ) {
                                e.preventDefault()
                              }
                            }}
                            onClick={() => handleTeamSelect(team, ws)}
                          >
                            {team.name}
                          </DropdownMenuItem>

                          <DropdownMenuSeparator />
                        </div>
                      ))}
                      {hasPermission(
                        EUserPermission.HANDLE_WORKSPACES,
                        EUserRole.WORKSPACE_ADMIN,
                      ) && (
                        <DropdownMenuItem>
                          <div
                            onClick={() => handleCreateNewTeam(ws._id)}
                            className="flex items-center gap-2 justify-between w-full"
                          >
                            Create new Team <Add size={16} />
                          </div>
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
                <DropdownMenuSeparator />
              </div>
            ))}
            {hasPermission(
              EUserPermission.HANDLE_WORKSPACES,
              EUserRole.CO_OWNER,
            ) && (
              <DropdownMenuItem>
                <div
                  onClick={() => handleCreateNewWorkspace()}
                  className="flex items-center gap-2 justify-between w-full"
                >
                  Create new Workspace <Add size={16} />
                </div>
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default WorkStationSelector
