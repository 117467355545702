import { ArrowLeft } from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface WeScreenHeaderProps {
  customButton?: React.ReactNode
  disabled?: boolean
  useContinueButton?: boolean
  onClickNext?: (key: any) => void
  useSkipButton?: boolean
  onClickSkipButton?: (key: any) => void
  isLoading?: boolean
  title?: string
  useBackButton?: boolean
  onClickBack?: () => void
  leftSideContent?: React.ReactNode
}

export const WeScreenHeader: React.FC<WeScreenHeaderProps> = ({
  customButton,
  onClickNext,
  disabled,
  useContinueButton = true,
  isLoading,
  title,
  useBackButton = true,
  onClickBack,
  leftSideContent,
  useSkipButton,
  onClickSkipButton,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className="flex justify-between items-center bg-primaryColor5 h-14 p-4 max-md:p-2">
      {useBackButton && (
        <Button
          asChild
          size={'icon'}
          variant={'ghost'}
          onClick={onClickBack ? onClickBack : goBack}
        >
          <div>
            <ArrowLeft color={weCheckPalette.neutralColor} />
          </div>
        </Button>
      )}
      {leftSideContent && <div>{leftSideContent}</div>}

      {title && (
        <div
          key={`title-${title}`}
          className={`flex ${useBackButton && 'justify-start flex-1 ml-4'}`}
        >
          <h1 className="text-[22px] font-medium text-neutralColor">{title}</h1>
        </div>
      )}

      <div className="flex flex-row gap-4">
        {customButton && customButton}
        {useSkipButton && (
          <Button
            variant="link"
            role="button"
            isLoading={isLoading}
            onClick={onClickSkipButton}
          >
            <p className="text-primaryColor text-base font-medium tracking-wide">
              {t('dashboard.newSearch.common.skip')}
            </p>
          </Button>
        )}
        {useContinueButton && (
          <Button
            variant="default"
            type={onClickNext ? 'button' : 'submit'}
            role="button"
            disabled={disabled}
            isLoading={isLoading}
            onClick={onClickNext}
          >
            <p className="text-base font-medium tracking-wide">
              {t('dashboard.newSearch.common.continue')}
            </p>
          </Button>
        )}
      </div>
    </div>
  )
}
