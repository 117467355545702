import React from 'react'
import { useSelector } from 'react-redux'

import RoleGuide from '@/assets/roleGuide.png'
import RoleGuideLight from '@/assets/roleGuideLight.svg?react'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { selectIsDarkMode } from '@/store/api/ui/ui.selector'

export const RoleGuideScreen = () => {
  const theme = useSelector(selectIsDarkMode)
  console.log(theme)
  return (
    <WeScreen title={'Role Guide'} useNextButton={false} useHeaderBackButton>
      <div className="w-full">
        {theme ? (
          <img
            src={RoleGuide}
            alt="Role Guide"
            className="w-full"
            style={{ maxHeight: 'calc(100vh - 100px)', objectFit: 'contain' }}
          />
        ) : (
          <RoleGuideLight
            className="w-full"
            style={{ maxHeight: 'calc(100vh - 100px)', objectFit: 'contain' }}
          />
        )}
      </div>
    </WeScreen>
  )
}
