import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type { CreateWorkSpaceRequest } from './CreateWorkSpace.types'

export const createWorkspaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createWorkSpace: builder.mutation<void, CreateWorkSpaceRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/workspaces`,
        method: 'POST',
        body: {
          name: request.name,
          teams: request.teams,
        },
      }),
      invalidatesTags: [
        RTKTags.createWorkspace,
        RTKTags.getWorkSpaces,
        RTKTags.workspaceDetails,
      ],
    }),
  }),
})

export const { useCreateWorkSpaceMutation } = createWorkspaceApi
