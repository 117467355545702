import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { CCPANotice } from '@/constants/TermsAndConditions'
import { useAppDispatch } from '@/hooks/redux'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useRudderStack } from '@/hooks/useRudderStack'
import { useRegisterMutation } from '@/store/api/Auth/Auth.query'
import { setSession, setUserID } from '@/store/api/Auth/Auth.slice'
import { setUserCredentials } from '@/store/api/SignUp/SignUp.slice'
import { setUserOrigin } from '@/store/api/Users/Users.slice'
import type { signUpType } from '@/utils/schemas'
import { signUpSchema } from '@/utils/schemas'

export const SignUpStart = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [isChecked, setIsChecked] = useState(false)

  const { trackEvent, identifyUser } = useRudderStack()

  const params = new URLSearchParams(location.search)
  const from = params.get('from')

  useEffect(() => {
    if (from) {
      dispatch(setUserOrigin(from))
    }
  }, [from])

  const form = useForm<signUpType>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: '',
      confirmPassword: '',
      createPassword: '',
    },
  })

  const {
    handleSubmit,
    control,
    formState: { errors = {}, dirtyFields },
    watch,
  } = form

  const passNotMatch =
    Object.keys(dirtyFields).includes('confirmPassword') &&
    watch().confirmPassword !== watch().createPassword

  const [register, { isLoading, isSuccess }] = useRegisterMutation()

  const handleCheckboxChange = (newChecked: boolean) => {
    setIsChecked(newChecked)
  }

  const makeRegister = async (password: string, email: string) => {
    try {
      const response = await register({
        email,
        password,
      }).unwrap()
      if (response.info.id) {
        dispatch(setSession(response.info))
        identifyUser({ userId: response.info.id, email })
        dispatch(setUserCredentials({ email }))
        dispatch(setUserID(response.info.id))
        trackEvent(Events.ONBOARDING_START, response.info.id, {
          email,
        })
        navigate(AppRoutes.registerEmailVerification)
      }
    } catch (e) {
      catchWithSentry(e)
      if (e.data.code.includes('EMAIL_DUP')) {
        toast.error(t('dashboard.landingSingUp.emailRegistered'))
      } else if (e.data.code.includes('USER_HAS_INVITATION')) {
        toast.error(t('dashboard.landingSingUp.userHasInvitation'))
      } else {
        toast.error(t('dashboard.landingSingUp.error'))
      }
    }
  }

  const onSubmit = (data: any) => {
    if (passNotMatch) {
      return
    } else {
      makeRegister(data.createPassword, data.email)
      if (isSuccess) {
        navigate(AppRoutes.registerEmailVerification)
      }
    }
  }

  return (
    <div className="flex flex-col gap-8 max-w-[436px]">
      <div className="flex flex-col gap-5">
        <p className="text-[40px] font-bold text-textNormal tracking-wide text-center">
          {t('dashboard.landingSingUp.signUp')}
        </p>
        <p className="text-1xl max-w-[436px] text-textAccent tracking-tight text-center">
          {t('dashboard.landingSingUp.signUpText')}
        </p>
      </div>

      <Form {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 w-full"
        >
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t('dashboard.landingSingUp.email')}
                  />
                </FormControl>
                <FormMessage data-name="email" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="createPassword"
            render={({ field }) => (
              <FormItem className="w-full bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t('dashboard.landingSingUp.createPassword')}
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                <FormMessage data-name="createPassword" />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="w-full  bg-white rounded-md">
                <FormControl className="w-full focus:outline-none">
                  <Input
                    {...field}
                    placeholder={t('dashboard.landingSingUp.confirmPassword')}
                    type="password"
                    showPassIcon
                  />
                </FormControl>
                {passNotMatch && !errors.confirmPassword && (
                  <p className="text-[0.8rem] font-medium text-destructive">
                    {t('dashboard.landingSingUp.passwordsDoNotMatch')}
                  </p>
                )}
                <FormMessage data-name="confirmPassword" />
              </FormItem>
            )}
          />

          <p className="text-textAccent text-base font-light text-center">
            {t('dashboard.landingSingUp.signUpSecondText')}
          </p>
          <div className="">
            <div className="flex flex-row gap-3">
              <Checkbox
                className="w-5 h-5 rounded-md"
                onCheckedChange={handleCheckboxChange}
              />
              <p className="text-textAccent text-base font-light ">
                {t('dashboard.landingSingUp.iAgree')}
              </p>
            </div>
          </div>
          <Button
            disabled={!isChecked}
            type="submit"
            className="py-3 px-5 font-medium tracking-widest h-11"
            isLoading={isLoading}
          >
            {t('dashboard.landingSingUp.continue')}
          </Button>
        </form>
      </Form>
      <div className="border-t pt-6 text-center max-w-[436px]">
        <p className="text-sm font-medium ">
          {t('dashboard.landingSingUp.clickingContinue')}&nbsp;
          <span
            className="text-linkColor "
            role="button"
            onClick={() =>
              window.open(
                t('dashboard.common.termsAndConditionsLink'),
                '_blank',
              )
            }
            tabIndex={0}
          >
            {t('dashboard.landingSingUp.termsAndConditions')}
          </span>
          &nbsp;
          <span
            className="text-linkColor"
            role="button"
            onClick={() =>
              window.open(t('dashboard.common.privacyPolicyLink'), '_blank')
            }
            tabIndex={0}
          >
            {t('dashboard.landingSingUp.privacyPolicy')}&nbsp;
          </span>
          <br />
          {t('dashboard.landingSingUp.additionalInformation')}
          &nbsp;
          <span
            className="text-linkColor"
            role="button"
            onClick={() => window.open(CCPANotice.CCPA_NOTICE, '_blank')}
            tabIndex={0}
          >
            {t('dashboard.landingSingUp.cCPANotice')}
          </span>
        </p>
      </div>
    </div>
  )
}
