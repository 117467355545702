import React from 'react'

import FacebookIcon from '@/assets/socialMedia/Facebook.svg?react'
import InstagramIcon from '@/assets/socialMedia/Instagram.svg?react'
import LinkedinIcon from '@/assets/socialMedia/LinkedIn.svg?react'
import PinterestIcon from '@/assets/socialMedia/Pinterest.svg?react'
import RedditIcon from '@/assets/socialMedia/Reddit.svg?react'
import TiktokIcon from '@/assets/socialMedia/TikTok.svg?react'
import WebSearch from '@/assets/socialMedia/Web.svg?react'
import TwitterIcon from '@/assets/socialMedia/X.svg?react'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import type { SocialMediaPost } from '@/store/api/Person/Person.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { PostCardContent } from './PostCardContent'

interface PostCardTypes {
  post?: SocialMediaPost
  cardHeigth: number
}

type Platform =
  | 'Pinterest'
  | 'Tiktok'
  | 'Reddit'
  | 'Twitter'
  | 'Instagram'
  | 'Facebook'
  | 'LinkedIn'
  | 'WebSearch'

export const PostCard = ({ post, cardHeigth }: PostCardTypes) => {
  const platformIcons: Record<Platform, JSX.Element> = {
    Pinterest: <PinterestIcon color={weCheckPalette.neutralColor80} />,
    Tiktok: <TiktokIcon color={weCheckPalette.neutralColor80} />,
    Reddit: <RedditIcon color={weCheckPalette.neutralColor80} />,
    Twitter: <TwitterIcon color={weCheckPalette.neutralColor80} />,
    Instagram: <InstagramIcon color={weCheckPalette.neutralColor80} />,
    Facebook: <FacebookIcon color={weCheckPalette.neutralColor80} />,
    LinkedIn: <LinkedinIcon color={weCheckPalette.neutralColor80} />,
    WebSearch: <WebSearch color={weCheckPalette.neutralColor80} />,
  }

  const isValidPlatform = (platform: string): platform is Platform => {
    return [
      'Pinterest',
      'Tiktok',
      'Reddit',
      'Twitter',
      'Instagram',
      'Facebook',
      'LinkedIn',
      'WebSearch',
    ].includes(platform)
  }
  const isSmallDesktop = useSmallDesktopQuery()

  return (
    <div
      className={`rounded-2xl bg-baseColorHighlight flex flex-col border border-neutralColor30`}
      style={{ height: cardHeigth }}
    >
      <div
        className={
          isSmallDesktop
            ? 'flex flex-row gap-2 pt-2 px-2 items-center'
            : 'flex flex-row gap-2 lg:pt-4 pt-2 lg:px-4 px-2 items-center'
        }
      >
        <div className="flex justify-center items-center bg-primaryColor5 rounded-full h-10 w-10 border border-primaryColor30 ">
          {post?.platform &&
            isValidPlatform(post.platform) &&
            platformIcons[post.platform]}
        </div>
        <div className="flex flex-col">
          <p className="text-textNormal text-base font-medium">{post?.type}</p>
          <div className="flex flex-row flex-wrap">
            {post?.flags.map((flag, index) => (
              <div
                key={index}
                className="flex flex-row content-center justify-center items-center"
              >
                <p className="text-base text-neutralColor">{flag}</p>
                {index < post.flags.length - 1 && (
                  <p className="text-xs text-neutralColor px-1">-</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <PostCardContent
          reason={post?.flagReason ?? post?.sentimentReason}
          postType={post?.type}
          postDate={post?.date}
          content={post?.content}
          imageUrl={post?.mediaUrl}
          link={post?.link}
          platform={post?.platform ?? ''}
          cardHeigth={cardHeigth}
          videoUrl={post?.videoUrl}
        />
      </div>
    </div>
  )
}
