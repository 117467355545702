import React from 'react'

import { WeScreen } from '@/components/WeScreen/WeScreen'

import { HistoryPlanComponent } from '../PurchaseHistory/HistoryPlan/HistoryPlanComponent'

import { CreditsBalanceCard } from './components/CreditsBalanceCard'

export const Biling = () => {
  return (
    <WeScreen
      title={'Account Balance'}
      useNextButton={false}
      useHeaderBackButton={false}
    >
      <div className="flex flex-col gap-4">
        <div>
          <CreditsBalanceCard />
        </div>
        <div className="">
          <HistoryPlanComponent />
        </div>
      </div>
    </WeScreen>
  )
}
