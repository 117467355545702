import React from 'react'

export const CreditCard = ({
  text,
  icon,
  number,
}: {
  text: string
  icon: any
  number: number
}) => {
  return (
    <div className="bg-baseColorHighlight flex flex-row justify-between rounded-full p-4 w-full">
      <p className="text-base text-neutralColor">{text}</p>
      <div className="flex flex-row gap-2">
        {number}
        {icon}
      </div>
    </div>
  )
}
