import EmptyStateAsset from '@/assets/emptyState/emptyStateAsset.svg?react'
import { CardContent } from '@/components/ui/card'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'

export const EmptyState = ({ text }: { text?: string }) => {
  const isMobile = useMobileMediaQuery()
  return (
    <CardContent className="lg:h-3/4 flex justify-center items-center">
      <div
        id="emptyState"
        className="flex flex-col justify-center items-center p-8"
      >
        <EmptyStateAsset />

        <p
          className={`text-base font-medium text-center neutral-500 text-textAccent tracking-wider ${
            isMobile && 'text-xs'
          }`}
        >
          {text}
        </p>
      </div>
    </CardContent>
  )
}
