import React from 'react'

interface RecordCardInterface {
  title: string
  content: string
}

export const RecordCard = ({ title, content }: RecordCardInterface) => {
  return (
    <div className="flex justify-between items-center">
      <p className="text-neutralColor text-sm font-medium tracking-tight">
        {title}
      </p>
      <p className="text-neutralColor60 text-sm font-medium tracking-tight">
        {content}
      </p>
    </div>
  )
}
