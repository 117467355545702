import { WeScreen } from '@/components/WeScreen/WeScreen'

export const Lab = () => {
  return (
    <WeScreen
      title="Laboratory"
      useNextButton={false}
      useHeaderBackButton={false}
    >
      <div className="p-6 bg-baseColorHighlight rounded-lg">
        <h1>Proximamente...</h1>
      </div>
    </WeScreen>
  )
}
