import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import type { listOfUseCasesResponse } from '@/store/api/NewSearch/NewSearch.types'

import { UseCaseGuidelineSheet } from './UseCaseGuidelineSheet'

interface UseCaseSectionTypes {
  data?: listOfUseCasesResponse
  handleUseCaseChange: any
  selectedUseCaseName?: string
  handleSwitchChange: any
  consentSwitchValue: boolean
  isSwitchDisabled: boolean
  language: string
}

export const UseCaseSection = ({
  data,
  handleUseCaseChange,
  selectedUseCaseName,
  handleSwitchChange,
  consentSwitchValue,
  isSwitchDisabled,
  language,
}: UseCaseSectionTypes) => {
  const { t } = useTranslation()

  const companySearchUseCases = data?.info.filter(
    (useCase) => useCase.isForCompanySearch,
  )

  const individualSearchUseCases = data?.info.filter(
    (useCase) => !useCase.isForCompanySearch,
  )

  return (
    <div className="flex flex-col gap-6 w-full h-min">
      <div className="flex flex-col gap-3 items-center">
        <p className="text-center text-neutralColor text-xl font-medium">
          {t('dashboard.newSearch.useCases.title')}
        </p>
        <span className="text-center text-neutralColor80 text-base font-normal">
          {t('dashboard.newSearch.useCases.subtitle')}
          <UseCaseGuidelineSheet data={data?.info} language={language} />
        </span>
      </div>
      <div className="flex gap-3 max-md:flex-col">
        <Select onValueChange={handleUseCaseChange}>
          <SelectTrigger className="h-[46px] rounded-lg">
            <SelectValue
              placeholder={
                selectedUseCaseName !== ''
                  ? selectedUseCaseName
                  : t('dashboard.newSearch.useCases.selectUseCase')
              }
            />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>
                {t('dashboard.newSearch.useCases.individualSearch')}
              </SelectLabel>
              {individualSearchUseCases?.map((useCase, index) => {
                const translation = useCase.translations.find(
                  (t) => t.language === language,
                )
                return (
                  <div className="flex items-center hover:bg-primaryColor5 gap-2">
                    <SelectItem
                      hiddenCheckIcon
                      key={index}
                      value={useCase._id || ''}
                    >
                      <p>{translation?.name}</p>
                    </SelectItem>
                    {useCase.requiredComplianceForm && (
                      <p className="text-dynamicBlue text-nowrap py-1.5 pr-2 text-sm font-medium tracking-tight ">
                        {t('dashboard.newSearch.useCases.consentRequired')}
                      </p>
                    )}
                  </div>
                )
              })}
              <SelectSeparator />
              <SelectLabel>
                {t('dashboard.newSearch.useCases.companySearch')}
              </SelectLabel>
              {companySearchUseCases?.map((useCase, index) => {
                const translation = useCase.translations.find(
                  (t) => t.language === language,
                )
                return (
                  <SelectItem
                    hiddenCheckIcon
                    value={useCase._id || ''}
                    key={index}
                  >
                    <p>{translation?.name}</p>
                  </SelectItem>
                )
              })}
            </SelectGroup>
          </SelectContent>
        </Select>

        <Card className="flex gap-3 p-4 justify-between h-[46px] w-full items-center rounded-lg">
          <p className="text-neutralColor text-base font-normal">
            {t('dashboard.newSearch.useCases.cardTitle')}
          </p>

          <Switch
            className="w-11 h-6"
            onCheckedChange={handleSwitchChange}
            checked={consentSwitchValue}
            disabled={isSwitchDisabled}
          />
        </Card>
      </div>
    </div>
  )
}
