import { useTranslation } from 'react-i18next'

import { BehaviorDetectionSheet } from './BehaviorDetectionSheet'

export const DefaultBehaviorFlags = () => {
  const { t } = useTranslation()

  const defaultFlags = [
    t('dashboard.newSearch.focusedTopics.disparagingComments'),
    t('dashboard.newSearch.focusedTopics.threats'),
    t('dashboard.newSearch.focusedTopics.suggestiveContent'),
    t('dashboard.newSearch.focusedTopics.nudity'),
    t('dashboard.newSearch.focusedTopics.suicideMentions'),
    t('dashboard.newSearch.focusedTopics.politicsGovernment'),
    t('dashboard.newSearch.focusedTopics.drugImagery'),
    t('dashboard.newSearch.focusedTopics.goryImages'),
    t('dashboard.newSearch.focusedTopics.prejudice'),
    t('dashboard.newSearch.focusedTopics.profanity'),
    t('dashboard.newSearch.focusedTopics.drugAlcoholMention'),
    t('dashboard.newSearch.focusedTopics.gambling'),
  ]

  return (
    <div className="flex flex-col gap-6 w-full h-min mt-6">
      <div className="flex flex-col gap-3 items-center">
        <p className="text-center text-neutralColor text-2xl">
          {t('dashboard.newSearch.focusedTopics.defaultBehaviorFlagsTitle')}
        </p>

        <span className="text-center text-neutralColor80 text-base font-normal">
          {t(
            'dashboard.newSearch.focusedTopics.defaultBehaviorFlagsDescription1',
          )}
          <span className="font-bold">
            {t(
              'dashboard.newSearch.focusedTopics.defaultBehaviorFlagsDescription2',
            )}
          </span>
          {t(
            'dashboard.newSearch.focusedTopics.defaultBehaviorFlagsDescription3',
          )}
          <span className="font-bold">
            {t(
              'dashboard.newSearch.focusedTopics.defaultBehaviorFlagsDescription4',
            )}
          </span>
          {t(
            'dashboard.newSearch.focusedTopics.defaultBehaviorFlagsDescription5',
          )}
          <span className="font-bold">
            {t(
              'dashboard.newSearch.focusedTopics.defaultBehaviorFlagsDescription6',
            )}
          </span>
        </span>
      </div>

      <div className="flex flex-wrap gap-2 p-4 justify-center items-center rounded-xl">
        {defaultFlags.map((flag, index) => (
          <div
            key={index}
            className="px-4 py-2 bg-baseColorHighlight rounded-full text-sm font-medium text-neutralColor"
          >
            {flag}
          </div>
        ))}
        <div
          className="px-4 py-2 text-dynamicBlue text-sm font-medium cursor-pointer"
          role="button"
          tabIndex={0}
        >
          <BehaviorDetectionSheet />
        </div>
      </div>
    </div>
  )
}
