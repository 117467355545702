import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import AdverseMediaCreditIcon from '@/assets/sidebar/adverseMediaCreditIcon.svg?react'
import AiPlusAnalistCreditIcon from '@/assets/sidebar/aiPlusAnalistReportCrediticon.svg?react'
import AiReportCreditIcon from '@/assets/sidebar/aiReportCreditIcon.svg?react'
import FacialMatchingCrediticon from '@/assets/sidebar/facialMatchingCreditIcon.svg?react'
import WeCheckAssistedCreditIcon from '@/assets/sidebar/weCheckAssistedCreditIcon.svg?react'
import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { EReportType } from '@/store/api/Enterprise/Enterprise.types'
import { selectEnterpriseCreditsFromStore } from '@/store/api/Users/Users.selector'
import { useAssignCreditsToWorkspaceMutation } from '@/store/api/workspace/workspace.query'
import type {
  Workspace,
  WorkspaceCredits,
} from '@/store/api/workspace/workspace.types'
import type { creditsSchemaType } from '@/utils/schemas'
import { creditsSchema } from '@/utils/schemas'

import { ManageCreditsSheetCard } from './ManageCreditsSheetCard'

//deprecated until further notice
interface IManageCreditsSheet {
  title: string
  workspace: Workspace
  workspaceCredits: WorkspaceCredits
  triggerCopy?: string
}

export const ManageCreditsSheet = ({
  title,
  workspace,
  workspaceCredits,
  triggerCopy = 'Manage Team',
}: IManageCreditsSheet) => {
  const [isOpen, setIsOpen] = useState(false)
  const enterpriseCredits = useSelector(selectEnterpriseCreditsFromStore)

  const methods = useForm<creditsSchemaType>({
    resolver: zodResolver(creditsSchema),
    defaultValues: {
      ai_report: workspaceCredits.aiReportCredits,
      ai_analyst_report: workspaceCredits.aiAnalystReportCredits,
      adverse_media_report: workspaceCredits.adverseMediaReportCredits,
      assisted_report: workspaceCredits.assistedReportCredits,
      facial_matching: workspaceCredits.facialMatchingCredits,
    },
    mode: 'onChange',
  })

  const [assignCreditsToWorkspace, { isLoading }] =
    useAssignCreditsToWorkspaceMutation()

  const onSubmit = async (data: creditsSchemaType) => {
    // Filtrar y mapear los datos
    const assignments = Object.entries(data)
      .filter(([_, value]) => value > 0)
      .map(([key, value]) => ({
        quantity: value,
        type: key as EReportType, // Asegúrate de que las keys coinciden con EReportType
      }))

    if (assignments.length === 0) {
      // Opcional: Manejar el caso donde no hay créditos para asignar
      console.warn('No hay créditos para asignar.')
      return
    }

    try {
      await assignCreditsToWorkspace({
        workspaceId: workspace?._id,
        values: assignments,
      })
      handleClose()
    } catch (error) {
      console.error('error asigning credits', error)
    }
  }

  const handleClose = () => setIsOpen(false)

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger>
        <p className="text-neutralColor text-sm border-neutralColor30 border-[1px] py-1 px-3 rounded-lg">
          {triggerCopy}
        </p>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-4 overflow-y-scroll md:rounded-tl-2xl px-4">
        <SheetHeader className="flex flex-col gap-2 mt-[-6px]">
          <SheetTitle className="text-neutralColor text-textNormal text-xl font-medium tracking-wide text-left">
            {title}
          </SheetTitle>
          <SheetDescription className="text-neutralColor80 max-md:text-left">
            Your input determines the final credits for each report type in this
            workspace.
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col gap-4 w-full">
          <FormProvider {...methods}>
            <ManageCreditsSheetCard
              title="AI Report"
              creditsAvalible={enterpriseCredits?.aiReportCredits ?? 0}
              icon={<AiReportCreditIcon />}
              workspace={workspace}
              type={EReportType.Ai}
            />
            <ManageCreditsSheetCard
              title="AI + Analyst Report"
              creditsAvalible={enterpriseCredits?.aiAnalystReportCredits ?? 0}
              icon={<AiPlusAnalistCreditIcon />}
              workspace={workspace}
              type={EReportType.AiAnalyst}
            />
            <ManageCreditsSheetCard
              title="WeCheck Assisted"
              creditsAvalible={enterpriseCredits?.assistedReportCredits ?? 0}
              icon={<WeCheckAssistedCreditIcon />}
              workspace={workspace}
              type={EReportType.Assited}
            />
            <ManageCreditsSheetCard
              title="Adverse Media"
              creditsAvalible={
                enterpriseCredits?.adverseMediaReportCredits ?? 0
              }
              icon={<AdverseMediaCreditIcon />}
              workspace={workspace}
              type={EReportType.AdverseMedia}
            />
            <ManageCreditsSheetCard
              title="Facial Matching"
              creditsAvalible={enterpriseCredits?.facialMatchingCredits ?? 0}
              icon={<FacialMatchingCrediticon />}
              workspace={workspace}
              type={EReportType.FacialMatching}
            />
          </FormProvider>
        </div>
        <div className="flex flex-row gap-3 justify-end">
          <Button
            disabled={isLoading}
            onClick={handleClose}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={methods.handleSubmit(onSubmit)}
            variant="default"
          >
            Save
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
