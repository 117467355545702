import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import AdverseMediaCreditIcon from '@/assets/sidebar/adverseMediaCreditIcon.svg?react'
import AiPlusAnalistCreditIcon from '@/assets/sidebar/aiPlusAnalistReportCrediticon.svg?react'
import AiReportCreditIcon from '@/assets/sidebar/aiReportCreditIcon.svg?react'
import FacialMatchingCrediticon from '@/assets/sidebar/facialMatchingCreditIcon.svg?react'
import WeCheckAssistedCreditIcon from '@/assets/sidebar/weCheckAssistedCreditIcon.svg?react'
import { selectEnterpriseCreditsFromStore } from '@/store/api/Users/Users.selector'
import type {
  Workspace,
  WorkspaceCredits,
} from '@/store/api/workspace/workspace.types'

import { CreditCard } from './CreditCard'
import { ManageCreditsSheet } from './ManageCreditsSheet'

export const CreditsBalanceCard = ({
  isManageCredits = false,
  sheetTitle,
  workspace,
  workspaceCredits,
}: {
  isManageCredits?: boolean
  sheetTitle?: string
  workspace?: Workspace
  workspaceCredits?: WorkspaceCredits
}) => {
  const credits = useSelector(selectEnterpriseCreditsFromStore)
  const { t } = useTranslation()
  return (
    <div className="bg-baseColor flex flex-col gap-4 rounded-lg p-4">
      {isManageCredits && sheetTitle && workspace && workspaceCredits ? (
        <div className="flex flex-row justify-between">
          <p className="text-xl text-neutralColor">
            {t('dashboard.biling.creditsBalance')}
          </p>
          <ManageCreditsSheet
            title={sheetTitle}
            workspace={workspace}
            workspaceCredits={workspaceCredits}
          />
        </div>
      ) : (
        <p className="text-xl text-neutralColor">
          {t('dashboard.biling.creditsBalance')}
        </p>
      )}

      <div className="flex flex-col lg:grid lg:grid-cols-3 gap-4">
        <CreditCard
          text={t('dashboard.biling.aIReport')}
          number={credits?.aiReportCredits ?? 0}
          icon={<AiReportCreditIcon />}
        />
        <CreditCard
          text={t('dashboard.biling.adverseMedia')}
          number={credits?.adverseMediaReportCredits ?? 0}
          icon={<AdverseMediaCreditIcon />}
        />
        <CreditCard
          text={t('dashboard.biling.facialMatching')}
          number={credits?.facialMatchingCredits ?? 0}
          icon={<FacialMatchingCrediticon />}
        />
      </div>
      <div className="flex lg:flex-row flex-col gap-4 w-full">
        <CreditCard
          text={t('dashboard.biling.aIAnalystReport')}
          number={credits?.aiAnalystReportCredits ?? 0}
          icon={<AiPlusAnalistCreditIcon />}
        />
        <CreditCard
          text={t('dashboard.biling.weCheckAssisted')}
          number={credits?.assistedReportCredits ?? 0}
          icon={<WeCheckAssistedCreditIcon />}
        />
      </div>
    </div>
  )
}
