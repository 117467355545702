import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Loader } from '@/components/Loader/Loader'
import { Input } from '@/components/ui/input'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import {
  selectItsDefaultState,
  selectSelectedWorkStation,
  selectSelectedTeam,
} from '@/store/api/Users/Users.selector'
import { useGetWorkSpaceReportsQuery } from '@/store/api/workspace/workspace.query'
import type { EReportFilter } from '@/store/api/workspace/workspace.types'
import { formatDate } from '@/utils/date'

import { FilterChip } from './Components/FilterChip'
import {
  ReportStatusSelector,
  type ReportStatusType,
} from './Components/ReportStatusSelector'
import { ReportTypeSelector } from './Components/ReportTypeSelector'
import { SocialAnalysisItem } from './Components/SocialAnalysisItem'
import TeamSelector from './Components/TeamSelector'

export type ExtendedReportFilter = EReportFilter | 'your_reports' | undefined

export const ReportsScreen = () => {
  const { t } = useTranslation()
  const { hasViewReports } = useRedirectBasedOnPermissions()
  const isDefaultState = useAppSelector(selectItsDefaultState)
  const selectedWorkStation = useSelector(selectSelectedWorkStation)
  const selectedTeam = useSelector(selectSelectedTeam)

  const [search, setSearch] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<
    ExtendedReportFilter | 'all'
  >('all')
  const [selectedStatus, setSelectedStatus] = useState<ReportStatusType>('all')
  useEffect(() => {
    hasViewReports()
  }, [isDefaultState])

  const { data, isLoading, isError, isFetching } = useGetWorkSpaceReportsQuery(
    {
      id: selectedWorkStation?._id ?? '',
      teamId: selectedTeam?._id ?? '',
      page: 1,
      pageSize: 10000,
      reportType:
        selectedFilter === 'all'
          ? undefined
          : selectedFilter !== 'your_reports'
            ? selectedFilter
            : undefined,
      onlyUser: selectedFilter === 'your_reports',
      status:
        selectedStatus === 'all' || selectedStatus === 'new'
          ? undefined
          : selectedStatus,
    },
    { pollingInterval: 240000 },
  )

  const filteredReports = data?.info
    .filter((report) => {
      const searchTerm = search.toLocaleLowerCase()
      if (searchTerm === '') return true

      const name = report?.inputs?.name?.toLocaleLowerCase() || ''
      const email = report?.inputs?.email?.toLocaleLowerCase() || ''
      const formattedDate =
        formatDate(report?.reportDate, 'MM/dd/yyyy')?.toLocaleLowerCase() || ''

      return (
        name.includes(searchTerm) ||
        email.includes(searchTerm) ||
        formattedDate.includes(searchTerm)
      )
    })
    .sort((a, b) => {
      return new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
    })
    .map((item, index) => {
      return <SocialAnalysisItem data={item} key={index} />
    })

  return (
    <WeScreen
      isError={isError}
      useNextButton={false}
      useHeaderBackButton={false}
      childrenCustomGap={20}
      leftSideHeaderContent={<TeamSelector />}
    >
      <div className="w-full flex justify-between sm:items-center max-sm:flex-col max-sm:gap-5">
        <Input
          placeholder={t('dashboard.reportsScreen.search')}
          className="flex h-[46px] w-[354.67px] rounded-lg max-sm:w-full"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          disabled={filteredReports?.length === 0 && search === ''}
        />
        <div className="flex gap-5">
          <ReportTypeSelector
            selectedFilter={selectedFilter}
            onFilterChange={setSelectedFilter}
          />
          <ReportStatusSelector
            selectedStatus={selectedStatus}
            onStatusChange={(value) =>
              setSelectedStatus(value as ReportStatusType)
            }
          />
        </div>
      </div>
      <div className="flex gap-5 items-center">
        <FilterChip
          text={selectedStatus}
          type="status"
          isSelected={selectedStatus === 'all'}
          onClick={() => setSelectedStatus('all')}
        />
        <FilterChip
          text={selectedFilter}
          type="reportType"
          isSelected={selectedFilter === 'all'}
          onClick={() => setSelectedFilter('all')}
        />
      </div>

      <div className="flex flex-col gap-5">
        {isLoading || isFetching ? (
          <div className="flex justify-center items-center p-4 h-[60vh]">
            <Loader />
          </div>
        ) : filteredReports?.length === 0 ? (
          <div className="flex justify-center items-center p-4">
            <EmptyState
              text={
                search === ''
                  ? t('dashboard.reportsScreen.emptyState')
                  : `${t('dashboard.reportsScreen.noResultsFor')} "${search}"`
              }
            />
          </div>
        ) : (
          filteredReports
        )}
      </div>
    </WeScreen>
  )
}
