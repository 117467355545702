import type {
  EReportType,
  ESocialCheckStatus,
} from '../Enterprise/Enterprise.types'

export interface WorkspaceTeam {
  _id: string
  name: string
  members: UserData[]
}

export interface Workspace {
  _id: string
  name: string
  teams: WorkspaceTeam[]
  allMembers: UserData[]
}
interface User {
  _id: string
  role: string
  firstname: string
  lastname: string
}

export interface UserData {
  _id: string
  userEmail: string
  user: User | null
  invite: InviteData | null
}

export interface InviteData {
  inviteId: string
  inviteStatus: string
  lastInviteUpdate: string
  role: string
}

export interface WorkspaceCredits {
  aiReportCredits: number
  aiAnalystReportCredits: number
  adverseMediaReportCredits: number
  assistedReportCredits: number
  facialMatchingCredits: number
}

export interface WorkspaceDetailsResponse {
  credits: WorkspaceCredits
  _id: string
  name: string
  teams: WorkspaceTeam[]
  allMembers: UserData[]
}

export interface WorkspaceCreditsResponse {
  credits: WorkspaceCredits
}

export interface WorkspaceDetailsRequest {
  workspaceId: string
}

export enum EReportFilter {
  Ai = 'ai_report',
  AiAnalyst = 'ai_analyst_report',
  AdverseMedia = 'adverse_media_report',
  Assited = 'assisted_report',
  FacialMatching = 'facial_matching',
}

export interface getWorkSpaceReportsRequest {
  id: string
  teamId: string
  page: number
  pageSize: number
  reportType?: EReportFilter
  status?: ESocialCheckStatus
  onlyUser?: boolean
}
interface RequesterInfo {
  id: string
  firstname: string
  lastname: string
  email: string
}

interface EnterpriseInfo {
  id: string
}

interface SubjectInputs {
  name: string
  nickname: string
  city: string
  state: string
  country: string
  email: string
  highSchool: string
  college: string
  phone: string
  company: string
  twitterUrl: string
  facebookUrl: string
  instagramUrl: string
  linkedInUrl: string
  tikTokUrl: string
  pinterestUrl: string
  ageOrDateOfBirth: string
  topics: string[]
  website: string
  industry: string
  stateLong: string
  countryLong: string
  cityShort: string
  cityLong: string
  stateShort: string
  countryShort: string
}

export interface ReportInfo {
  id: string
  status: ESocialCheckStatus
  inputs: SubjectInputs
  pdfLanguage: string
  pdfCoreFindings: boolean
  pdfSocialMediaAccounts: boolean
  pdfSentimentAnalysis: boolean
  pdfFlaggedBehaviors: boolean
  pdfPositivePosts: boolean
  pdfNegativePosts: boolean
  pdfNeutralPosts: boolean
  pdfWebSearchPosts: boolean
  pdfFocusedTopics: boolean
  reportDate: string
  pdfViewed: boolean
  pdf: string | null
  subjectId: string
  profileImgUrl: string | null
  hiddenDigitalReport: boolean
  requester: RequesterInfo
  enterprise: EnterpriseInfo
  reportType: EReportType
  complianceStatus: string
  lastUpdate: string
  complianceSigned: string | null
  disclosureSigned: string | null
  summarySigned: string | null
  dynamicSigned: string | null
  otherAuthorizationSigned: string | null
  wecheckScrapped: boolean
  workspace: string
  team: string
}

export interface getWorkSpaceReportsResponse {
  message: string
  info: ReportInfo[]
}

export interface GetWorkspacesReponse {
  credits: WorkspaceCredits
  workspace: Workspace[]
}

export interface WorkSpaceInfo {
  _id: string
  name: string
  teams: WorkspaceTeam[]
  credits: WorkspaceCredits
  allMembers: UserData[]
}

export interface WorkSpaceResponse {
  message: string
  info: {
    workspaces: WorkSpaceInfo[]
    allMembers: UserData[]
  }
  status: number
}

export interface AssignCreditsToWorkspaceRequest {
  workspaceId: string
  values: AssignCreditsValue[]
}

export interface AssignCreditsValue {
  quantity: number
  type: EReportType
}
export interface UpdateTeamData {
  id: string
  teamId: string
  name: string | undefined
}

export interface UpdateWorkspaceData {
  id: string
  name: string | undefined
}

export interface inviteMemberToATeamRequest {
  id: string
  email: string
  role: string
  teamId: string
}

export interface RemoveMemberFromTeamRequest {
  id: string
  teamId: string
  userId: string
}
