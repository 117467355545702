import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { InputsSection } from '@/components/SearchInput/InputsSection'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import {
  EUserPermission,
  EUserRole,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { clearTokens } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import { useListOfUseCasesQuery } from '@/store/api/NewSearch/NewSearch.query'
import {
  clearNewSearch,
  setIsPrefillForm,
} from '@/store/api/NewSearch/NewSearch.slice'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import {
  selectIsPending,
  selectItsDefaultState,
  selectWorkspaceCreditsFromStore,
} from '@/store/api/Users/Users.selector'

export const AiGeneratedReport = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { hasPermission } = usePermissions()
  hasPermission(EUserPermission.HANDLE_REPORTS, EUserRole.TEAM_ANALYST)

  const {
    data: userData,
    isError,
    isLoading,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data,
    }),
  })

  useEffect(() => {
    if (userData?.role === EUserRole.READER) {
      navigate(AppRoutes.reports)
    }
  }, [userData])

  const aiReportCredits =
    useSelector(selectWorkspaceCreditsFromStore)?.aiReportCredits ?? 0

  const isDefaultState = useSelector(selectItsDefaultState)

  const { hasHandleReports } = useRedirectBasedOnPermissions()

  const { isLoading: isLoadingUserData } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      isPending: selectIsPending(data),
    }),
  })

  const {
    data: useCasesData,
    isLoading: isLoadingUsesCasesData,
    isError: isErrorUseCasesData,
  } = useListOfUseCasesQuery()

  useEffect(() => {
    dispatch(clearTokens())
    dispatch(clearNewSearch())
    dispatch(setIsPrefillForm(false))
    if (!isDefaultState) {
      hasHandleReports()
    }
  }, [isLoadingUserData])

  return (
    <WeScreen
      isError={isError || isErrorUseCasesData}
      isLoading={isLoading || isLoadingUsesCasesData || isLoadingUserData}
      useHeader
      useNextButton={false}
      useHeaderBackButton={true}
      childrenCustomGap={48}
    >
      <div className="flex flex-row h-full items-center justify-center ">
        <div className="flex flex-col gap-6 w-1/2">
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-center gap-3">
              <p className="text-center text-neutralColor text-[32px] font-medium">
                {t('dashboard.home.title')}
              </p>
            </div>

            <p
              className="text-center text-neutralColor80 text-base font-normal"
              dangerouslySetInnerHTML={{
                __html: t('dashboard.home.subtitle'),
              }}
            />
          </div>
          <InputsSection
            hasSufficientCredits={aiReportCredits > 0}
            data={useCasesData}
          />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <p className="text-neutralColor80 text-xs font-medium">
          {t('dashboard.home.aiMistakes')}
        </p>
      </div>
    </WeScreen>
  )
}
