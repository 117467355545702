import { CloseSquare } from 'iconsax-react'
import { MultiSelect } from 'primereact/multiselect'
import React, { useState } from 'react'
import type { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useAppSelector } from '@/hooks/redux'
import { selectCreateTeamFirstStep } from '@/store/api/CreateTeam/CreateTeam.selector'
import { EEnterpriseRole } from '@/store/api/Enterprise/Enterprise.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { createTeamSecondStepSchemaType } from '@/utils/schemas'

interface CreateTeamSecondStepInputsProps {
  control: Control<createTeamSecondStepSchemaType>
  name: `email_${number}`
  value: string
  showDeleteIcon?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemove: () => void
  onRoleChange: (role: string) => void
  onTeamsChange: (teams: string[]) => void
}

export const CreateTeamSecondStepInputs = ({
  control,
  name,
  value,
  onChange,
  onRemove,
  showDeleteIcon,
  onRoleChange,
  onTeamsChange,
}: CreateTeamSecondStepInputsProps) => {
  const { t } = useTranslation()
  const createTeamSecondStepData = useAppSelector(selectCreateTeamFirstStep)
  const [selectedTeams, setSelectedTeams] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRole, setSelectedRole] = React.useState<string>('')

  const handleRoleSelect = (role: string) => {
    setSelectedRole(role)
    onRoleChange(role)
  }

  const handleTeamsSelect = (teams: string[]) => {
    setSelectedTeams(teams)
    onTeamsChange(teams)
  }

  return (
    <div>
      <FormField
        control={control}
        name={name as keyof createTeamSecondStepSchemaType}
        render={({ field, fieldState }) => (
          <FormItem>
            <div className="flex flex-col lg:grid lg:grid-cols-3 gap-4">
              <FormControl>
                <div>
                  <Input
                    {...field}
                    value={value}
                    onChange={(e) => {
                      field.onChange(e)
                      onChange(e)
                    }}
                    className={`bg-baseColorHighlight w-full ${
                      fieldState.error ? 'border-red-500' : ''
                    }`}
                    placeholder={t('dashboard.company.createTeam.exampleGmail')}
                  />
                  <FormMessage data-name={name} />
                </div>
              </FormControl>
              <MultiSelect
                value={selectedTeams}
                onChange={(e) => {
                  handleTeamsSelect(e.value)
                }}
                options={createTeamSecondStepData.createTeamArray}
                placeholder={t('dashboard.company.createTeam.selectTeams')}
                maxSelectedLabels={3}
                showSelectAll
                selectAllLabel="Select All"
                className="w-full h-[45px] flex items-center p-3 bg-baseColorHighlight rounded-lg text-sm"
                panelClassName="bg-baseColorHighlight text-neutralColor60 p-3 mt-1 rounded-lg"
                itemTemplate={(option) => (
                  <div className="flex items-center">
                    <Checkbox
                      checked={selectedTeams.includes(option)}
                      onChange={() => {
                        const newSelectedCities = selectedTeams.includes(option)
                          ? selectedTeams.filter((city) => city !== option)
                          : [...selectedTeams, option]
                        setSelectedTeams(newSelectedCities)
                      }}
                      className="mr-2"
                    />
                    <p className="ml-3">{option}</p>
                  </div>
                )}
              />

              <div className="flex flex-row">
                <Select onValueChange={handleRoleSelect}>
                  <SelectTrigger className="h-[45px]">
                    <SelectValue
                      placeholder={t('dashboard.company.createTeam.role')}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={EEnterpriseRole.SUPERVISOR}>
                      {t('dashboard.company.createTeam.supervisor')}
                    </SelectItem>
                    <SelectItem value={EEnterpriseRole.WORKSPACE_ADMIN}>
                      {t('dashboard.company.createTeam.workspaceAdmin')}
                    </SelectItem>
                    <SelectItem value={EEnterpriseRole.TEAM_MANAGER}>
                      {t('dashboard.company.createTeam.teamManager')}
                    </SelectItem>
                    <SelectItem value={EEnterpriseRole.TEAM_ANALYST}>
                      {t('dashboard.company.createTeam.teamAnalyst')}
                    </SelectItem>
                    <SelectItem value={EEnterpriseRole.READER}>
                      {t('dashboard.company.createTeam.reader')}
                    </SelectItem>
                  </SelectContent>
                </Select>

                {showDeleteIcon && (
                  <div className="">
                    <Button
                      className="flex mt-1"
                      variant="link"
                      onClick={onRemove}
                    >
                      <CloseSquare
                        width={18}
                        height={17}
                        variant="Bold"
                        color={weCheckPalette.neutralColor}
                      />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </FormItem>
        )}
      />
    </div>
  )
}
