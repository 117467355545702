import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { setAIReportFocusedTopicsGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  selectHasSocialMediaData,
  selectIsPrefillForm,
  selectSubjectInfo,
} from '@/store/api/NewSearch/NewSearch.selector'
import { setSubjectInfo } from '@/store/api/NewSearch/NewSearch.slice'
import type {
  prefillSearchFormSchemaTypes,
  searchFormSchemaTypes,
} from '@/utils/schemas'
import { prefillSearchFormSchema, searchFormSchema } from '@/utils/schemas'

import { PrefillSearchForm } from './Components/PrefillSearchForm'
import { ProgressBar } from './Components/ProgressBar'
import { SearchForm } from './Components/SearchForm'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const AdvancedSearch = () => {
  useHasCurrentStepToken('advancedSearch')
  const isMobile = useMobileMediaQuery()
  const subjectInfo = useAppSelector(selectSubjectInfo)
  const hasSocialMediaData = useSelector(selectHasSocialMediaData)
  const isPrefillForm = useAppSelector(selectIsPrefillForm)

  const form = useForm<
    typeof isPrefillForm extends true
      ? prefillSearchFormSchemaTypes
      : searchFormSchemaTypes
  >({
    resolver: zodResolver(
      isPrefillForm ? prefillSearchFormSchema : searchFormSchema,
    ),
    defaultValues: {
      firstName: '',
      lastName: '',
      countryLongName: '',
      email: '',
      middleName: '',
      alias: '',
      phoneNumber: '',
      dob: '',
      collage: '',
      companyName: '',
      cityLongName: '',
      stateLongName: '',
      image: null,
    },
    mode: 'onChange',
  })

  const { handleSubmit, formState, setValue } = form
  const { isValid } = formState

  const requiredFields: (keyof searchFormSchemaTypes)[] = [
    'firstName',
    'lastName',
    'countryLongName',
  ]
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  useMemo(() => {
    Object.keys(subjectInfo).forEach((key) => {
      const subjectInfoCast = subjectInfo as any
      if (subjectInfoCast[key] !== '') {
        setValue(key as keyof searchFormSchemaTypes, subjectInfoCast[key], {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      }
    })
  }, [subjectInfo, setValue])

  const onSubmit = (
    data: prefillSearchFormSchemaTypes | searchFormSchemaTypes,
  ) => {
    dispatch(setSubjectInfo(data))
    dispatch(
      setAIReportFocusedTopicsGuardToken({
        AIReportFocusedTopicsGuardToken: true,
      }),
    )
    navigate(AppRoutes.AIReportFocusedTopics)
  }

  const handleSkip = () => {
    dispatch(
      setAIReportFocusedTopicsGuardToken({
        AIReportFocusedTopicsGuardToken: true,
      }),
    )
    navigate(AppRoutes.AIReportFocusedTopics)
  }

  return (
    <WeScreen
      rightSideHeaderContent={
        <div className="flex gap-3">
          {isPrefillForm && (
            <Button
              variant={'secondary'}
              className="h-8 px-3 py-2"
              onClick={handleSkip}
            >
              <p className="text-xs font-medium text-neutralColor">
                {t('dashboard.newSearch.advancedSearch.skip')}
              </p>
            </Button>
          )}

          <Button
            variant={'default'}
            className="h-8 px-3 py-2"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid}
          >
            <p className="text-xs font-medium text-neutralColor">
              {t('dashboard.newSearch.advancedSearch.add')}
            </p>
          </Button>
        </div>
      }
      isDisabledNextButton={!isValid}
      childrenCustomPadding={0}
      useNextButton={false}
    >
      <div className="flex flex-col h-full justify-between max-lg:gap-4">
        <div className="flex flex-col gap-1 ">
          <p className="text-neutralColor text-2xl font-medium">
            {t('dashboard.newSearch.advancedSearch.title')}
          </p>
          <p className="text-neutralColor80 text-sm font-medium tracking-tight">
            {t('dashboard.newSearch.advancedSearch.subtitle')}
          </p>
        </div>
        <div className="flex flex-col lg:h-[80%]">
          <FormProvider {...form}>
            {isPrefillForm ? <PrefillSearchForm /> : <SearchForm />}
          </FormProvider>
        </div>
        {!isMobile && (
          <ProgressBar
            form={form}
            requiredFields={requiredFields}
            hasSocialMediaData={hasSocialMediaData}
          />
        )}
      </div>
    </WeScreen>
  )
}
