import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface CreateTeamSliceState {
  CreateTeamFirstStep: {
    workspaceId: string
    createTeamArray: string[]
  }
  CreateTeamSecondStep: {
    members: {
      email: string
      teamWhichBelong: string[]
      role: string
    }[]
  }
}

export const createTeamInitialState: CreateTeamSliceState = {
  CreateTeamFirstStep: {
    workspaceId: '',
    createTeamArray: [],
  },
  CreateTeamSecondStep: {
    members: [],
  },
}

const createTeamSlice = createSlice({
  name: 'createTeam',
  initialState: createTeamInitialState,
  reducers: {
    setCreateTeamFirstStep: (state, action) => {
      const TeamSecond = action.payload
      state.CreateTeamFirstStep = { ...TeamSecond }
    },
    setCreateTeamSecondStep: (
      state,
      action: PayloadAction<{
        email: string
        teamWhichBelong: string[]
        role: string
      }>,
    ) => {
      const newMember = {
        email: action.payload.email,
        teamWhichBelong: action.payload.teamWhichBelong,
        role: action.payload.role,
      }
      state.CreateTeamSecondStep.members.push(newMember)
    },

    clearCreateTeamState: (state) => {
      state.CreateTeamFirstStep = createTeamInitialState.CreateTeamFirstStep
      state.CreateTeamSecondStep = createTeamInitialState.CreateTeamSecondStep
    },
  },
})
export const {
  setCreateTeamFirstStep,
  setCreateTeamSecondStep,
  clearCreateTeamState,
} = createTeamSlice.actions

export default createTeamSlice.reducer
