import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'

import { EUserPermission, EUserRole } from './usePermissions/permissions.type'
import { usePermissions } from './usePermissions/usePermissions'

export const useRedirectBasedOnPermissions = () => {
  const navigate = useNavigate()
  const { hasPermission } = usePermissions()

  const hasHandleBillingRedirect = () => {
    if (
      !hasPermission(EUserPermission.HANDLE_BILLING, EUserRole.WORKSPACE_ADMIN)
    ) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleCreditsRedirect = () => {
    if (
      !hasPermission(EUserPermission.HANDLE_CREDITS, EUserRole.WORKSPACE_ADMIN)
    ) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleEnterprisesRedirect = () => {
    if (
      !hasPermission(
        EUserPermission.HANDLE_ENTERPRISES,
        EUserRole.WORKSPACE_ADMIN,
      )
    ) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleUsersRedirect = () => {
    if (
      !hasPermission(EUserPermission.HANDLE_USERS, EUserRole.WORKSPACE_ADMIN)
    ) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasViewReportsRedirect = () => {
    if (!hasPermission(EUserPermission.VIEW_REPORTS, EUserRole.READER)) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleReportsRedirect = () => {
    if (
      !hasPermission(EUserPermission.HANDLE_REPORTS, EUserRole.TEAM_ANALYST)
    ) {
      navigate(AppRoutes.reports)
    }
  }

  const hasHandleTopicsRedirect = () => {
    if (!hasPermission(EUserPermission.HANDLE_TOPICS, EUserRole.TEAM_ANALYST)) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleUseCasesRedirect = () => {
    if (
      !hasPermission(EUserPermission.HANDLE_USE_CASES, EUserRole.TEAM_ANALYST)
    ) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleWorkspacesRedirect = () => {
    if (
      !hasPermission(
        EUserPermission.HANDLE_WORKSPACES,
        EUserRole.WORKSPACE_ADMIN,
      )
    ) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleTeamsRedirect = () => {
    if (!hasPermission(EUserPermission.HANDLE_TEAMS, EUserRole.TEAM_MANAGER)) {
      navigate(AppRoutes.dashboard)
    }
  }

  const hasHandleMembersRedirect = () => {
    if (
      !hasPermission(EUserPermission.HANDLE_MEMBERS, EUserRole.TEAM_MANAGER)
    ) {
      navigate(AppRoutes.dashboard)
    }
  }

  return {
    hasHandleBilling: hasHandleBillingRedirect,
    hasHandleCredits: hasHandleCreditsRedirect,
    hasHandleEnterprises: hasHandleEnterprisesRedirect,
    hasHandleUsers: hasHandleUsersRedirect,
    hasViewReports: hasViewReportsRedirect,
    hasHandleReports: hasHandleReportsRedirect,
    hasHandleTopics: hasHandleTopicsRedirect,
    hasHandleUseCases: hasHandleUseCasesRedirect,
    hasHandleWorkspaces: hasHandleWorkspacesRedirect,
    hasHandleTeams: hasHandleTeamsRedirect,
    hasHandleMembers: hasHandleMembersRedirect,
  }
}
