import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  EUserPermission,
  EUserRole,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'

import { DobleAvatarPlusNameCard } from './DobleAvatarPlusNameCard'
import { RenamePopUp } from './RenamePopUp'

export enum EWorkSpaceCardType {
  TEAM = 'team',
  WORKSPACE = 'workspace',
  SETTINGS = 'settings',
}

interface IWorkSpaces {
  title?: string
  type?: EWorkSpaceCardType
  teamNumber?: number
  nameOne?: string
  nameTwo?: string
  primaryButtonText?: string
  primaryButtonOnClick?: () => void
  secondaryButtonText?: string
  secondaryButtonOnClick?: () => void
  isActionButtons?: boolean
  leftButtonText?: string
  workspaceId?: string
  teamId?: string
}

export const WorkSpaceCard = ({
  title,
  type,
  teamNumber,
  nameOne,
  nameTwo,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  workspaceId,
  teamId,
}: IWorkSpaces) => {
  const { t } = useTranslation()
  const [isOpenRename, setIsOpenRename] = useState(false)
  const { hasPermission } = usePermissions()
  return (
    <div
      className={`bg-baseColor flex flex-col p-4 gap-4 rounded-lg ${
        type === EWorkSpaceCardType.SETTINGS && 'lg:h-[120px] h-[140px]'
      }`}
    >
      <div className="flex flex-row justify-between">
        <p className="text-neutralColor text-xl">{title}</p>
        {type === EWorkSpaceCardType.SETTINGS && (
          <Button variant="outline" onClick={() => setIsOpenRename(true)}>
            {t('dashboard.company.workspaceCard.rename')}
          </Button>
        )}
        {type === EWorkSpaceCardType.WORKSPACE && (
          <p className="text-neutralColor60 text-base">
            {`${teamNumber} ${t('dashboard.company.workspaceCard.teams')}`}
          </p>
        )}
      </div>

      <div className="flex flex-row items-center">
        <DobleAvatarPlusNameCard
          nameShowInOthers={nameOne}
          nameForInitials={nameTwo}
          countElements={teamNumber}
        />
      </div>

      {type !== EWorkSpaceCardType.SETTINGS && (
        <div className="flex flex-row gap-3 justify-end">
          {hasPermission(
            EUserPermission.HANDLE_WORKSPACES,
            EUserRole.CO_OWNER,
          ) &&
            secondaryButtonOnClick && (
              <Button variant="secondary" onClick={secondaryButtonOnClick}>
                {secondaryButtonText}
              </Button>
            )}
          {hasPermission(
            EUserPermission.HANDLE_TEAMS,
            EUserRole.TEAM_MANAGER,
          ) &&
            primaryButtonOnClick && (
              <Button variant="default" onClick={primaryButtonOnClick}>
                {primaryButtonText}
              </Button>
            )}
        </div>
      )}

      <RenamePopUp
        oldName={title}
        workspaceId={workspaceId}
        teamId={teamId}
        isOpen={isOpenRename}
        setIsOpen={setIsOpenRename}
      />
    </div>
  )
}
