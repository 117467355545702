import { createSlice } from '@reduxjs/toolkit'

export interface newSearchGuardsTokensSliceState {
  initialReportSetup: {
    initialReportSetupToken: boolean
  }
  consent: {
    consentToken: boolean
  }
  reportSetup: {
    reportSetupGuardToken: boolean
  }
  firstStep: {
    firstStepGuardToken: boolean
  }
  subjectInfoStep: {
    subjectInfoStepGuardToken: boolean
  }
  companyInfoStep: {
    companyInfoStepGuardToken: boolean
  }
  finalStep: {
    FinalStepGuardToken: boolean
  }
  searchResults: {
    searchResultsGuardToken: boolean
  }
  advancedSearch: {
    advancedSearchGuardToken: boolean
  }
  generatingReport: {
    generatingReportGuardToken: boolean
  }
  AIReportFocusedTopics: {
    AIReportFocusedTopicsGuardToken: boolean
  }
}

export const newSearchGuardsTokenInitialState: newSearchGuardsTokensSliceState =
  {
    initialReportSetup: {
      initialReportSetupToken: false,
    },
    consent: {
      consentToken: false,
    },
    reportSetup: {
      reportSetupGuardToken: false,
    },
    firstStep: {
      firstStepGuardToken: false,
    },
    subjectInfoStep: {
      subjectInfoStepGuardToken: false,
    },
    companyInfoStep: {
      companyInfoStepGuardToken: false,
    },
    finalStep: {
      FinalStepGuardToken: false,
    },
    searchResults: {
      searchResultsGuardToken: false,
    },
    advancedSearch: {
      advancedSearchGuardToken: false,
    },
    generatingReport: {
      generatingReportGuardToken: false,
    },
    AIReportFocusedTopics: {
      AIReportFocusedTopicsGuardToken: false,
    },
  }

export const newSearchGuardTokenSlice = createSlice({
  name: 'newSearchGuardToken',
  initialState: newSearchGuardsTokenInitialState,
  reducers: {
    setInitialReportSetupToken: (state, action) => {
      const initialReportSetupToken = action.payload
      state.initialReportSetup.initialReportSetupToken = {
        ...initialReportSetupToken,
      }
    },
    setConsentToken: (state, action) => {
      const consentToken = action.payload
      state.consent.consentToken = { ...consentToken }
    },
    setReportSetupToken: (state, action) => {
      const reportSetupGuardToken = action.payload
      state.reportSetup.reportSetupGuardToken = { ...reportSetupGuardToken }
    },
    setFirstStepGuardToken: (state, action) => {
      const firstStepGuardToken = action.payload
      state.firstStep.firstStepGuardToken = { ...firstStepGuardToken }
    },
    setSubjectInfoStepGuardToken: (state, action) => {
      const subjectInfoStepGuardToken = action.payload
      state.subjectInfoStep.subjectInfoStepGuardToken = {
        ...subjectInfoStepGuardToken,
      }
    },
    setCompanyInfoStepGuardToken: (state, action) => {
      const companyInfoStepGuardToken = action.payload
      state.companyInfoStep.companyInfoStepGuardToken = {
        ...companyInfoStepGuardToken,
      }
    },
    setFinalStepGuardToken: (state, action) => {
      const finalStepGuardToken = action.payload
      state.finalStep.FinalStepGuardToken = { ...finalStepGuardToken }
    },
    setSearchResultsGuardToken: (state, action) => {
      const searchResultsGuardToken = action.payload
      state.searchResults.searchResultsGuardToken = {
        ...searchResultsGuardToken,
      }
    },
    setAdvancedSearchGuardToken: (state, action) => {
      const advancedSearchGuardToken = action.payload
      state.advancedSearch.advancedSearchGuardToken = {
        ...advancedSearchGuardToken,
      }
    },
    setGeneratingReportGuardToken: (state, action) => {
      const generatingReportGuardToken = action.payload
      state.generatingReport.generatingReportGuardToken = {
        ...generatingReportGuardToken,
      }
    },
    setAIReportFocusedTopicsGuardToken: (state, action) => {
      const AIReportFocusedTopicsGuardToken = action.payload
      state.AIReportFocusedTopics.AIReportFocusedTopicsGuardToken = {
        ...AIReportFocusedTopicsGuardToken,
      }
    },
    clearTokens: (state) => {
      state = newSearchGuardsTokenInitialState
      return state
    },
  },
})

export const {
  setInitialReportSetupToken,
  setConsentToken,
  setReportSetupToken,
  setFirstStepGuardToken,
  setSubjectInfoStepGuardToken,
  setCompanyInfoStepGuardToken,
  setFinalStepGuardToken,
  setAdvancedSearchGuardToken,
  setGeneratingReportGuardToken,
  setSearchResultsGuardToken,
  setAIReportFocusedTopicsGuardToken,
  clearTokens,
} = newSearchGuardTokenSlice.actions

export default newSearchGuardTokenSlice.reducer
