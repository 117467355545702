export const genericLoadingMessages = [
  'dashboard.newSearch.generatingReport.loading.generic.searching',
  'dashboard.newSearch.generatingReport.loading.generic.scanning',
  'dashboard.newSearch.generatingReport.loading.generic.gathering',
  'dashboard.newSearch.generatingReport.loading.generic.analyzing',
  'dashboard.newSearch.generatingReport.loading.generic.processing',
  'dashboard.newSearch.generatingReport.loading.generic.identifying',
  'dashboard.newSearch.generatingReport.loading.generic.evaluating',
  'dashboard.newSearch.generatingReport.loading.generic.reviewing',
  'dashboard.newSearch.generatingReport.loading.generic.detecting',
  'dashboard.newSearch.generatingReport.loading.generic.combining',
  'dashboard.newSearch.generatingReport.loading.generic.verifying',
  'dashboard.newSearch.generatingReport.loading.generic.crossReferencing',
  'dashboard.newSearch.generatingReport.loading.generic.processingInputs',
  'dashboard.newSearch.generatingReport.loading.generic.refining',
]

export const scrapingSocialMediaMessages = [
  'dashboard.newSearch.generatingReport.loading.scraping.analyzing',
  'dashboard.newSearch.generatingReport.loading.scraping.analyzing',
  'dashboard.newSearch.generatingReport.loading.scraping.scanning',
  'dashboard.newSearch.generatingReport.loading.scraping.scanning',
  'dashboard.newSearch.generatingReport.loading.scraping.processing',
  'dashboard.newSearch.generatingReport.loading.scraping.processing',
  'dashboard.newSearch.generatingReport.loading.scraping.examining',
  'dashboard.newSearch.generatingReport.loading.scraping.examining',
  'dashboard.newSearch.generatingReport.loading.scraping.checking',
  'dashboard.newSearch.generatingReport.loading.scraping.checking',
  'dashboard.newSearch.generatingReport.loading.scraping.exploring',
  'dashboard.newSearch.generatingReport.loading.scraping.exploring',
]

export const analyzingMessages = [
  'dashboard.newSearch.generatingReport.loading.analyzing.collecting',
  'dashboard.newSearch.generatingReport.loading.analyzing.scanning',
  'dashboard.newSearch.generatingReport.loading.analyzing.evaluating',
  'dashboard.newSearch.generatingReport.loading.analyzing.processing',
  'dashboard.newSearch.generatingReport.loading.analyzing.uncovering',
  'dashboard.newSearch.generatingReport.loading.analyzing.mapping',
  'dashboard.newSearch.generatingReport.loading.analyzing.studying',
  'dashboard.newSearch.generatingReport.loading.analyzing.highlighting',
  'dashboard.newSearch.generatingReport.loading.analyzing.exploring',
  'dashboard.newSearch.generatingReport.loading.analyzing.reviewing',
  'dashboard.newSearch.generatingReport.loading.analyzing.examining',
  'dashboard.newSearch.generatingReport.loading.analyzing.focusing',
  'dashboard.newSearch.generatingReport.loading.analyzing.breaking',
]
