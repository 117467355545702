import { ArrowRight } from 'iconsax-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AiGeneratedReportImage from '@/assets/home/aIGeneratedReport.png'
import FacialMatching from '@/assets/home/facialMatching.png'
import SubjectSearchWithAnalyst from '@/assets/home/subjectSearchWithAnalyst.png'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogFooter,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { Chip } from '../Chip/Chip'

export const GetStartedCard = ({
  title,
  text,
  aiReportCredits = 0,
  aiAnalystReportCredits = 0,
  adverseMediaReportCredits = 0,
  assistedReportCredits = 0,
  facialMatchingCredits = 0,
  onClick,
  useChip,
}: {
  title: string
  text: string
  aiReportCredits?: number
  aiAnalystReportCredits?: number
  adverseMediaReportCredits?: number
  assistedReportCredits?: number
  facialMatchingCredits?: number
  onClick: () => void
  useChip?: boolean
}) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()

  const { t } = useTranslation()

  return (
    <Card className="lg:h-[500px] md:w-[350px] p-4 bg-baseColorHighlight border-0 shadow-none flex flex-col rounded-2xl gap-3 justify-between relative">
      {!isMobile && (
        <div>
          {title === t('dashboard.home.aIGeneratedReport') && (
            <img
              src={AiGeneratedReportImage}
              alt="AiGeneratedReportImage"
              className="w-[319px] h-[338px]"
            />
          )}
          {title === t('dashboard.home.socialAnalysis') && (
            <img
              src={SubjectSearchWithAnalyst}
              alt="SubjectSearchWithAnalyst"
              className="w-[319px] h-[338px]"
            />
          )}
          {title === t('dashboard.home.facialMatching') && (
            <img
              src={FacialMatching}
              alt="FacialMatching"
              className="w-[319px] h-[338px]"
            />
          )}
        </div>
      )}

      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center gap-4">
          <p className="text-neutralColor text-xl font-medium">{title}</p>
        </div>

        <p
          className="h-11 text-neutralColor60 text-base font-normal"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
      <div
        className={`flex justify-end items-end ${
          useChip &&
          (isMobile || isSmallMobile) &&
          'flex flex-row justify-between items-center'
        }`}
      >
        {useChip && (isMobile || isSmallMobile) && (
          <div className="inline-flex">
            <Chip green text="Popular" />
          </div>
        )}
        {aiReportCredits > 0 ||
        aiAnalystReportCredits > 0 ||
        adverseMediaReportCredits > 0 ||
        assistedReportCredits > 0 ||
        facialMatchingCredits > 0 ? (
          <Button
            onClick={onClick}
            variant={'default'}
            size={'icon'}
            className="w-8 h-8 p-1.5 rounded-full"
          >
            <ArrowRight
              className="w-7 h-7"
              color={weCheckPalette.staticWhite}
            />
          </Button>
        ) : (
          <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
              <Button
                variant={'default'}
                size={'icon'}
                className="w-8 h-8 p-1.5 rounded-full"
              >
                <ArrowRight
                  className="w-7 h-7"
                  color={weCheckPalette.staticWhite}
                />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="flex flex-col w-6/12 max-md:w-11/12">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-textNormal text-[32px] font-medium tracking-wide max-md:text-left max-md:text-[30px]">
                  {t('dashboard.home.insufficientCredits')}
                </AlertDialogTitle>

                <AlertDialogDescription className="text-textNormal text-base font-medium tracking-tight max-md:text-left">
                  {t('dashboard.home.insufficientCreditsText')}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter className="flex flex-col gap-3 lg:flex-row lg:gap-0">
                <Button
                  type="submit"
                  variant="secondary"
                  onClick={() => setOpen(false)}
                >
                  {t('dashboard.home.cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="default"
                  onClick={() => navigate(AppRoutes.contactUs)}
                >
                  {t('dashboard.home.contactSales')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </div>
    </Card>
  )
}
