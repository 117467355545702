import { Magicpen } from 'iconsax-react'
import React from 'react'

import { weCheckPalette } from '@/utils/palette/weCheckPalette'

interface CoreFindingsCardTypes {
  isFirstCard: boolean
  title: string
  text: string
}

export const CoreFindingsCard = ({
  title,
  text,
  isFirstCard,
}: CoreFindingsCardTypes) => {
  return (
    <>
      {!isFirstCard && (
        <div className="border-t-[0.5px] border-neutralColor30 my-4" />
      )}
      <div className="flex rounded-xl flex-col justify-start items-start gap-2">
        <div className="flex w-full gap-3">
          <p className="text-textNormal text-xl font-medium tracking-wide">
            {title}
          </p>
          {isFirstCard && (
            <div className="flex flex-row justify-end">
              <Magicpen
                size={20}
                variant="Bold"
                color={weCheckPalette.dynamicBlue}
              />
            </div>
          )}
        </div>

        <p className="text-neutralColor80 text-base font-normal">{text}</p>
      </div>
    </>
  )
}
