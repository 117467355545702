import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'
import type { summary } from '@/store/api/Person/Person.types'

import { CoreFindingsCard } from './CoreFindingsCard'

interface CoreFindingsCardTypes {
  Summary: summary | undefined
}

export const CoreFindingsSection = ({ Summary }: CoreFindingsCardTypes) => {
  const { t } = useTranslation()
  const cardsData = [
    {
      title: t('dashboard.reportGeneralInfo.aim'),
      text: Summary?.executiveAim !== null ? Summary?.executiveAim : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.executiveSummary'),
      text:
        Summary?.executiveSummary !== null
          ? Summary?.executiveSummary
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.riskIndicators'),
      text:
        Summary?.riskIndicators !== null ? Summary?.riskIndicators : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.sentimentAnalysis'),
      text:
        Summary?.sentimentAnalysis !== null
          ? Summary?.sentimentAnalysis
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.redFlags'),
      text: Summary?.redFlags !== null ? Summary?.redFlags : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.webNewsFindings'),
      text:
        Summary?.webNewsFindings !== null
          ? Summary?.webNewsFindings
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.engagementMetrics'),
      text:
        Summary?.personalEngagement !== null
          ? Summary?.personalEngagement
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.sentimentAnalysis'),
      text:
        Summary?.personalSentiment !== null
          ? Summary?.personalSentiment
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.audienceResponse'),
      text:
        Summary?.strategyResponse !== null
          ? Summary?.strategyResponse
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.onlineBehavior'),
      text:
        Summary?.digitalBehavior !== null
          ? Summary?.digitalBehavior
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.contentDiversity'),
      text:
        Summary?.strategyDiversity !== null
          ? Summary?.strategyDiversity
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.thematicAnalysis'),
      text:
        Summary?.strategyThemes !== null ? Summary?.strategyThemes : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.influence'),
      text:
        Summary?.digitalInfluence !== null
          ? Summary?.digitalInfluence
          : undefined,
    },
    {
      title: t('dashboard.reportGeneralInfo.improvements'),
      text:
        Summary?.recommendationsImprovements !== null
          ? Summary?.recommendationsImprovements
          : undefined,
    },

    {
      title: t('dashboard.reportGeneralInfo.conclusion'),
      text:
        Summary?.recommentationsConclusion !== null
          ? Summary?.recommentationsConclusion
          : undefined,
    },
  ].filter((item) => item.text !== undefined)
  return (
    <Card className="flex flex-col p-4 bg-baseColorHighlight rounded-2xl">
      {cardsData.map((data, index) => (
        <CoreFindingsCard
          isFirstCard={index === 0}
          title={data.title}
          text={data.text || ''}
          key={index}
        />
      ))}
    </Card>
  )
}
