import React from 'react'
import { useTranslation } from 'react-i18next'

interface SocialMetricsProps {
  following: number
  followers: number
  posts: number
  platform?: string
}

export const SocialMetrics = ({
  following,
  followers,
  posts,
  platform,
}: SocialMetricsProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row gap-8">
      {platform === 'WebSearch' ? (
        <>
          <div className="flex gap-1 max-md:flex-col max-md:items-center">
            <p className="text-textNormal text-base font-bold tracking-tight">
              {posts}
            </p>
            <p className="text-textAccent text-base font-normal tracking-tight">
              {t('dashboard.header.posts')}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-3 max-md:flex-col max-md:items-center">
            <p className="text-neutralColor text-xl font-bold">{posts}</p>
            <p className="text-neutralColor80 text-xl font-medium">
              {t('dashboard.header.posts')}
            </p>
          </div>
          <div className="flex gap-3 max-md:flex-col max-md:items-center">
            <p className="text-neutralColor text-xl font-bold">{followers}</p>
            <p className="text-neutralColor80 text-xl font-medium">
              {t('dashboard.header.followers')}
            </p>
          </div>
          <div className="flex gap-3 max-md:flex-col max-md:items-center">
            <p className="text-neutralColor text-xl font-bold">{following}</p>
            <p className="text-neutralColor80 text-xl font-medium">
              {t('dashboard.header.following')}
            </p>
          </div>
        </>
      )}
    </div>
  )
}
