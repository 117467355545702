import { PlusIcon } from '@radix-ui/react-icons'
import { useMask } from '@react-input/mask'
import { Trash } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Autocomplete from 'react-google-autocomplete'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'

import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useAppSelector } from '@/hooks/redux'
import { SocialMediaSelector } from '@/pages/Reports/Components/SocialMediaSelector'
import {
  selectIsPrefillForm,
  selectSubjectInfo,
} from '@/store/api/NewSearch/NewSearch.selector'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import Env from '../../../env/env'

export const PrefillSearchForm = () => {
  const { watch, setValue, control } = useFormContext()
  const key = Env.GOOGLE_KEY as string
  const { t } = useTranslation()
  const subjectInfo = useAppSelector(selectSubjectInfo)
  const isPrefillForm = useAppSelector(selectIsPrefillForm)

  const dateFormat = useMask({
    mask: '__/__/____',
    replacement: { _: /\d/ },
  })

  const disabledImageField = isPrefillForm && subjectInfo.image !== null

  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  useEffect(() => {
    const image = watch('image')

    if (typeof image === 'string') {
      setPreviewUrl(image)
    } else if (!image) {
      setPreviewUrl(null)
    }
  }, [watch('image')])

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]

      setValue('image', file, { shouldValidate: true, shouldDirty: true })

      const fileUrl = URL.createObjectURL(file)
      setPreviewUrl(fileUrl)
    }
  }

  const deleteImage = () => {
    setValue('image', null, { shouldValidate: true, shouldDirty: true })
    setPreviewUrl(null)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    multiple: false,
    maxSize: 3000000,
    disabled: disabledImageField,
  })

  return (
    <div className="flex flex-col gap-3 flex-1">
      {/* Image Section */}
      <div className="flex lg:flex-row flex-col self-center">
        <FormField
          control={control}
          name="image"
          render={() => (
            <FormItem className="flex items-center ">
              <div
                {...getRootProps()}
                className={`rounded-full flex flex-col items-center gap-4 mr-2 ${
                  !previewUrl
                    ? 'border border-neutralColor30 border-dashed w-12 h-12 justify-center'
                    : 'w-min h-min'
                } ${
                  disabledImageField ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
              >
                <input {...getInputProps()} />
                {previewUrl ? (
                  <div className="relative w-12 h-12">
                    <img
                      alt="Preview"
                      src={previewUrl}
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                ) : (
                  <PlusIcon />
                )}
              </div>

              <div className="flex items-center gap-1">
                <FormLabel className="text-base font-medium">
                  {t('dashboard.newSearch.stepSubjectInfo.picture')}
                </FormLabel>
              </div>

              {previewUrl && !disabledImageField && (
                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    deleteImage()
                  }}
                  size="icon"
                  variant="ghost"
                  className="hover:bg-transparent"
                >
                  <Trash
                    color={weCheckPalette.dynamicRed}
                    size={18}
                    variant="Bold"
                  />
                </Button>
              )}

              <FormMessage data-name="image" />
            </FormItem>
          )}
        />
      </div>

      {/* Personal Information Section */}
      <div className="flex flex-col gap-5 mt-4">
        <p className="text-base font-medium mb-[-10px]">
          {t('dashboard.newSearch.stepSubjectInfo.personalInformation')}
        </p>

        {/* Personal Information First Row */}
        <div className="flex lg:flex-row flex-col gap-6">
          {/* First Name */}
          <FormField
            control={control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  <span className="text-neutralColor">
                    {t('dashboard.newSearch.stepSubjectInfo.firstName')}
                    <span className="text-neutralColor80 ml-1">
                      {t('dashboard.newSearch.stepSubjectInfo.required')}
                    </span>
                  </span>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage data-name="firstName" />
              </FormItem>
            )}
          />

          {/* Middle Name */}
          <FormField
            control={control}
            name="middleName"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  <span className="text-neutralColor">
                    {t('dashboard.newSearch.stepSubjectInfo.middleName')}
                  </span>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage data-name="middleName" />
              </FormItem>
            )}
          />

          {/* Last Name */}
          <FormField
            control={control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  <span className="text-neutralColor">
                    {t('dashboard.newSearch.stepSubjectInfo.lastName')}
                    <span className="text-neutralColor80 ml-1">
                      {t('dashboard.newSearch.stepSubjectInfo.required')}
                    </span>
                  </span>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage data-name="lastName" />
              </FormItem>
            )}
          />

          {/* Alias */}
          <FormField
            control={control}
            name="alias"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel className="text-neutralColor">
                  {t('dashboard.newSearch.stepSubjectInfo.alias')}
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage data-name="alias" />
              </FormItem>
            )}
          />
        </div>

        {/* Personal Information Second Row */}
        <div className="flex flex-col md:flex-row gap-6 mt-[-10px]">
          {/* Email */}
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel className="text-neutralColor">
                  {t('dashboard.newSearch.stepSubjectInfo.email')}
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage data-name="email" />
              </FormItem>
            )}
          />

          {/* Phone Number */}
          <FormField
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-neutralColor">
                  {t('dashboard.newSearch.stepSubjectInfo.phoneNumber')}
                </FormLabel>
                <FormControl>
                  <PhoneInputWithCountry
                    className="w-full h-11 border border-neutralColor30 bg-baseColorHighlight rounded-md text-sm shadow-sm px-3 py-1 tex"
                    {...field}
                    name="phoneNumber"
                    defaultCountry="US"
                    limitMaxLength={true}
                    control={control}
                  />
                </FormControl>
                <FormMessage data-name="phoneNumber" />
              </FormItem>
            )}
          />

          {/* Date of Birth */}
          <FormField
            control={control}
            name="dob"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  <span className="text-neutralColor">
                    {t('dashboard.newSearch.stepSubjectInfo.ageApprox.')}
                  </span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="" ref={dateFormat} />
                </FormControl>
                <FormMessage data-name="dob" />
              </FormItem>
            )}
          />
        </div>
      </div>

      {/* Professional Information Section */}
      <div className="flex flex-col gap-5 mt-6">
        <p className="text-base font-medium mb-[-10px]">
          {t('dashboard.newSearch.stepSubjectInfo.professionalInformation')}
        </p>

        {/* Professional Information First Row */}
        <div className="flex lg:flex-row flex-col gap-6">
          {/* Company Name */}
          <FormField
            control={control}
            name="companyName"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  {t('dashboard.newSearch.stepSubjectInfo.company')}
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage data-name="companyName" />
              </FormItem>
            )}
          />

          {/* College */}
          <FormField
            control={control}
            name="collage"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-neutralColor">
                  {t('dashboard.newSearch.stepSubjectInfo.college')}
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage data-name="collage" />
              </FormItem>
            )}
          />
        </div>
      </div>

      {/* Location Section */}
      <div className="flex flex-col gap-5 mt-4">
        <p className="text-base font-medium mb-[-10px]">
          {t('dashboard.newSearch.stepSubjectInfo.location')}
        </p>

        {/* Location First Row */}
        <div className="flex lg:flex-row flex-col gap-6 mb-2">
          {/* Country */}
          <FormField
            control={control}
            name="countryLongName"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  <span className="text-neutralColor">
                    {t('dashboard.newSearch.stepSubjectInfo.country')}
                    <span className="text-neutralColor80 ml-1">
                      {t('dashboard.newSearch.stepSubjectInfo.required')}
                    </span>
                  </span>
                </FormLabel>
                <FormControl>
                  <Autocomplete
                    {...field}
                    placeholder={t(
                      'dashboard.newSearch.stepSubjectInfo.enterCountry',
                    )}
                    language="en"
                    className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    apiKey={key}
                    options={{ types: ['(regions)'] }}
                    onPlaceSelected={(place) => {
                      setValue(
                        'countryLongName',
                        place.address_components[0].long_name
                          ? place.address_components[0].long_name
                          : '',
                        { shouldValidate: true, shouldDirty: true },
                      )
                      setValue(
                        'countryShortName',
                        place.address_components[0].short_name
                          ? place.address_components[0].short_name
                          : '',
                      )
                    }}
                  />
                </FormControl>
                <FormMessage data-name="countryLongName" />
              </FormItem>
            )}
          />

          {/* State */}
          <FormField
            control={control}
            name="stateLongName"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  {t('dashboard.newSearch.stepSubjectInfo.state')}
                </FormLabel>
                <FormControl>
                  <Autocomplete
                    {...field}
                    placeholder={t(
                      'dashboard.newSearch.stepSubjectInfo.enterState',
                    )}
                    language="en"
                    className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-primaryColor5 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    apiKey={key}
                    options={{ types: ['(regions)'] }}
                    onPlaceSelected={(place) => {
                      setValue(
                        'stateLongName',
                        place.address_components[0].long_name,
                      )
                      setValue(
                        'countryShortName',
                        place.address_components[0].short_name
                          ? place.address_components[0].short_name
                          : '',
                      )
                    }}
                  />
                </FormControl>
                <FormMessage data-name="stateLongName" />
              </FormItem>
            )}
          />

          {/* City */}
          <FormField
            control={control}
            name="cityLongName"
            render={({ field }) => (
              <FormItem className="w-full h-20">
                <FormLabel>
                  {t('dashboard.newSearch.stepSubjectInfo.city')}
                </FormLabel>
                <FormControl>
                  <Autocomplete
                    {...field}
                    language="en"
                    placeholder={t(
                      'dashboard.newSearch.stepSubjectInfo.enterCity',
                    )}
                    className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-primaryColor5 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    apiKey={key}
                    options={{ types: ['(cities)'] }}
                    onPlaceSelected={(place) => {
                      setValue(
                        'cityLongName',
                        place.address_components[0].long_name,
                      )
                      setValue(
                        'countryShortName',
                        place.address_components[0].short_name
                          ? place.address_components[0].short_name
                          : '',
                      )
                    }}
                  />
                </FormControl>
                <FormMessage data-name="cityLongName" />
              </FormItem>
            )}
          />
        </div>
      </div>

      {/* Social Media Section */}
      <div className="flex flex-col gap-2 mt-4">
        <p className="text-base font-medium mb-[-10px]">
          {t('dashboard.newSearch.stepSubjectInfo.socialMediaAccounts')}
        </p>
        <SocialMediaSelector />
      </div>
    </div>
  )
}
