import {
  ESocialMedias,
  SocialMediaPatterns,
} from '@/constants/sanitizeSocialMediaConstants'

export const identifySocialMedia = (url: string): ESocialMedias | undefined => {
  for (const [platform, pattern] of Object.entries(SocialMediaPatterns)) {
    if (pattern.test(url)) {
      return ESocialMedias[platform as keyof typeof ESocialMedias]
    }
  }
  return undefined
}
