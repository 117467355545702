import { ArrowLeft2, CloseSquare } from 'iconsax-react'
import React from 'react'

import { Button } from '@/components/ui/button'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const PopUpTopHeader = ({
  text,
  onClick,
  setIsOpen,
}: {
  text: any
  onClick?: () => void
  setIsOpen?: any
}) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <Button
        variant="link"
        className="flex justify-start gap-3 p-0"
        onClick={onClick}
      >
        <ArrowLeft2 size="16" color={weCheckPalette.neutralColor} />
        <p className="text-base">{text}</p>
      </Button>

      <Button
        onClick={() => setIsOpen && setIsOpen(false)}
        variant="link"
        className="text-base"
      >
        <CloseSquare
          width={18}
          height={17}
          variant="Bold"
          color={weCheckPalette.neutralColor}
        />
      </Button>
    </div>
  )
}
