import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'sonner'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useFindSubjectMutation } from '@/store/api/NewSearch/NewSearch.query'
import { selectSearchResultsInfo } from '@/store/api/NewSearch/NewSearch.selector'
import { setSearchResultsInfo } from '@/store/api/NewSearch/NewSearch.slice'
import { filterEmptyFields } from '@/utils/filterEmptyFields'

import { ProfileItem } from './Components/ProfileItem'
import { SearchDetailsCollapsible } from './Components/SearchDetailsCollapsible'
import { SearchResultsPagination } from './Components/SearchResultsPagination'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const SearchResults = () => {
  useHasCurrentStepToken('searchResults')

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchResultsInfo = useSelector(selectSearchResultsInfo)
  const [currentPage, setCurrentPage] = useState(1)

  const [findSubject, { data, isLoading, isError }] = useFindSubjectMutation()

  const onSubmit = async (data: any) => {
    const request = {
      page: currentPage,
      linkedinUrl: data.linkedInUrl || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      company: data.company || '',
      school: data.school || '',
      reportType: 'ai_report',
    }
    const filterRequest = filterEmptyFields(request)
    try {
      await findSubject(filterRequest).unwrap()
      dispatch(
        setSearchResultsInfo({
          firstName: request.firstName,
          lastName: request.lastName,
          school: request.school,
          company: request.company,
          linkedInUrl: request.linkedinUrl,
        }),
      )
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('error'))
      console.error(e)
    }
  }

  useEffect(() => {
    onSubmit(searchResultsInfo)
  }, [currentPage])

  return (
    <WeScreen
      childrenCustomGap={20}
      isLoading={isLoading}
      isError={isError}
      useNextButton={false}
    >
      <p className="text-neutralColor60 text-sm font-medium tracking-tight text-center">
        {t('dashboard.newSearch.searchResults.legalDisclaimer')}
      </p>
      <SearchDetailsCollapsible
        onSubmit={onSubmit}
        count={data?.info.count || 0}
        data={searchResultsInfo}
      />
      <div className="flex flex-col h-full gap-2">
        {data?.info.items.map((data, index) => (
          <ProfileItem data={data} key={index} />
        ))}
      </div>
      {data?.info.count !== 0 && (
        <div className="flex justify-end">
          <SearchResultsPagination
            currentPage={currentPage}
            totalPages={Math.ceil((data?.info.count || 0) / 10)}
            totalItems={data?.info.count || 0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </WeScreen>
  )
}
