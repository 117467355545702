import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { EmptyState } from '../EmptyState/EmptyState'
import { Error404 } from '../ErrorState/Error404'
import { LottieSpinner } from '../LottieSpinner/LottieSpinner'

import { WeScreenHeader } from './WeScreenHeader'

export const WeScreen = ({
  children,
  containerCustomGap = 6,
  useHeader = true,
  title,
  useHeaderBackButton = true,
  rightSideHeaderContent,
  useNextButton = true,
  onClickNextButton,
  useSkipButton = false,
  onClickSkipButton,
  isLoadingNextButton,
  isDisabledNextButton,
  onClickBack,
  isLoading = false,
  isError = false,
  isEmptyState = false,
  emptyStateText,
  childrenCustomPadding,
  childrenCustomGap,
  leftSideHeaderContent,
}: {
  children?: React.ReactNode
  containerCustomGap?: number
  useHeader?: boolean
  title?: string
  useHeaderBackButton?: boolean
  rightSideHeaderContent?: React.ReactNode
  useNextButton?: boolean
  onClickNextButton?: (key: any) => void
  useSkipButton?: boolean
  onClickSkipButton?: (key: any) => void
  isLoadingNextButton?: boolean
  isDisabledNextButton?: boolean
  onClickBack?: () => void
  isLoading?: boolean
  isError?: boolean
  isEmptyState?: boolean
  emptyStateText?: string
  childrenCustomPadding?: number
  childrenCustomGap?: number
  leftSideHeaderContent?: React.ReactNode
}) => {
  const { t } = useTranslation()
  const screenId = useLocation().key

  return (
    <div className={`flex flex-col w-full h-full `} key={`screen-${screenId} `}>
      {useHeader && (
        <WeScreenHeader
          useContinueButton={useNextButton}
          useSkipButton={useSkipButton}
          disabled={isDisabledNextButton}
          isLoading={isLoadingNextButton}
          onClickNext={onClickNextButton}
          onClickSkipButton={onClickSkipButton}
          title={title}
          useBackButton={useHeaderBackButton}
          customButton={rightSideHeaderContent}
          onClickBack={onClickBack}
          key={`header-${screenId}`}
          leftSideContent={leftSideHeaderContent}
        />
      )}
      <div
        className={`flex flex-col w-full h-full p-4 max-md:p-2`}
        style={{ gap: containerCustomGap }}
        key={`container-${screenId}`}
      >
        {isError && (
          <div
            className="flex justify-center items-center w-full h-full"
            key={`error-${screenId}`}
          >
            <Error404 absolute={false} />
          </div>
        )}
        {isLoading && (
          <div
            className="flex justify-center items-center w-full h-full"
            key={`loader-${screenId}`}
          >
            <LottieSpinner
              width={100}
              height={100}
              text={t('feedback.common.loading')}
            />
          </div>
        )}
        {isEmptyState && (
          <div
            className="flex justify-center items-center w-full h-full"
            key={`empty-${screenId}`}
          >
            <EmptyState text={emptyStateText} />
          </div>
        )}
        {!isError && !isLoading && !isEmptyState && (
          <div
            style={{ padding: childrenCustomPadding, gap: childrenCustomGap }}
            className="flex flex-col h-full "
            key={`children-${screenId}`}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  )
}
