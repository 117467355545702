import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Error404 } from '@/components/ErrorState/Error404'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import { selectWorkspaceCreditsFromStore } from '@/store/api/Users/Users.selector'

import { FaceSearch } from './FaceSearch'

export const FaceSearchFatherScreen = () => {
  const { t } = useTranslation()

  const { isLoading: isLoadingUserData } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,

      data,
    }),
  })

  const facialMatchingCredits =
    useSelector(selectWorkspaceCreditsFromStore)?.facialMatchingCredits ?? 0

  return (
    <WeScreen isLoading={isLoadingUserData} useNextButton={false}>
      {facialMatchingCredits ? (
        <FaceSearch />
      ) : (
        <Error404
          title={t('dashboard.error404.insufficientCredits')}
          text={t('dashboard.error404.insufficientCreditsText')}
        />
      )}
    </WeScreen>
  )
}
