import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Progress } from '@/components/ui/progress'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { Events } from '@/constants/events'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useRudderStack } from '@/hooks/useRudderStack'
import { setGeneratingReportGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  useLazyGetReportStatusQuery,
  useStartNewPersonSearchMutation,
} from '@/store/api/NewSearch/NewSearch.query'
import { selectNewSearch } from '@/store/api/NewSearch/NewSearch.selector'
import {
  selectSelectedTeam,
  selectSelectedWorkStation,
  selectUserInfo,
} from '@/store/api/Users/Users.selector'
import { convertToBase64 } from '@/utils/binary'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { EllipsisAnimation } from './Components/EllipsisAnimation'
import {
  analyzingMessages,
  genericLoadingMessages,
  scrapingSocialMediaMessages,
} from './Const/LoadingMessages'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const GeneratingReport = () => {
  const { t } = useTranslation()

  const selectedWorkspace = useSelector(selectSelectedWorkStation)
  const selectedTeam = useSelector(selectSelectedTeam)
  //states con los ids obtenidos de la response del person search
  const [subjectId, setSubjectId] = useState(() =>
    localStorage.getItem('subjectId'),
  )
  const [reportId, setReportId] = useState(() =>
    localStorage.getItem('reportId'),
  )
  //state para el progreso
  const [progress, setProgress] = useState(0)

  //si tiene ids en el storage o generatingReportGuard es true permite que ingrese
  useHasCurrentStepToken(subjectId && reportId ? 'null' : 'generatingReport')

  //states para controlar animaciones
  const [fadeOut, setFadeOut] = useState(false)
  const [fadeIn, setFadeIn] = useState(true)

  //states para mensaje de loading
  const [loadingMessage, setLoadingMessage] = useState(
    t('dashboard.newSearch.generatingReport.loading.subjectFound'),
  )

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scrapingSocialMediaMessagesIndex,
    setScrapingSocialMediaMessagesIndex,
  ] = useState(0)

  const searchInfo = useSelector(selectNewSearch)
  const defaultConfigReport = useSelector(selectUserInfo).pdfConfig
  const userID = useSelector(selectUserInfo).userId
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { trackEvent } = useRudderStack()

  //ref para controlar el interval entre renderizados
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const [
    startPersonSearch,
    { isLoading: isLoadingNewSearch, isError: isErrorPersonSearch },
  ] = useStartNewPersonSearchMutation()
  const [
    getReportStatus,
    {
      data: reportStatus,
      isFetching: isFetchingReportStatus,
      isError: isErrorReportStatus,
    },
  ] = useLazyGetReportStatusQuery()

  //Comienza el loop de request de report status
  const startInterval = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (subjectId: string, id: string) => {
      const checkReportStatus = async () => {
        try {
          const response = await getReportStatus({ subjectId }).unwrap()
          handleLoadingMessage(
            response.info.status,
            response.info.currenScraping,
          )
          if (response.info.progress === 100) {
            stopInterval()
            setLoadingMessage(
              t(
                'dashboard.newSearch.generatingReport.loading.generatingReport',
              ),
            )
            //setTimeout para darle tiempo a la barra de llenarse antes de navegar
            setTimeout(() => {
              navigate(AppRoutes.reports)
              //navigate(AppRoutes.reports, { state: id })

              dispatch(
                setGeneratingReportGuardToken({
                  generatingReportGuardToken: false,
                }),
              )
              localStorage.removeItem('subjectId')
              localStorage.removeItem('reportId')
            }, 6000)
          }
        } catch (e) {
          console.error('Error al obtener estado del reporte', e)
          stopInterval()
          catchWithSentry(e)
          toast.error(t('feedback.toast.errorFetchingStatus'))
          dispatch(
            setGeneratingReportGuardToken({
              generatingReportGuardToken: false,
            }),
          )
        }
      }

      stopInterval()

      intervalRef.current = setInterval(checkReportStatus, 5000)
    },
    [getReportStatus, navigate, t, dispatch],
  )

  //Detiene el loop de request de report status

  const stopInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  //Dispara la request de person search
  const handleStartPersonSearch = useCallback(async () => {
    try {
      const base64Image = await convertToBase64(searchInfo.subjectInfo.image)
      const requestPayload = {
        name: searchInfo.subjectInfo.firstName,
        middleName: searchInfo.subjectInfo.middleName,
        lastName: searchInfo.subjectInfo.lastName,
        nickname: searchInfo.subjectInfo.alias,
        cityShort:
          searchInfo.subjectInfo.cityShortName ||
          searchInfo.companyInfo.cityShortName,
        cityLong:
          searchInfo.subjectInfo.cityLongName ||
          searchInfo.companyInfo.cityLongName,
        stateShort:
          searchInfo.subjectInfo.stateShortName ||
          searchInfo.companyInfo.stateShortName,
        stateLong:
          searchInfo.subjectInfo.stateLongName ||
          searchInfo.companyInfo.stateLongName,
        countryShort:
          searchInfo.subjectInfo.countryShortName ||
          searchInfo.companyInfo.countryShortName,
        countryLong:
          searchInfo.subjectInfo.countryLongName ||
          searchInfo.companyInfo.countryLongName,
        email: searchInfo.subjectInfo.email,
        phone: searchInfo.subjectInfo.phoneNumber,
        college: searchInfo.subjectInfo.collage,
        company: searchInfo.subjectInfo.companyName,
        twitterUrl: searchInfo.socialMedia.x,
        facebookUrl: searchInfo.socialMedia.facebook,
        instagramUrl: searchInfo.socialMedia.instagram,
        tikTokUrl: searchInfo.socialMedia.tikTok,
        linkedInUrl: searchInfo.socialMedia.linkedIn,
        pinterestUrl: searchInfo.socialMedia.pinterest,
        redditUrl: searchInfo.socialMedia.reddit,
        ageOrDateOfBirth: searchInfo.subjectInfo.dob,
        picture: base64Image,
        pdfCoreFindings: defaultConfigReport.pdfCoreFindings,
        pdfFlaggedBehaviors: defaultConfigReport.pdfFlaggedBehaviors,
        pdfFocusedTopics: true,
        pdfLanguage: defaultConfigReport.pdfLanguage,
        pdfNegativePosts: defaultConfigReport.pdfNegativePosts,
        pdfNeutralPosts: defaultConfigReport.pdfNeutralPosts,
        pdfPositivePosts: defaultConfigReport.pdfPositivePosts,
        pdfSentimentAnalysis: defaultConfigReport.pdfSentimentAnalysis,
        pdfSocialMediaAccounts: defaultConfigReport.pdfSocialMediaAccounts,
        pdfWebSearchPosts: defaultConfigReport.pdfWebSearchPosts,
        topics: searchInfo.firstStep.topics,
        topicsIds: searchInfo.firstStep.topicsIds,
        reportType: 'ai_report',
        useCase: searchInfo.useCases.selectedUseCaseId,
        optionalCompliance: searchInfo.useCases.consentSwitchValue,
        website: searchInfo.companyInfo.website,
        industry: searchInfo.companyInfo.industry,
      }

      const filteredPayload = filterEmptyFields(requestPayload, 'useCase')
      const newSearchRequest = await startPersonSearch({
        ...filteredPayload,
        workspaceId: selectedWorkspace?._id,
        teamId: selectedTeam?._id,
      }).unwrap()

      trackEvent(Events.REPORT_NEW, userID, {
        type_of_report: searchInfo.selectSearchType.searchType,
        type_of_topics: 'ai_report',
      })

      const newSubjectId = newSearchRequest.info?.subjectId
      const newReportId = newSearchRequest.info.id

      localStorage.setItem('subjectId', newSubjectId)
      localStorage.setItem('reportId', newReportId)

      setSubjectId(newSubjectId)
      setReportId(newReportId)

      startInterval(newSubjectId, newReportId)
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('feedback.toast.errorOnSearch'))
    }
  }, [
    searchInfo,
    defaultConfigReport,
    userID,
    startPersonSearch,
    startInterval,
  ])

  //useEffect para controlar las animaciones

  useEffect(() => {
    if (isLoadingNewSearch || isFetchingReportStatus) {
      setFadeOut(true)
      setFadeIn(false)
    } else {
      setFadeOut(false)
      setFadeIn(true)
    }
  }, [isLoadingNewSearch, isFetchingReportStatus])

  //useEffect para manejar las request

  useEffect(() => {
    if (subjectId && reportId) {
      startInterval(subjectId, reportId)
    } else {
      handleStartPersonSearch()
    }

    return () => {
      stopInterval()
    }
  }, [
    subjectId,
    reportId,
    handleStartPersonSearch,
    startInterval,
    stopInterval,
  ])

  //useEffect para manejar el fake value del progreso

  useEffect(() => {
    if (isLoadingNewSearch) {
      const fakeProgressInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 26) return prev + 2
          clearInterval(fakeProgressInterval)
          return prev
        })
      }, 5000)
      return () => clearInterval(fakeProgressInterval)
    }
    if (!isLoadingNewSearch && reportStatus?.info.progress) {
      setProgress(reportStatus.info.progress)
    }
  }, [isLoadingNewSearch, reportStatus])

  //funciones para determinar el mensaje de loading que se muestra

  const formatSocialMedia = (platform: string) => {
    switch (platform) {
      case 'LinkedIn':
        return 'LinkedIn'
      case 'Facebook':
        return 'Facebook'
      case 'Instagram':
        return 'Instagram'
      case 'Pinterest':
        return 'Pinterest'
      case 'TikTok':
        return 'TikTok'
      case 'Twitter':
        return 'X (Twitter)'
      case 'Reddit':
        return 'Reddit'
      case 'WebSearch':
        return 'Web/News'
      default:
        return 'various platforms'
    }
  }

  const getScrapingMessage = (newIndex: number, socialMedia: string) => {
    const messageTemplate = t(scrapingSocialMediaMessages[newIndex])
    const formattedMessage = messageTemplate.replace(
      '${socialMedia}',
      formatSocialMedia(socialMedia),
    )
    return formattedMessage
  }

  const handleLoadingMessage = (status: string, currenScraping: string) => {
    if (status === null || currenScraping === null) {
      setLoadingMessage((prevMessage) => {
        const newIndex =
          (genericLoadingMessages.indexOf(prevMessage) + 1) %
          genericLoadingMessages.length
        return t(genericLoadingMessages[newIndex])
      })
    } else if (status === 'Scraping' && currenScraping !== null) {
      setScrapingSocialMediaMessagesIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % scrapingSocialMediaMessages.length
        setLoadingMessage(getScrapingMessage(newIndex, currenScraping))
        return newIndex
      })
    } else if (status === 'Searching' && currenScraping !== null) {
      setLoadingMessage(
        t('dashboard.newSearch.generatingReport.loading.searching.profiles', {
          socialMedia: formatSocialMedia(currenScraping),
        }),
      )
    } else if (status === 'IaAnalysis') {
      setLoadingMessage((prevMessage) => {
        const newIndex =
          (analyzingMessages.indexOf(prevMessage) + 1) %
          analyzingMessages.length
        return t(analyzingMessages[newIndex])
      })
    } else if (status === 'Completed') {
      setLoadingMessage(
        t('dashboard.newSearch.generatingReport.loading.generatingReport'),
      )
    } else {
      setLoadingMessage(
        t('dashboard.newSearch.generatingReport.loading.processingData'),
      )
    }
  }

  return (
    <WeScreen
      isError={isErrorPersonSearch || isErrorReportStatus}
      useHeader={false}
    >
      <div className="flex flex-col h-full gap-8 px-8 py-16 justify-between max-md:h-[85vh]">
        <p className="flex justify-center items-center text-neutralColor text-2xl font-medium w-full basis-1/6">
          {t('dashboard.newSearch.generatingReport.title')}
        </p>
        <div className="flex flex-col gap-8 justify-center basis-5/6">
          <div className="flex gap-4 justify-center items-center ">
            <div className="flex gap-2.5 items-center">
              {isLoadingNewSearch ? (
                <>
                  <p className="text-center text-neutralColor80 text-xl font-bold transition-all duration-4000 opacity-100 translate-y-0">
                    {t(
                      'dashboard.newSearch.generatingReport.loading.initializing',
                    )}
                  </p>
                  <EllipsisAnimation fadeOut={false} fadeIn={false} />
                </>
              ) : (
                <>
                  <p
                    className={`text-center text-neutralColor80 text-xl font-bold transition-all duration-4000 ${
                      fadeOut
                        ? 'opacity-0 -translate-y-2'
                        : fadeIn
                          ? 'opacity-100 translate-y-0'
                          : ''
                    }`}
                  >
                    {loadingMessage}
                  </p>
                  <EllipsisAnimation fadeOut={fadeOut} fadeIn={fadeIn} />
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <Progress
              value={progress}
              className="h-4"
              color={weCheckPalette.dynamicBlue}
              animationDuration={5000}
              isLoadingBar
            />
            <p className="text-center text-neutralColor80 font-medium ">
              {t('dashboard.newSearch.generatingReport.footerText')}
            </p>
          </div>
        </div>
      </div>
    </WeScreen>
  )
}
