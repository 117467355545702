import { Loader } from '@/components/Loader/Loader'
import { Card } from '@/components/ui/card'

export const DueDiligenceCard = ({
  title,
  number,
  subTitle,
  isLoading,
}: {
  title?: string
  number?: number
  subTitle?: string
  isLoading?: boolean
}) => {
  return (
    <Card className="w-full flex flex-row justify-between p-4 bg-baseColorHighlight shadow-none">
      <div className="flex flex-col gap-2 ">
        <p className="text-sm font-medium text-neutralColor">{title}</p>
        {!isLoading ? (
          <p className="text-neutralColor text-2xl">{number}</p>
        ) : (
          <div className="h-8 w-8 flex items-center justify-center">
            <Loader />
          </div>
        )}
        {subTitle && (
          <p className="text-xs font-medium text-neutralColor60">{subTitle}</p>
        )}
      </div>
    </Card>
  )
}
