import { ArrowRight } from 'iconsax-react'
import React from 'react'

import { Button } from '@/components/ui/button'

interface TotalPostsCardTypes {
  sentiment: string
  totalPosts?: number
  onClick: () => void
}

export const TotalPostsCard = ({
  sentiment,
  totalPosts,
  onClick,
}: TotalPostsCardTypes) => {
  return (
    <Button
      className="h-[100px] p-4 rounded-2xl flex gap-2 flex-col w-full border bg-baseColorHighlight shadow justify-between"
      disabled={totalPosts === 0}
      onClick={onClick}
    >
      <p className="flex text-neutralColor text-xl font-medium tracking-tight w-full">
        {sentiment}
      </p>
      <div className="flex justify-between w-full">
        <p className="text-neutralColor text-[32px] font-medium">
          {totalPosts}
        </p>
        <ArrowRight className="w-6 h-6" />
      </div>
    </Button>
  )
}
