import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import { useLazyPlanHistoryQuery } from '@/store/api/Plan/Plan.query'
import type { PlanHistoryItem } from '@/store/api/Plan/Plan.types'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { HistoryPlanItem } from '../HistoryPlanItem/HistoryPlanItem'

export const HistoryPlanComponent = () => {
  const { t } = useTranslation()
  const { hasHandleCredits } = useRedirectBasedOnPermissions()
  const isDefaultState = useAppSelector(selectItsDefaultState)
  const [currentPage, setCurrentPage] = useState(1)
  const isSmallDesktop = useSmallDesktopQuery()
  const [allRechargeHistory, setAllRechargeHistory] = useState<
    PlanHistoryItem[]
  >([])

  useEffect(() => {
    hasHandleCredits()
  }, [isDefaultState])

  const [
    makeRequest,
    {
      data: historyPlanData,
      isLoading: isLoadingPlanHistoryFirstTime,
      isFetching: isFetchingPlanHistory,
    },
  ] = useLazyPlanHistoryQuery()

  const makeBeRequest = useCallback(
    (page: number) => {
      makeRequest({ page, pageSize: 10 })
    },
    [makeRequest],
  )

  useEffect(() => {
    makeBeRequest(currentPage)
  }, [makeBeRequest, currentPage])

  useEffect(() => {
    if (historyPlanData?.rechargeHistory) {
      setAllRechargeHistory((prevHistory) => [
        ...prevHistory,
        ...historyPlanData.rechargeHistory,
      ])
    }
  }, [historyPlanData])

  const fetchMoreOnPagination = () => {
    if (!isFetchingPlanHistory && hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1) // Incrementa la página
    }
  }

  const hasMoreData = useMemo(() => {
    const totalLoadedItems = allRechargeHistory.length
    const totalItems = historyPlanData?.totalItems || 0
    return totalLoadedItems < totalItems
  }, [allRechargeHistory, historyPlanData?.totalItems])

  const isLoadingFirstPage =
    (isFetchingPlanHistory && currentPage === 1) ||
    isLoadingPlanHistoryFirstTime

  console.log(allRechargeHistory)

  return (
    <div className=" bg-baseColor rounded-lg p-4">
      <p className="text-xl text-neutralColor mb-4">
        {t('dashboard.biling.accountHistory')}
      </p>
      {!isLoadingFirstPage && allRechargeHistory.length > 0 && (
        <div>
          <InfiniteScroll
            dataLength={allRechargeHistory.length} //This is important field to render the next data
            next={fetchMoreOnPagination}
            hasMore={hasMoreData}
            loader={<p>Loading...</p>}
            height={isSmallDesktop ? 320 : 500}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {allRechargeHistory.map((item: PlanHistoryItem, id) => (
              <div key={id}>
                <HistoryPlanItem data={item} />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
      {!allRechargeHistory && (
        <div className="flex justify-center ">
          <EmptyState />
        </div>
      )}
      {isLoadingFirstPage && (
        <div className="flex justify-center ">
          <LottieSpinner />
        </div>
      )}
    </div>
  )
}
