import React from 'react'

interface EllipsisAnimationTypes {
  fadeOut: boolean
  fadeIn: boolean
}

export const EllipsisAnimation = ({
  fadeIn,
  fadeOut,
}: EllipsisAnimationTypes) => {
  return (
    <div
      className={`flex gap-2 w-12 h-3 items-end mt-1.5 duration-4000 ${
        fadeOut
          ? 'opacity-0 -translate-y-2'
          : fadeIn
            ? 'opacity-100 translate-y-0'
            : ''
      }`}
    >
      <span className="relative flex h-1 w-1">
        <span className="animate-bounce absolute inline-flex h-full w-full rounded-full bg-neutralColor opacity-75 ease-in delay-350 duration-1050"></span>
        <span className="animate-ping relative inline-flex rounded-full h-1 w-1 bg-neutralColor ease-in delay-710 duration-1050"></span>
      </span>
      <span className="relative flex h-1 w-1">
        <span className="animate-bounce absolute inline-flex h-full w-full rounded-full bg-neutralColor opacity-75 ease-in delay-700 duration-1050"></span>
        <span className="animate-ping relative inline-flex rounded-full h-1 w-1 bg-neutralColor ease-in delay-1060 duration-1050"></span>
      </span>
      <span className="relative flex h-1 w-1">
        <span className="animate-bounce absolute inline-flex h-full w-full rounded-full bg-neutralColor opacity-75 ease-in delay-1800 duration-1050"></span>
        <span className="animate-ping relative inline-flex rounded-full h-1 w-1 bg-neutralColor ease-in delay-1410 duration-1050"></span>
      </span>
    </div>
  )
}
