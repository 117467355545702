import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useUpdateTeamDataMutation,
  useUpdateWorkSpaceDataMutation,
} from '@/store/api/workspace/workspace.query'
import type { renameSchemaType } from '@/utils/schemas'
import { renameSchema } from '@/utils/schemas'

export const RenamePopUp = ({
  isRenameTeam,
  workspaceId,
  teamId,
  oldName,
  isOpen,
  setIsOpen,
}: {
  isRenameTeam?: boolean
  workspaceId?: string
  teamId?: string
  oldName?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { t } = useTranslation()

  const [updateTeamData] = useUpdateTeamDataMutation()
  const [updateWorkSpaceData] = useUpdateWorkSpaceDataMutation()
  const form = useForm<renameSchemaType>({
    resolver: zodResolver(renameSchema),
    defaultValues: {
      name: '',
    },
  })
  const { handleSubmit, control } = form

  const handleUpdateTeamData = async (data: any) => {
    try {
      if (data) {
        await updateTeamData({
          id: workspaceId!,
          teamId: teamId!,
          name: data.name,
        }).unwrap()
        toast.success(
          isRenameTeam
            ? t('dashboard.company.renamePopUp.changeTeamNameSuccess')
            : t('dashboard.company.renamePopUp.changeWorkspaceNameSuccess'),
        )
        setIsOpen(false)
      }
    } catch (error) {
      toast.error(
        isRenameTeam
          ? t('dashboard.company.renamePopUp.changeTeamNameFail')
          : t('dashboard.company.renamePopUp.changeWorkspaceNameFail'),
      )
      catchWithSentry(error)
    }
  }

  const handleUpdateWorkspaceData = async (data: any) => {
    try {
      if (data) {
        await updateWorkSpaceData({
          id: workspaceId!,
          name: data.name,
        }).unwrap()
        toast.success('Change team name success')
        setIsOpen(false)
      }
    } catch (error) {
      toast.error('Change team name fail')
      catchWithSentry(error)
    }
  }

  const onSubmit = async (data: any) => {
    if (isRenameTeam) {
      handleUpdateTeamData(data)
    } else {
      handleUpdateWorkspaceData(data)
    }
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent className="flex flex-col w-2/6 max-md:w-11/12">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-[32px] font-medium tracking-wide max-md:text-left max-md:text-[30px]">
            {t('dashboard.company.renamePopUp.rename')}{' '}
            {isRenameTeam
              ? t('dashboard.company.renamePopUp.team')
              : t('dashboard.company.renamePopUp.workspace')}
          </AlertDialogTitle>

          <AlertDialogDescription className="text-base font-medium  tracking-tight max-md:text-left">
            {t('dashboard.company.renamePopUp.renameText')} {oldName}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <div className="flex gap-6 items-end justify-start max-md:flex-col">
              <FormField
                control={control}
                name="name"
                render={({ field }) => (
                  <FormItem className="w-full rounded-md flex flex-col ">
                    <FormLabel className="text-textNormal text-base font-medium tracking-tight">
                      {t('dashboard.company.renamePopUp.name')}
                    </FormLabel>

                    <FormControl className="focus:outline-none">
                      <Input
                        className="bg-transparent"
                        {...field}
                        placeholder={t('dashboard.company.renamePopUp.newName')}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-row justify-end gap-3">
              <Button variant="secondary" onClick={() => setIsOpen(false)}>
                {t('dashboard.company.renamePopUp.cancel')}
              </Button>

              <Button type="submit">
                {t('dashboard.company.renamePopUp.continue')}
              </Button>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}
