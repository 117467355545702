import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowDown2 } from 'iconsax-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { AppRoutes } from '@/constants/appRoutes'
import { setAdvancedSearchGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  clearSubjectInfo,
  setIsPrefillForm,
  setSocialMedia,
} from '@/store/api/NewSearch/NewSearch.slice'
import type { searchDetailsSchemaType } from '@/utils/schemas'
import { searchDetailsSchema } from '@/utils/schemas'

interface SearchDetailsCollapsibleTypes {
  onSubmit: (data: any) => Promise<void>
  count: number
  data: {
    firstName: string
    lastName: string
    school: string
    company: string
    linkedInUrl: string
    searchInput: string
  }
}
export const SearchDetailsCollapsible = ({
  onSubmit,
  count,
  data,
}: SearchDetailsCollapsibleTypes) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(count > 0 ? false : true)

  const form = useForm<searchDetailsSchemaType>({
    resolver: zodResolver(searchDetailsSchema),
    defaultValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      school: data.school,
      company: data.company,
      linkedInUrl: data.linkedInUrl,
    },
    mode: 'onChange',
  })

  const { handleSubmit, control } = form

  const handleUseAdvancedSearch = () => {
    dispatch(clearSubjectInfo())
    dispatch(setSocialMedia({ linkedIn: '' }))
    dispatch(setIsPrefillForm(false))
    dispatch(setAdvancedSearchGuardToken({ advancedSearchGuardToken: true }))
    navigate(AppRoutes.advancedSearch)
  }

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="flex flex-col gap-3 rounded-xl border bg-baseColorHighlight shadow px-3 py-2 "
    >
      <div className="flex justify-between items-center md:h-[57px] max-md:flex-col max-md:gap-2">
        <div className="flex flex-col">
          <p className="text-neutralColor text-base font-medium">
            {count > 0
              ? data.firstName
                ? `${count} ${t('dashboard.newSearch.searchResults.results')} ${
                    data.firstName
                  } ${data.lastName}`
                : `${count} ${t('dashboard.newSearch.searchResults.matching')}`
              : t('dashboard.newSearch.searchResults.refine')}
          </p>
          <p className="text-neutralColor60 text-sm font-medium tracking-tight">
            {count > 0
              ? data.firstName
                ? `${t('dashboard.newSearch.searchResults.showingResults')} ${
                    data.firstName
                  } ${data.lastName}`
                : t('dashboard.newSearch.searchResults.selectProfile')
              : t('dashboard.newSearch.searchResults.noResults')}
          </p>
        </div>
        <CollapsibleTrigger asChild disabled={count === 0}>
          <Button
            variant="ghost"
            size="sm"
            className="flex gap-2 items-center max-md:w-full"
          >
            <p className="text-neutralColor text-xs font-medium">
              {t('dashboard.newSearch.searchResults.searchDetails')}
            </p>
            <ArrowDown2 className="w-5" />
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent
        className={`${isOpen ? 'flex' : 'hidden'} flex-col gap-6`}
      >
        <Form {...form}>
          <form className="flex flex-wrap justify-between gap-y-5 max-md:flex-col">
            <FormField
              control={control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="md:w-[48%] h-20">
                  <FormLabel className="text-neutralColor">
                    {t('dashboard.newSearch.searchResults.name')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage data-name="firstName" />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="md:w-[48%] h-20">
                  <FormLabel className="text-neutralColor">
                    {t('dashboard.newSearch.searchResults.lastName')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage data-name="lastName" />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="school"
              render={({ field }) => (
                <FormItem className="md:w-[48%] h-20">
                  <FormLabel className="text-neutralColor">
                    {t('dashboard.newSearch.searchResults.school')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage data-name="school" />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="company"
              render={({ field }) => (
                <FormItem className="md:w-[48%] h-20">
                  <FormLabel className="text-neutralColor">
                    {t('dashboard.newSearch.searchResults.company')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage data-name="company" />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="linkedInUrl"
              render={({ field }) => (
                <FormItem className="md:w-[48%] h-20">
                  <FormLabel className="text-neutralColor">
                    {t('dashboard.newSearch.searchResults.url')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage data-name="linkedInUrl" />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <p className="text-neutralColor text-base font-medium">
              {t('dashboard.newSearch.searchResults.rightMatch')}
            </p>
            <p className="text-neutralColor60 text-sm font-medium tracking-tight">
              {t('dashboard.newSearch.searchResults.try')}
            </p>
          </div>
          <div className="flex justify-end gap-3 max-md:flex-col">
            <Button
              variant="secondary"
              onClick={handleUseAdvancedSearch}
              className="md:h-[46px] px-5 py-2 rounded-lg bg-baseColorHighlight"
            >
              {t('dashboard.newSearch.searchResults.use')}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              className="md:h-[46px] px-5 py-2 rounded-lg"
            >
              {t('dashboard.newSearch.searchResults.search')}
            </Button>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
