import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import {
  EUserPermission,
  EUserRole,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { clearTokens } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import { useListOfUseCasesQuery } from '@/store/api/NewSearch/NewSearch.query'
import {
  clearNewSearch,
  setIsPrefillForm,
} from '@/store/api/NewSearch/NewSearch.slice'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import {
  selectIsPending,
  selectItsDefaultState,
  selectWorkspaceCreditsFromStore,
} from '@/store/api/Users/Users.selector'

import { SearchSection } from './components/SearchSection/SearchSection'
import { StatsSection } from './components/StatsSection/StatsSection'

export const Home = () => {
  const { hasPermission } = usePermissions()
  hasPermission(EUserPermission.HANDLE_REPORTS, EUserRole.TEAM_ANALYST)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    data: userData,
    isError,
    isLoading,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      data,
    }),
  })

  useEffect(() => {
    if (userData?.role === EUserRole.READER) {
      navigate(AppRoutes.reports)
    }
  }, [userData])

  const aiReportCredits =
    useSelector(selectWorkspaceCreditsFromStore)?.aiReportCredits ?? 0
  const aiAnalystReportCredits =
    useSelector(selectWorkspaceCreditsFromStore)?.aiAnalystReportCredits ?? 0
  const adverseMediaReportCredits =
    useSelector(selectWorkspaceCreditsFromStore)?.adverseMediaReportCredits ?? 0
  const assistedReportCredits =
    useSelector(selectWorkspaceCreditsFromStore)?.assistedReportCredits ?? 0
  const facialMatchingCredits =
    useSelector(selectWorkspaceCreditsFromStore)?.facialMatchingCredits ?? 0

  const isDefaultState = useSelector(selectItsDefaultState)

  const { hasHandleReports } = useRedirectBasedOnPermissions()

  const { isLoading: isLoadingUserData } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      isPending: selectIsPending(data),
    }),
  })

  const { isLoading: isLoadingUsesCasesData, isError: isErrorUseCasesData } =
    useListOfUseCasesQuery()

  useEffect(() => {
    dispatch(clearTokens())
    dispatch(clearNewSearch())
    dispatch(setIsPrefillForm(false))
    if (!isDefaultState) {
      hasHandleReports()
    }
  }, [isLoadingUserData])

  return (
    <WeScreen
      isError={isError || isErrorUseCasesData}
      isLoading={isLoading || isLoadingUsesCasesData || isLoadingUserData}
      useHeader={false}
      childrenCustomGap={48}
    >
      <div className="flex justify-center">
        <SearchSection
          aiReportCredits={aiReportCredits}
          aiAnalystReportCredits={aiAnalystReportCredits}
          adverseMediaReportCredits={adverseMediaReportCredits}
          assistedReportCredits={assistedReportCredits}
          facialMatchingCredits={facialMatchingCredits}
        />
      </div>
      <div>
        <div
          className={`absolute inset-x-0 bg-cover bg-baseColor lg:h-[35%] h-full`}
          style={{
            zIndex: -1,
          }}
        ></div>
        <StatsSection creditsData={userData?.enterprise.credits} />
      </div>
    </WeScreen>
  )
}
