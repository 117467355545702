export const SITES = [
  'facebook.com',
  'instagram.com',
  'twitter.com',
  'x.com',
  'linkedin.com/in',
  'reddit.com',
  'pinterest.com',
]

export enum ESocialMedias {
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  TikTok = 'TikTok',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
  Reddit = 'Reddit',
}

export const SocialMediaPatterns: { [key: string]: RegExp } = {
  [ESocialMedias.Facebook]: /(?:https?:\/\/)?(?:www\.)?facebook\.com/i,
  [ESocialMedias.Twitter]: /(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)/i,
  [ESocialMedias.Instagram]: /(?:https?:\/\/)?(?:www\.)?instagram\.com/i,
  [ESocialMedias.LinkedIn]: /(?:https?:\/\/)?(?:www\.)?linkedin\.com/i,
  [ESocialMedias.TikTok]: /(?:https?:\/\/)?(?:www\.)?tiktok\.com/i,
  [ESocialMedias.Reddit]: /(?:https?:\/\/)?(?:www\.)?reddit\.com/i,
  [ESocialMedias.Pinterest]: /(?:https?:\/\/)?(?:www\.)?pinterest\.com/i,
}
