import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { selectCreateTeamFirstStep } from '@/store/api/CreateTeam/CreateTeam.selector'
import { setCreateTeamFirstStep } from '@/store/api/CreateTeam/CreateTeam.slice'
import type { workSpaceSecondStepSchemaType } from '@/utils/schemas'
import { workSpaceSecondStepSchema } from '@/utils/schemas'

import { Stepper } from '../Search/Components/Stepper'

import { WorkSpaceCreationSecondStepInput } from './components/WorkSpaceCreationSecondStepInput'

export const CreateTeamFirstStep = () => {
  const { t } = useTranslation()
  const steps = [
    t('dashboard.company.createTeam.teamSetup'),
    t('dashboard.company.createTeam.inviteMembers'),
  ]

  const createTeamFirstStep = useAppSelector(selectCreateTeamFirstStep)
  const navigate = useNavigate()
  const { state } = useLocation()
  const workspaceId = state
  const dispatch = useAppDispatch()
  const form = useForm<workSpaceSecondStepSchemaType>({
    resolver: zodResolver(workSpaceSecondStepSchema),
    defaultValues: {
      createTeamName: '',
    },
    mode: 'onChange',
  })
  const { control, formState } = form
  const { errors } = formState
  const activeStep = 0

  const [inputs, setInputs] = useState<string[]>(
    createTeamFirstStep?.createTeamArray?.length
      ? createTeamFirstStep.createTeamArray
      : [''],
  )
  const maxInputs = 5
  const isTeamNameValid =
    form.watch('createTeamName')?.trim().length > 0 && !errors.createTeamName

  const isAnyInputValid =
    inputs.every((input) => input.trim().length > 0) &&
    inputs.some((input) => input.trim().length > 0)
  const disabledContinueButton = !isTeamNameValid && !isAnyInputValid

  const handleAddInput = () => {
    const lastInput = inputs[inputs.length - 1]
    if (inputs.length < maxInputs && lastInput.trim().length > 0) {
      setInputs([...inputs, ''])
    }
  }
  const handleInputChange = (index: number, value: string) => {
    const newInputs = [...inputs]
    newInputs[index] = value
    setInputs(newInputs)
  }

  const handleRemoveInput = (index: number) => {
    const newInputs = inputs.filter((_, i) => i !== index)

    setInputs(newInputs)
  }

  const handleOnclick = () => {
    dispatch(
      setCreateTeamFirstStep({
        createTeamArray: inputs,
        workspaceId: workspaceId,
      }),
    )
    navigate(AppRoutes.createTeamSecondStep)
  }

  return (
    <WeScreen
      isLoading={false}
      isError={false}
      isDisabledNextButton={disabledContinueButton}
      onClickNextButton={handleOnclick}
    >
      <div className="flex flex-col items-center gap-3 xl:gap-6 h-full">
        <div className="flex">
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        <div className="flex justify-center xl:mt-44">
          <Form {...form}>
            <form className="w-3/4 flex flex-col justify-center max-md:gap-6 gap-6">
              <div className="flex flex-col gap-3">
                <p className="text-center text-xl font-medium text-neutralColor">
                  {t('dashboard.company.createTeam.teamSetupTitle')}
                </p>
                <p className="text-center font-normal text-base text-neutralColor80">
                  {t('dashboard.company.createTeam.teamSetupText')}
                </p>
                {inputs.map((value: string, index: number) => (
                  <WorkSpaceCreationSecondStepInput
                    key={index}
                    control={control}
                    name={`createTeamName_${index}`}
                    value={value}
                    showDeleteIcon={index === 0 ? false : true}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onRemove={() => handleRemoveInput(index)}
                  />
                ))}
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault()
                    handleAddInput()
                  }}
                  className="w-1/2 lg:w-1/3"
                  disabled={inputs.length >= maxInputs}
                >
                  {t('dashboard.company.createTeam.addAnother')}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </WeScreen>
  )
}
