import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'

import { UseCaseCard } from './UseCaseCard'

export const FocusTopicsSheet = () => {
  const { t } = useTranslation()

  const data = [
    {
      name: t('dashboard.newSearch.focusedTopics.focusTopicSheet.gender'),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.genderDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.focusTopicSheet.adultContent'),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.adultContentDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.focusTopicSheet.mentalHealth'),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.mentalHealthDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.focusTopicSheet.fraud'),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.fraudDescription',
      ),
    },
    {
      name: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.cryptocurrency',
      ),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.cryptocurrencyDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.focusTopicSheet.terrorism'),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.terrorismDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.focusTopicSheet.harassment'),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.harassmentDescription',
      ),
    },
    {
      name: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.ukraineRussiaConflict',
      ),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.ukraineRussiaConflictDescription',
      ),
    },
    {
      name: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.discrimination',
      ),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.discriminationDescription',
      ),
    },
    {
      name: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.israelHamasConflict',
      ),
      description: t(
        'dashboard.newSearch.focusedTopics.focusTopicSheet.israelHamasConflictDescription',
      ),
    },
  ]

  const isMobile = useMobileMediaQuery()
  return (
    <Sheet>
      <SheetTrigger>
        <p className="text-dynamicBlue text-center text-base font-medium text-nowrap ml-1">
          {t(
            'dashboard.newSearch.focusedTopics.focusTopicSheet.learnMoreFocusTopics',
          )}
        </p>
      </SheetTrigger>

      <SheetContent
        className="flex flex-col gap-8 max-h-full overflow-y-auto rounded-tl-2xl px-4"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-3 mt-[-6px]">
          <SheetTitle className="text-textNormal text-xl font-medium tracking-wide text-left">
            {t('dashboard.newSearch.focusedTopics.focusTopicSheet.sheetTitle')}
          </SheetTitle>
          <SheetDescription className="max-md:text-left">
            {t(
              'dashboard.newSearch.focusedTopics.focusTopicSheet.sheetDescription',
            )}
          </SheetDescription>
        </SheetHeader>

        <div className="flex flex-col gap-8 justify-center ">
          {data?.map((Behavior, index) => {
            return (
              <UseCaseCard
                title={Behavior?.name}
                description={Behavior?.description}
                key={index}
              />
            )
          })}
        </div>
      </SheetContent>
    </Sheet>
  )
}
